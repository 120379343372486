import { Formik } from 'formik';
import { Community } from '../../../models/community/Community';
import * as Yup from 'yup';
import Input from '../../../components/form-control/Input';
import SelectDropdown from '../../../components/form-control/SelectDropdown';
import { COMMUNITY_TYPES } from '../../../resources/enums';
import {
  getFormattedPhoneNumber,
  getPhoneNumberFromShortCodeFormat,
  getSelectOptionsFromEnum
} from '../../../utils/miscFunctions';
import Button from '../../../components/form-control/Button';
import SelectMenu from '../../../components/form-control/SelectMenu';
import { COUNTRY_OPTIONS, GHANA_REGIONS } from '../../../resources/constants';
import {
  addCommunityContact,
  createCommunity,
  updateCommunity,
  updateCommunityContact
} from '../../../api/requests';
import {
  IAddCommunityContactDTO,
  ICreateCommunityDTO,
  IRequestErrorResponse
} from '../../../resources/interfaces';
import { AxiosError } from 'axios';
import TextArea from '../../../components/form-control/TextArea';
import { useState } from 'react';
import { toast } from 'react-toastify';
import PlaceComponent from '../../../components/PlaceComponent';

interface IAddCommunityProps {
  community?: Community;
  isEdit?: boolean;
  onClose: () => void;
  onProceed: (community: Community) => void;
}

interface IFormData {
  name: string;
  description: string;
  streetName: string;
  city: string;
  town: string;
  country: string;
  region: string;
  type: string;
  contactPersonName: string;
  contactPersonEmail: string;
  contactPersonPhone: string;
  postalCode: string;
}

const AddCommunity = (props: IAddCommunityProps): JSX.Element => {
  const [submitting, setSubmitting] = useState<boolean>(false);

  const handleSubmit = (values: IFormData) => {
    const payload: ICreateCommunityDTO = {
      city: values.city,
      country: values.country,
      name: values.name,
      postalCode: values.postalCode,
      region: values.region,
      streetName: values.streetName,
      town: values.town,
      type: values.type as COMMUNITY_TYPES,
      description: values.description
    };

    setSubmitting(true);
    if (props.isEdit && props.community) {
      updateCommunity(props.community?.id, payload)
        .then((response: any) => {
          if (response) {
            const contactPayload: IAddCommunityContactDTO = {
              name: values.contactPersonName,
              phone: values.contactPersonPhone,
              email: values.contactPersonEmail
            };
            updateCommunityContact(response.id, response.id, contactPayload)
              .then((response: any) => {
                toast.success('Community updated successfully.');
                props.onProceed(new Community(response));
              })
              .finally(() => setSubmitting(false));
          }
        })
        .catch((error: AxiosError | IRequestErrorResponse | any) => {});
    } else {
      createCommunity(payload)
        .then((response: any) => {
          if (response) {
            const contactPayload: IAddCommunityContactDTO = {
              name: values.contactPersonName,
              phone: getFormattedPhoneNumber(values.contactPersonPhone),
              email: values.contactPersonEmail
            };
            addCommunityContact(response.id, contactPayload)
              .then((response: any) => {
                toast.success('Community created successfully.', {
                  autoClose: 3000
                });
                props.onProceed(new Community(response));
              })
              .finally(() => setSubmitting(false));
          }
        })
        .catch((error: AxiosError | IRequestErrorResponse | any) => {});
    }
  };

  return (
    <div className="py-[43px] px-0 md:px-14 w-full">
      <Formik
        initialValues={{
          name: props.community?.name ?? '',
          description: props.community?.description ?? '',
          streetName: props.community?.address?.streetName ?? '',
          city: props.community?.address?.city ?? '',
          town: props.community?.address?.town ?? '',
          country: props.community?.address?.country?.name ?? '',
          region: props.community?.address?.region ?? '',
          type: props.community?.type ?? '',
          contactPersonName: props.community?.getPrimaryContactPerson()?.fullName ?? '',
          contactPersonEmail: props.community?.getPrimaryContactPerson()?.fullName ?? '',
          contactPersonPhone: getPhoneNumberFromShortCodeFormat(
            props.community?.getPrimaryContactPerson()?.phone ?? ''
          ),
          postalCode: props.community?.address?.postalCode ?? ''
        }}
        enableReinitialize={true}
        validateOnChange={false}
        validateOnBlur={false}
        validationSchema={Yup.object().shape({
          name: Yup.string().trim().required('Community name is required.'),
          description: Yup.string().trim().required('Community description is required.'),
          type: Yup.string().required('Community type is requied.'),
          country: Yup.string().required('Country is requied.'),
          region: Yup.string().required('Region is requied.'),
          postalCode: Yup.string().required('Postal code is requied.'),
          contactPersonName: Yup.string().trim().required('Name of contact person is required.'),
          contactPersonEmail: Yup.string()
            .trim()
            .email('Please provide a valid email address.')
            .required('Email address is required.'),
          contactPersonPhone: Yup.string()
            .matches(/^[0-9]+$/, 'Must be only digits')
            .min(10, 'Must be exactly 10 digits')
            .max(10, 'Must be exactly 10 digits')
            .trim()
            .required('Phone number of contact person is requied.')
        })}
        onSubmit={(values) => {
          handleSubmit(values);
        }}>
        {({
          values,
          errors,
          isSubmitting,
          handleSubmit,
          handleChange,
          handleBlur,
          setFieldValue,
          touched
        }) => (
          <form className="" onSubmit={handleSubmit}>
            <div className="w-full flex flex-col space-y-8">
              <div className="flex flex-col space-y-6">
                <h3 className="font-heading font-[600] text-[20px] text-[#455A64] leading-[30px] m-0 p-0">
                  Community Info
                </h3>
                <Input
                  type={'text'}
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  label="Community Name"
                  placeholder="Enter community name"
                  required
                  error={touched.name ? errors.name : undefined}
                  helperText={touched?.name ? errors.name : undefined}
                  className="rounded"
                />
                <TextArea
                  name="description"
                  value={values.description}
                  onChange={handleChange}
                  onBlur={handleChange}
                  label="Description"
                  placeholder="Enter community description"
                  required
                  error={touched.description ? errors.description : undefined}
                  helperText={touched?.description ? errors.description : undefined}
                  classes="rounded"
                />

                <SelectDropdown
                  name="type"
                  listSelectedValue={values.type}
                  onChange={(value: string) => {
                    setFieldValue('type', value);
                  }}
                  label="Community Type"
                  description={'Eg. union, association, school, church, company, etc'}
                  placeholder="Select community type"
                  list={getSelectOptionsFromEnum(COMMUNITY_TYPES)}
                  required
                  error={touched.type ? errors.type : undefined}
                  helperText={touched?.type ? errors.type : undefined}
                  selectInputClassName="rounded"
                />
              </div>

              {/* Address */}
              <div className="flex flex-col space-y-6">
                <h3 className="font-heading font-[600] text-[20px] text-[#455A64] leading-[30px] m-0 p-0">
                  Address
                </h3>
                <PlaceComponent
                  name="streetName"
                  label="Street "
                  classes="border border-gray-200 bg-transparent rounded-[0px] placeholder-[#848484] focus:ring-0 px-4 py-3 focus:outline-none resize-none rounded"
                  value={values.streetName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Enter street address."
                  error={touched?.streetName ? errors.streetName : undefined}
                  helperText={touched?.streetName ? errors.streetName : undefined}
                  onAddressChange={(location: string) => {
                    setFieldValue('streetName', location);
                  }}
                />

                <div className="grid grid-cols-1 lg:grid-cols-2 gap-10  ">
                  <Input
                    type={'text'}
                    name="city"
                    value={values.city}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label="City"
                    placeholder="Enter city"
                    required
                    error={touched.city ? errors.city : undefined}
                    helperText={touched?.city ? errors.city : undefined}
                    className="rounded"
                  />
                  <SelectMenu
                    name="region"
                    listSelectedValue={values.region}
                    onChange={(value: string) => {
                      setFieldValue('region', value);
                    }}
                    label="Region"
                    placeholder="Select region/state"
                    list={GHANA_REGIONS}
                    required
                    error={touched.region ? errors.region : undefined}
                    helperText={touched?.region ? errors.region : undefined}
                    selectInputClassName="rounded"
                  />
                  <Input
                    type={'text'}
                    name="postalCode"
                    value={values.postalCode}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label="Postal Code / Digital Address"
                    placeholder="Enter postal code / digital address"
                    required
                    error={touched.postalCode ? errors.postalCode : undefined}
                    helperText={touched?.postalCode ? errors.postalCode : undefined}
                    className="rounded"
                  />
                  {/* <Input
                                        name="country"
                                        value={values.country}
                                        onChange={handleChange}
                                        onBlur={handleChange}
                                        label="Country"
                                        placeholder="Enter country"
                                        required
                                        error={touched.country ? errors.country : undefined}
                                        helperText={touched?.country ? errors.country : undefined}
                                        className="rounded"
                                    /> */}
                  <SelectMenu
                    name="country"
                    listSelectedValue={values.country}
                    onChange={(value: string) => {
                      setFieldValue('country', value);
                    }}
                    label="Country"
                    placeholder="Enter country"
                    list={COUNTRY_OPTIONS}
                    required
                    error={touched.country ? errors.country : undefined}
                    helperText={touched?.country ? errors.country : undefined}
                    selectInputClassName="rounded"
                  />
                </div>
              </div>

              {/* Contact Person */}
              <div className="flex flex-col space-y-6">
                <h3 className="font-heading font-[600] text-[20px] text-[#455A64] leading-[30px] m-0 p-0">
                  Contact Person
                </h3>
                <Input
                  type={'text'}
                  name="contactPersonName"
                  value={values.contactPersonName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  label="Name"
                  placeholder="Enter contact person's name name"
                  required
                  error={touched.contactPersonName ? errors.contactPersonName : undefined}
                  helperText={touched?.contactPersonName ? errors.contactPersonName : undefined}
                  className="rounded"
                />
                <Input
                  type={'text'}
                  name="contactPersonEmail"
                  value={values.contactPersonEmail}
                  onChange={handleChange}
                  onBlur={handleChange}
                  label="Email"
                  placeholder="Enter contact person's email"
                  required
                  error={touched.contactPersonEmail ? errors.contactPersonEmail : undefined}
                  helperText={touched?.contactPersonEmail ? errors.contactPersonEmail : undefined}
                  className="rounded"
                />
                <Input
                  type={'tel'}
                  name="contactPersonPhone"
                  value={values.contactPersonPhone}
                  onChange={handleChange}
                  onBlur={handleChange}
                  label="Phone number"
                  placeholder="024 444 4444"
                  required
                  error={touched.contactPersonPhone ? errors.contactPersonPhone : undefined}
                  helperText={touched?.contactPersonPhone ? errors.contactPersonPhone : undefined}
                  className="rounded"
                />
              </div>

              <div className="flex-col-reverse gap-5 flex md:flex-row  mt-10 ">
                <div className="w-full md:w-1/2">
                  <Button
                    label="Cancel"
                    onClick={props.onClose}
                    curved
                    className=" w-full px-[12px] py-[12px] h-[45px] rounded border border-primary-border hover:bg-primary-main "
                  />
                </div>

                <div className="w-full md:w-1/2">
                  <Button
                    label="Save"
                    className="w-full px-[12px] py-[12px] h-[45px] rounded border border-primary-border hover:bg-primary-main "
                    onClick={handleSubmit}
                    curved
                    bgColor="primary"
                    loading={submitting}
                    disabled={submitting}
                  />
                </div>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default AddCommunity;
