import React, { ChangeEvent, useEffect, useState } from 'react';
import Input from './form-control/Input';
import Button from './form-control/Button';
import clsx from 'clsx';

export interface IVerifyInput {
  name?: string;
  id?: string;
  readOnly?: boolean;
  verified: boolean;
  label?: string;
  value?: string;
  onClick: () => void;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void | null | any;
  error?: string | undefined;
  helperText?: string;
  required?: boolean;
}
function VerifyInput({
  readOnly,
  verified,
  label,
  value,
  onClick,
  onChange,
  name,
  error,
  helperText,
  id,
  required = false
}: IVerifyInput) {
  const [touched, setTouched] = useState<boolean>(false);

  return (
    <div>
      <div className="flex flex-col md:flex-row md:items-center">
        <Input
          name={name}
          id={id}
          readOnly={readOnly}
          label={label}
          type="text"
          value={value}
          className={clsx(' !rounded-r-none', readOnly && '!bg-[#D1D5DB]')}
          onChange={onChange}
          required={required}
          error={touched ? error : undefined}
          helperText={touched ? helperText : undefined}
        />

        {value &&
          <div className="">
            {verified ? (
              <div
                className={clsx(
                  'w-fit px-2 text-green-500 font-bold cursor-default',
                  label && 'mt-6'
                )}>
                Verified
              </div>
            ) : (
              <div
                onClick={onClick}
                className={clsx(
                  'md:w-[6rem] font-bold text-xs text-red-500 px-1 cursor-pointer ',
                  label && 'mt-6'
                )}>
                Click to verify
              </div>
            )}
          </div>}

      </div>
    </div>
  );
}

export default VerifyInput;
