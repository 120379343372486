import React from 'react';
import { ILeadActivityStreams } from '../models/lead/ILead';
import { ACTIVITY_STREAMS_TYPE } from '../resources/enums';
import {
  CalendarIcon,
  CreditCardIcon,
  DocumentTextIcon,
  ListBulletIcon,
  PencilIcon,
  PencilSquareIcon,
  TruckIcon
} from '@heroicons/react/24/solid';
import moment from 'moment';
import clsx from 'clsx';
import { ClipboardDocumentCheckIcon, DocumentIcon, UserIcon } from '@heroicons/react/24/outline';
import { IoRefreshCircleOutline } from 'react-icons/io5';
import { MdEditNotifications } from 'react-icons/md';
import { IActivityStreams } from '../resources/interfaces';
import Loader from './Loader';

interface ActivityStepperProps {
  activityStreams: IActivityStreams[] | null;
  currentStep: number;
  setCurrentStep?: React.Dispatch<React.SetStateAction<number>>;
  orientation?: 'horizontal' | 'vertical';
  clickable?: boolean;
  isLoading?: boolean;
  page?:string;
}

const ActivityStepper: React.FC<ActivityStepperProps> = ({
  activityStreams,
  currentStep,
  setCurrentStep,
  orientation = 'vertical',
  clickable = false,
  isLoading = false,
  page
}) => {
  const isHorizontal = orientation === 'horizontal';

  const handleClick = (index: number) => {
    if (clickable) {
      setCurrentStep?.(index);
    }
  };

  const getActivityIconFromType = (activity: ACTIVITY_STREAMS_TYPE) => {
    switch (activity) {
      case ACTIVITY_STREAMS_TYPE.CREATE:
        return <MdEditNotifications className="h-5 w-5" />;

      case ACTIVITY_STREAMS_TYPE.PAYMENT:
        return <CreditCardIcon className="h-5 w-5" />;

      case ACTIVITY_STREAMS_TYPE.POLICY:
        return <DocumentIcon className="h-5 w-5" />;

      case ACTIVITY_STREAMS_TYPE.TASK:
        return <ClipboardDocumentCheckIcon className="h-5 w-5" />;

      case ACTIVITY_STREAMS_TYPE.UPDATE:
        return <IoRefreshCircleOutline className="h-5 w-5" />;

      case ACTIVITY_STREAMS_TYPE.LEAD:
        return <UserIcon className="h-5 w-5" />;

      case ACTIVITY_STREAMS_TYPE.VEHICLE:
        return <TruckIcon className="h-5 w-5" />;

      default:
        return <CalendarIcon className="h-5 w-5" />;
    }
  };

  return (
    <div className="px-3">
      <h2 className="text-sm py-3">Activities</h2>
      <div className={`flex div ${isLoading ? '' : page =='Dashboard' && 'max-h-60 overflow-y-auto'} ${isHorizontal ? 'items-center' : 'flex-col'}`}>
        {isLoading && (
          <div className="flex justify-center items-center max-h-40">
            <Loader brollyLoader />
          </div>
        )}
        {!isLoading &&
          activityStreams &&
          activityStreams.map((activity: ILeadActivityStreams, index: number) => (
            <React.Fragment key={index}>
              <div
                className={`flex gap-2 ${isHorizontal ? 'mr-4' : ''} ${
                  clickable ? 'cursor-pointer' : ''
                }`}
                onClick={() => handleClick(index)}>
                <div className="space-y-2">
                  <div
                    className={`flex items-center justify-center size-8 border rounded-full p-2 ${
                      index === currentStep && clickable
                        ? 'bg-primary-main text-white'
                        : 'border-gray-300 text-gray-500'
                    } ${isHorizontal ? 'mr-2' : 'mr-0'}`}>
                    {getActivityIconFromType(activity.type)}
                  </div>
                  {!isHorizontal && index + 1 !== activityStreams.length && (
                    <div className="w-8 flex justify-center">
                      <div className={clsx('border-l-[1px] border-gray-300 min-h-[1.7rem]')}></div>
                    </div>
                  )}
                </div>
                <div className={`text-sm ${isHorizontal ? 'mr-2' : 'mr-0'}`}>
                  <span className="text-xs text-gray-500">
                    {moment(activity?.createdOn).format('lll') ?? 'N/A'}
                  </span>
                  <br />
                  <span className="text-xs">{activity?.activity}</span>
                </div>
              </div>
            </React.Fragment>
          ))}

        {!isLoading && (!activityStreams || activityStreams.length < 1) && (
          <div className="flex justify-center items-center h-40">
            <p className="text-gray-500 text-sm">No Activity</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default ActivityStepper;
