import { Form, Formik } from 'formik';
import { useEffect, useLayoutEffect, useState } from 'react';
import * as Yup from 'yup';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Input from '../../../components/form-control/Input';
import {
  getFormattedPhoneNumber,
  getIdPlaceholder,
  getIdTypeFromString,
  getIdTypeFromValue,
  getInstallmentPeriodFromKey,
  getInstallmentTypeFromKey,
  getListFromRecord,
  getPaystackConfig,
  getPhoneNumberFromShortCodeFormat,
  getSelectableManufacturingYears,
  isCommercial,
  isThirdPartyProtection,
  numberWithCommas,
  sentenceCase
} from '../../../utils/miscFunctions';
import TextArea from '../../../components/form/TextArea';
import {
  adminPoliciesVerifyPhoneOTP,
  getAllCommunities,
  getAllInsurers,
  getPolicy,
  getYangoParks,
  sendRefereeOTP,
  verifyVehicle
} from '../../../api/requests';
import { toast } from 'react-toastify';
import { InputRadio } from '../../../components/form/InputRadio';
import Loader from '../../../components/Loader';
import Button from '../../../components/form-control/Button';
import SelectMenu, {
  ISelectMenuItemValue,
  MiniSelectMenu
} from '../../../components/form-control/SelectMenu';
import {
  UPLOADABLE_DOCUMENT_TYPES,
  UPLOAD_IMAGE_ENDPOINTS,
  VEHICLE_BODY_TYPES_LIST,
  POLICY_REVIEW_STATES,
  ID_TYPES_DTO,
  POLICY_DOCUMENT_TYPES
} from '../../../resources/enums';
import {
  ALPHABETS,
  EMPLOYEE_INCOME_BAND,
  GHANA_REGIONS,
  INPUT_DATE_FORMAT,
  OCCUPATION_TYPES,
  RIDE_SHARING_DAILY_AVERAGE_INCOME,
  VEHICLE_COLORS,
  VEHICLE_PROTECTION_TYPES,
  VEHICLE_USAGES
} from '../../../resources/constants';
import usePolicy from '../../../hooks/policyHook';
import { Policy } from '../../../models/policy/Policy';
import moment from 'moment';
import useResource from '../../../hooks/resourcesHook';
import { Insurer } from '../../../models/insurer/Insurer';
import SelectDropdown, {
  ISelectDropdownItem
} from '../../../components/form-control/SelectDropdown';
import { IPolicyDocument, IRequestErrorResponse } from '../../../resources/interfaces';
import useRoute from '../../../hooks/routesHook';
import _ from 'lodash';
import useModal from '../../../hooks/modalHook';
import ReviewPolicy from './ReviewPolicy';
import { ReactComponent as OnHoldIcon } from './imgs/on-hold.svg';
import FileUpload from '../../../components/elements/FileUpload/FileUpload';
import React from 'react';
import useImage from '../../../hooks/imageHook';
import { User } from '../../../models/User';
import useUserAccount from '../../../hooks/userAccountHook';
import PopOnMe from '../../../components/elements/poponme';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import InputSelectMenu from '../../../components/form-control/InputSelectMenu';
import { Community } from '../../../models/community/Community';
import { ICommunity } from '../../../models/community/ICommunity';
import AddCommunity from '../communities/AddCommunity';
import PlaceComponent from '../../../components/PlaceComponent';
import CustomerPhoneVerificationModal from './PhoneVerificationModal';
import BackButton from '../../../components/BackButton';
import usePayment from '../../../hooks/paymentHook';
import VerifyInput from '../../../components/VerifyInput';
import ChangePhoneNumberModal from './ChangePhoneNumberModal';
import MultiSelectDropdown, {
  IMultiSelectDropdownItem
} from '../../../components/form-control/MultiSelectDropdown';

const labelClass = 'font-semibold w-full';
const VEHICLE_USAGES_LIST = getListFromRecord(VEHICLE_USAGES, 'title');
const PROTECTION_TYPES = getListFromRecord(VEHICLE_PROTECTION_TYPES, 'title');
const OCCUPATIONS = getListFromRecord(OCCUPATION_TYPES, 'title');
const MANUFACTURED_YEARS_LIST = getSelectableManufacturingYears();
const REFEREE_RELATIONSHIP_OPTIONS = ['Friend', 'Family member', 'Husband/Wife', 'Owner'];

interface IVehiclePhotoFiles {
  front: File | null;
  back: File | null;
  right: File | null;
  left: File | null;
}

interface IPolicyDocumentFiles {
  sticker: File | null;
  insuranceCertificate: File | null;
  contractSummary: File | null;
  renewalNotice: File | null;
  roadWorthy: File | null;
}
interface IPolicyOwnerDocumentFiles {
  ownerPhoto: File | null;
  driverLicenseFront: File | null;
  driverLicenseBack: File | null;
  ownerId: File | null;
}
export interface IVerifyPolicyRefereeOTP {
  identifier: string;
  policyNumber?: string;
  verificationCode: string;
  customerID?: string;
}

export const NEW_OWNER_YET_TO_CHANGE_AT_DVLA = 'New owner, yet to change at DVLA';
const WORK_AND_PAY_DRIVER = 'Work & Pay Driver';
const HIRE_PURCHASER = 'Hire Purchaser';
const EMPLOYEE_DRIVER = 'Employee Driver';
const OTHER_AUTHORISED_KEEPER_OR_USER = 'Other authorised keeper/user';
const OWNER_IN_DVLA_RECORDS = 'Owner in DVLA records';

const booleanSelectDropdownList = [
  {
    name: 'No',
    value: 'no'
  },
  {
    name: 'Yes',
    value: 'yes'
  }
];

const EditPolicy = (): JSX.Element => {
  const navigate = useNavigate();
  const { updatePolicyDetails } = usePolicy();
  const { getVehicleMakes } = useResource();
  const { uploadVehicleImage, uploadPolicyDocument, uploadPolicyOwnerDocument } = useImage();
  const { getDashboardBase } = useRoute();
  const { ModalComponent, updateModalStates, closeModal } = useModal();
  const { getProfile, getInsurer } = useUserAccount();

  const params = useParams();

  const policyId = params.policyId;
  const user = new User(getProfile());

  const [loading, setLoading] = useState<boolean>(false);
  const [policy, setPolicy] = useState<Policy>();
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [vehicleMakes, setVehicleMakes] = useState<string[]>([]);
  const [insurers, setInsurers] = useState<Insurer[]>([]);
  const [parks, setParks] = useState<any>([]);
  const [saveAndSubmit, setSaveAndSubmit] = useState<boolean>(false);
  const [communities, setCommunities] = useState<Community[]>([]);
  const [initiateRecurringPayment, setInitiateRecurringPayment] = useState<boolean>(false);
  const [makePayment, setMakePayment] = useState<boolean>(false);
  const [validManufacturingYears, setValidManufacturingYears] = useState(
    getSelectableManufacturingYears()
  );
  const [vehicleVerified, setVehicleVerified] = useState<boolean>(false);
  const ID_TYPES_LIST = policy?.isFullPayment()
    ? [{ name: 'National ID', value: ID_TYPES_DTO.NATIONAL_ID }]
    : Object.keys(ID_TYPES_DTO)?.map((key: string) => ({
        name: getIdTypeFromValue(key) ?? '',
        value: key
      }));

  // Files
  const [vehiclePhotoFiles, setVehiclePhotoFiles] = React.useState<IVehiclePhotoFiles>({
    front: null,
    back: null,
    right: null,
    left: null
  });
  const [policyDocumentFiles, setPolicyDocumentFiles] = useState<IPolicyDocumentFiles>({
    sticker: null,
    roadWorthy: null,
    renewalNotice: null,
    insuranceCertificate: null,
    contractSummary: null
  });
  const [policyOwnerDocumentFiles, setPolicyOwnerDocumentFiles] =
    useState<IPolicyOwnerDocumentFiles>({
      ownerPhoto: null,
      driverLicenseFront: null,
      driverLicenseBack: null,
      ownerId: null
    });

  const [verified, setVerified] = useState<boolean>(false);
  const paystackConfig = getPaystackConfig(policy);
  const [registrationYearSuffix, setRegistrationYearSuffix] = useState<string>(
    predictRegYearSuffix(String(policy?.vehicle?.registrationYear ?? ''))
  );
  const { choosePaymentService, PaymentModalComponent, choosePaymentOption, paymentCompleted } =
    usePayment({
      paystackConfig: paystackConfig,
      policy: policy
    });

  useLayoutEffect(() => {
    if (policyId) {
      fetchPolicy(policyId);
    }
  }, [policyId]);

  useEffect(() => {
    fetchVehicleMakes();
    getInsurers();
    getCommunities();
    setRegistrationYearSuffix(
      predictRegYearSuffix(String(policy?.vehicle?.registrationYear ?? ''))
    );
  }, []);

  useEffect(() => {
    if (policy?.isYango()) {
      getYangoParks()
        .then((response: any) => {
          console.log('Parks', response);
          setParks(response);
        })
        .catch((error: any) => {
          toast.error(error.message);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [policy]);

  useEffect(() => {
    if (paymentCompleted) {
      fetchPolicy(policyId ?? '');
      submitForReview();
    }
  }, [paymentCompleted]);

  function formatRegistrationNumber() {
    let regNumber = '';

    if (
      Number(policy?.vehicle?.registrationYear) >= 2008 &&
      policy?.vehicle?.registrationNumber?.includes('-')
    ) {
      regNumber = policy?.vehicle?.registrationNumber?.split('-')[0]?.trim();
    } else {
      regNumber = policy?.vehicle?.registrationNumber?.split(' ')[0]?.trim() ?? '';
    }
    return regNumber?.trim();
  }

  const getInitValues = () => {
    const initData = {
      firstName: policy?.applicant?.firstName ?? '',
      lastName: policy?.applicant?.lastName ?? '',
      phoneNumber: getPhoneNumberFromShortCodeFormat(policy?.applicant?.phoneNumber ?? ''),
      otherPhone: getPhoneNumberFromShortCodeFormat(policy?.applicant?.otherPhone ?? ''),
      email: policy?.applicant?.email ?? '',
      address: policy?.applicant?.address ?? '',
      occupation: policy?.applicant?.occupation ?? '',
      protectionType: policy?.quote?.protectionType?.title ?? '',
      excess: policy?.quote?.quotePlan?.excess ?? 0,
      vehicleInsuredValue: policy?.vehicle?.insuredValue ?? 0,
      vehicleType: policy?.vehicle?.type?.name,
      makeOfVehicle: policy?.vehicle?.make,
      vehicleModel: policy?.vehicle?.model,
      vehicleColor: policy?.vehicle?.color,
      vehicleUse: policy?.vehicle?.usage?.title,
      vehicleMake: policy?.vehicle?.make,
      numOfPassenger: policy?.vehicle?.maxSeat,
      goodRepairState:
        policy?.vehicle?.goodRepairState == true
          ? 'yes'
          : policy?.vehicle?.goodRepairState == false
          ? 'no'
          : undefined,
      repairState: policy?.vehicle?.repairState,
      chassisNumber: policy?.vehicle?.chassisNumber,
      registrationNumber: formatRegistrationNumber(),
      registrationYear: policy?.vehicle?.registrationYear ?? '',
      vehicleRegion: policy?.vehicle?.region,
      ownerName: policy?.vehicle?.ownerName ?? '',
      vehicleAltered:
        policy?.vehicle?.vehicleAltered == true
          ? 'yes'
          : policy?.vehicle?.vehicleAltered == false
          ? 'no'
          : undefined,
      alterationDetails: policy?.vehicle?.alterationDetails,
      hasInterestedParties:
        policy?.vehicle?.hasInterestedParties == true
          ? 'yes'
          : policy?.vehicle?.hasInterestedParties == false
          ? 'no'
          : 'no',
      interestedParties: policy?.vehicle?.interestedParties ?? '',
      vehicleMainDriver: policy?.vehicle?.driver?.fullName ?? '',
      hasDisease:
        policy?.vehicle?.driver?.hasDisease == true
          ? 'yes'
          : policy?.vehicle?.driver?.hasDisease == false
          ? 'no'
          : undefined,
      diseaseOrComplications: policy?.vehicle?.driver?.diseaseOrComplications,
      previouslyDeclined:
        policy?.previouslyDeclined == true
          ? 'yes'
          : policy?.previouslyDeclined == false
          ? 'no'
          : undefined,
      declinedByOtherInsurer: policy?.declinedInsuranceDetails,
      previouslyIssuedClaim:
        policy?.previouslyIssuedClaim == true
          ? 'yes'
          : policy?.previouslyIssuedClaim == false
          ? 'no'
          : undefined,
      issuedClaimDetails: policy?.previousInsuranceClaims,
      cc: policy?.vehicle?.cc ?? 0,
      powerType: 'cc',
      dateOfBirth: policy?.applicant?.dateOfBirth
        ? moment(policy?.applicant?.dateOfBirth).format(INPUT_DATE_FORMAT)
        : '',
      idExpiryDate: policy?.applicant?.idExpiryDate
        ? moment(policy?.applicant?.idExpiryDate).format(INPUT_DATE_FORMAT)
        : '',
      manufacturedYear: policy?.vehicle?.manufacturedYear,
      idNumber: policy?.applicant?.idNumber ?? '',
      idType: getIdTypeFromString(policy?.applicant?.idType ?? '') ?? ID_TYPES_DTO.NATIONAL_ID,
      employerName: policy?.applicant?.employerName,
      employerAddress: policy?.applicant?.employerAddress,
      employerPhoneNumber: getPhoneNumberFromShortCodeFormat(
        policy?.applicant?.employerPhoneNumber ?? ''
      ),
      monthlyIncome: policy?.applicant?.monthlyIncome,
      insurer: policy?.insurer?.id ?? getInsurer().id,
      refereeName: policy?.referee?.name ?? '',
      refereePhone: getPhoneNumberFromShortCodeFormat(policy?.referee?.phone ?? ''),
      refereeYearsKnown: policy?.referee?.yearsKnown ?? '',
      refereeRelationship: policy?.referee?.relationship ?? '',
      code: '',
      community: policy?.community?.id,
      startsOn: policy?.startsOn ? moment(policy?.startsOn).format(INPUT_DATE_FORMAT) : '',
      composerIsOwner: policy?.composerIsOwner() ? 'yes' : 'no',
      natureOfInterestInVehicle: policy?.vehicle?.natureOfInterest ?? '',
      ownerAddress: policy?.vehicle?.ownerAddress ?? '',
      ownerPhone: getPhoneNumberFromShortCodeFormat(policy?.vehicle?.ownerPhoneNumber ?? ''),
      hasInstalledAccessories:
        policy?.vehicle?.hasInstalledAccessories == true
          ? 'yes'
          : policy?.vehicle?.hasInstalledAccessories == false
          ? 'no'
          : '',
      installedAccessoriesDetails: policy?.vehicle?.installedAccessoriesDetails ?? '',
      installedAccessoriesValue: policy?.vehicle?.installedAccessoriesValue ?? 0,
      rideSharePeriod: policy?.applicant?.rideSharePeriod ?? 0,
      liability:
        policy?.applicant?.liability == true
          ? 'yes'
          : policy?.applicant?.liability == false
          ? 'no'
          : '',
      liabilityDetails: policy?.applicant?.liabilityDetails ?? '',
      liabilityDebt: policy?.applicant?.liabilityDebt,
      liabilityModeOfPayment: policy?.applicant?.liabilityModeOfPayment,
      parkId: policy?.owner?.id,
    };

    return initData;
  };

  function fetchVehicleMakes() {
    getVehicleMakes().then((vehicleMakes) => {
      setVehicleMakes(vehicleMakes);
    });
  }

  const fetchPolicy = (policyId: string) => {
    setLoading(true);

    getPolicy(policyId)
      .then((response) => {
        setPolicy(new Policy(response.data));
      })
      .catch((error: Error) => {
        toast.error('Failed to fetch policy details. Please try again later.');
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    setRegistrationYearSuffix(predictRegYearSuffix(String(policy?.vehicle?.registrationYear)));
    const registrationYear = policy?.vehicle?.registrationYear;
    if (registrationYear !== undefined)
      setValidManufacturingYears(
        getSelectableManufacturingYears().filter((y) => y <= registrationYear)
      );
  }, [policy]);

  const getCommunities = (page = 0) => {
    getAllCommunities(page, 100, '')
      .then((response: any) => {
        const _communities = response?.data?.data?.map((_community: ICommunity) => {
          return new Community(_community);
        });
        setCommunities(response?.data?.data);
      })
      .catch((error: Error) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  const getInsurers = (page = 0) => {
    getAllInsurers(page, 100)
      .then((response: any) => {
        const _insurers = response?.data?.content?.map((_insurer: any) => {
          return new Insurer(_insurer);
        });
        setInsurers(_insurers);
      })
      .catch((error: any) => {
        toast.error(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  function predictRegYearSuffix(registrationYear: string): string {
    let suffix = '';

    if (Number(registrationYear) >= 2008) {
      suffix = `-${registrationYear?.substring(
        registrationYear.length - 2,
        registrationYear.length
      )}`;
    } else {
      const startYear = 2008;
      let count = 0;

      while (Number(registrationYear) <= startYear) {
        if (Number(registrationYear) === startYear - count) {
          suffix = ALPHABETS[ALPHABETS.length - count];
          break;
        }
        count++;
      }
    }

    return suffix;
  }

  function submitHandler(values: any) {
    const formattedValues = getPayloadFromValues(values);

    if (policy && values) {
      setSubmitting(true);
      updatePolicyDetails(policy.ref, formattedValues)
        .then((response: any) => {
          uploadImages(values);
          toast.success('Policy details updated successfully');
          if (response) fetchPolicy(policy.ref);
          if (saveAndSubmit) {
            if (!policy.applicant.phoneVerified) {
              toast.error(
                'You need to verify phone number before policy can be submitted for review.',
                {
                  autoClose: 5000
                }
              );
              setSaveAndSubmit(false);
              return;
            }
            setTimeout(() => {
              submitForReview();
            }, 1000);
          }

          if (initiateRecurringPayment) {
            choosePaymentService();
          }

          if (makePayment) {
            choosePaymentOption();
          }
        })
        .catch((error: IRequestErrorResponse) => {
          toast.error(error.detail, {
            autoClose: 3000
          });
        })
        .finally(() => {
          setSubmitting(false); 
        });
    }
  }

  const uploadImages = (formData: any) => {
    const url = UPLOAD_IMAGE_ENDPOINTS.POLICY;
    const imageRequests: any[] = [];
    const policyDocumentRequests: any[] = [];

    Object.keys(vehiclePhotoFiles).forEach((key: any, index) => {
      if (vehiclePhotoFiles[key as keyof IVehiclePhotoFiles]) {
        imageRequests.push(
          uploadVehicleImage(
            vehiclePhotoFiles[key as keyof IVehiclePhotoFiles] ?? '',
            getVehicleImageType(key),
            UPLOAD_IMAGE_ENDPOINTS.VEHICLE,
            policy?.vehicle?.id ?? ''
          )
        );
      }
    });

    Object.keys(policyDocumentFiles).forEach((key: any, index) => {
      if (policyDocumentFiles[key as keyof IPolicyDocumentFiles]) {
        policyDocumentRequests.push(
          uploadPolicyDocument(
            policyDocumentFiles[key as keyof IPolicyDocumentFiles] ?? '',
            getPolicyDocumentType(key),
            UPLOAD_IMAGE_ENDPOINTS.POLICY,
            policy?.ref ?? ''
          )
        );
      }
    });

    Object.keys(policyOwnerDocumentFiles).forEach((key: any, index) => {
      if (policyOwnerDocumentFiles[key as keyof IPolicyOwnerDocumentFiles]) {
        policyDocumentRequests.push(
          uploadPolicyOwnerDocument(
            policyOwnerDocumentFiles[key as keyof IPolicyOwnerDocumentFiles] ?? '',
            getPolicyDocumentType(key),
            UPLOAD_IMAGE_ENDPOINTS.CUSTOMER,
            policy?.applicant?.customerId ?? ''
          )
        );
      }
    });

    Promise.all([...imageRequests, ...policyDocumentRequests]).then(
      (response: any) => {},
      (error: any) => {}
    );
  };

  const getPayloadFromValues = (values: IFormData) => {
    if (values.powerType == 'hp') values.cc = Math.round(values.cc * 15);

    if (values.composerIsOwner === 'yes') {
      values.ownerName = `${values.firstName} ${values.lastName}`;
      values.ownerAddress = values.address;
      values.ownerPhone = values.phoneNumber;
      values.natureOfInterestInVehicle = OWNER_IN_DVLA_RECORDS;
    }

    if (
      values.composerIsOwner === 'no' &&
      values.natureOfInterestInVehicle === NEW_OWNER_YET_TO_CHANGE_AT_DVLA
    ) {
      values.ownerName = `${values.firstName} ${values.lastName}`;
      values.ownerAddress = values.address;
      values.ownerPhone = values.phoneNumber;
    }

    if (policy?.isRideSharing()) {
      values.employerAddress = values.address;
      values.employerName = 'Self Employed';
      values.employerPhoneNumber = values.phoneNumber;
    }

    const registrationNumber =
      Number(policy?.vehicle?.registrationYear) >= 2008 &&
      policy?.vehicle?.registrationNumber?.includes('-')
        ? `${values.registrationNumber}${registrationYearSuffix}`
        : `${values.registrationNumber} ${registrationYearSuffix}`;

    return {
      ...values,
      hasInterestedParties: values.hasInterestedParties === 'yes' ? true : false,
      hasInstalledAccessories: values.hasInstalledAccessories ? true : false,
      registrationNumber: registrationNumber,
      goodRepairState: values.goodRepairState === 'yes' ? true : false,
      vehicleAltered: values.vehicleAltered === 'yes' ? true : false,
      previouslyDeclined: values.previouslyDeclined === 'yes' ? true : false,
      previouslyIssuedClaim: values.previouslyIssuedClaim === 'yes' ? true : false,
      liability: values.liability === 'yes' ? true : false
    };
  };

  const getVehicleImageType = (
    key: 'front' | 'back' | 'right' | 'left'
  ): UPLOADABLE_DOCUMENT_TYPES => {
    switch (key) {
      case 'front':
        return UPLOADABLE_DOCUMENT_TYPES.VEHICLE_PHOTO_FRONT;

      case 'back':
        return UPLOADABLE_DOCUMENT_TYPES.VEHICLE_PHOTO_BACK;

      case 'left':
        return UPLOADABLE_DOCUMENT_TYPES.VEHICLE_PHOTO_LEFT;

      case 'right':
        return UPLOADABLE_DOCUMENT_TYPES.VEHICLE_PHOTO_RIGHT;

      default:
        return UPLOADABLE_DOCUMENT_TYPES.DRIVER_LICENSE_FRONT;
    }
  };

  const getPolicyDocumentType = (
    key:
      | 'ownerPhoto'
      | 'ownerId'
      | 'roadWorthy'
      | 'driverLicenseFront'
      | 'driverLicenseBack'
      | 'renewalNotice'
      | 'insuranceCertificate'
      | 'sticker'
      | 'contractSummary'
  ): UPLOADABLE_DOCUMENT_TYPES => {
    switch (key) {
      case 'ownerPhoto':
        return UPLOADABLE_DOCUMENT_TYPES.PASSPORT_PHOTO;

      case 'ownerId':
        return UPLOADABLE_DOCUMENT_TYPES.ID_CARD;

      case 'roadWorthy':
        return UPLOADABLE_DOCUMENT_TYPES.ROADWORTHY;

      case 'driverLicenseFront':
        return UPLOADABLE_DOCUMENT_TYPES.DRIVER_LICENSE_FRONT;

      case 'driverLicenseBack':
        return UPLOADABLE_DOCUMENT_TYPES.DRIVER_LICENSE_BACK;

      case 'renewalNotice':
        return UPLOADABLE_DOCUMENT_TYPES.RENEWAL_NOTICE;

      case 'insuranceCertificate':
        return UPLOADABLE_DOCUMENT_TYPES.INSURANCE_CERTIFICATE;

      case 'sticker':
        return UPLOADABLE_DOCUMENT_TYPES.INSURANCE_STICKER;

      case 'contractSummary':
        return UPLOADABLE_DOCUMENT_TYPES.CONTRACT_SUMMARY;

      default:
        return UPLOADABLE_DOCUMENT_TYPES.ID_CARD;
    }
  };

  const submitForReview = () => {
    updateModalStates({
      show: true,
      children: (
        <ReviewPolicy
          policy={policy}
          onProceed={() => {
            closeModal();
            navigate(`${getDashboardBase()}/policies/${policyId}`, { replace: true });
          }}
          onCancel={closeModal}
          title="Submit for review"
          titleIcon={<OnHoldIcon />}
          type={POLICY_REVIEW_STATES.IN_REVIEW}
        />
      ),
      showTitleSection: false,
      classes: '!p-0 r-[5px]',
      size: 'lg'
    });
  };

  const getSaveActions = (submitForm: any, errors?: any) => {
    const saveActions = [
      {
        label: 'Save and continue later',
        callback: () => {
          submitForm();
        }
      }
    ];

    if (policy?.metaData?.firstPaymentReceived) {
      saveActions.push({
        label: 'Save and submit for review',
        callback: () => {
          setSaveAndSubmit(true);
          setMakePayment(false);
          setInitiateRecurringPayment(false);
          submitForm();
        }
      });
    }

    if (
      user.isAgent() ||
      user.isAdmin() ||
      user.isAgencyAdmin() ||
      user.isInsurerAdmin() ||
      user.isInsurerPolicyAdmin()
    ) {
      if (policy?.isFullPayment()) {
        saveActions.unshift({
          label: 'Save and make payment',
          callback: () => {
            setMakePayment(true);
            setSaveAndSubmit(false);
            setInitiateRecurringPayment(false);
            submitForm();
          }
        });
      } else {
        saveActions.unshift({
          label: 'Save and initiate recurring payment',
          callback: () => {
            setMakePayment(false);
            setSaveAndSubmit(false);
            setInitiateRecurringPayment(true);
            submitForm();
          }
        });
      }
    }

    return saveActions;
  };

  const sendRefOTP = (values: any) => {
    let policyNumber = policy?.ref;
    let phone = getFormattedPhoneNumber(values.refereePhone);
    sendRefereeOTP(phone, policyNumber)
      .then((response: any) => {
        toast.success('Verification code sent successfully');
      })
      .catch((error: any) => {
        toast.error(error.message);
      });
  };

  const verifyRefereeOTP = (values: any) => {
    const VERIFICATION_CODE_CONSTANT = 'B-';
    const payload: IVerifyPolicyRefereeOTP = {
      verificationCode: VERIFICATION_CODE_CONSTANT + values.code,
      policyNumber: policy?.ref,
      identifier: getFormattedPhoneNumber(values.refereePhone)
    };

    adminPoliciesVerifyPhoneOTP(payload)
      .then((response: any) => {
        toast.success(response.message ?? 'Verification successful');
        setVerified(true);
        window.location.reload();
      })
      .catch((error: any) => {
        toast.error('Please check the code');
      });
  };

  const addCommunity = () => {
    updateModalStates({
      show: true,
      children: (
        <AddCommunity
          onClose={closeModal}
          onProceed={(community: Community) => {
            setCommunities([community, ...communities]);
            closeModal();
          }}
        />
      ),
      size: '2xl'
    });
  };

  const showCustomerPhoneVerificationModal = (phone: string) => {
    updateModalStates({
      show: true,
      size: 'md',
      children: (
        <CustomerPhoneVerificationModal
          policy={policy}
          close={closeModal}
          onProceed={() => {
            closeModal();
            const _policy = policy;
            if (_policy) {
              _policy.applicant.phoneVerified = true;
              setPolicy(_policy);
            }
          }}
          phoneNumber={phone}
        />
      ),
      showTitleSection: false,
      classes: '!p-0 r-[5px]'
    });
  };

  const manuallyVerifyVIN = async (values: any) => {
    const formattedValues = getPayloadFromValues(values);

    if (policy && formattedValues) {
      await updatePolicyDetails(policy.ref, formattedValues);
    }

    const vehicleID = Number(policy?.vehicle?.id);
    try {
      if (vehicleID) {
        const response = await verifyVehicle(vehicleID);

        if (response) {
          toast.success(response.message, {
            autoClose: 5000
          });
          setVehicleVerified(true);
        }
      }
    } catch (error: any) {
      toast.error(error.description, {
        autoClose: 5000
      });
    }
  };

  const changePolicyPhoneNumber = () => {
    updateModalStates({
      show: true,
      size: 'md',
      children: (
        <ChangePhoneNumberModal
          onClose={closeModal}
          onProceed={() => {
            closeModal();
            // const _policy = policy;
            // if (_policy) {
            //   _policy.applicant.phoneVerified = true;
            //   setPolicy(_policy);
            // }
          }}
        />
      ),
      showTitleSection: false,
      classes: '!p-0 r-[5px]'
    });
  };

  return (
    <div className="bg-white">
      {loading && !policy?.ref ? (
        <div className="min-h-screen flex items-center justify-center">
          <Loader message="Loading policy data. Please wait..." brollyLoader />
        </div>
      ) : (
        policy &&
        policy.ref && (
          <div className="p-4">
            <div className="flex items-center gap-8 mb-[36px]">
              <Link to={`${getDashboardBase()}/policies/${policy?.ref}`}>
                <BackButton />
              </Link>
              <h1 className="font-semibold text-left text-[32px] leading-[38px]">
                Policy ID: {policy?.ref}
              </h1>
            </div>

            <Formik
              initialValues={getInitValues()}
              initialErrors={_.omit(
                getInitValues(),
                'excess',
                'vehicleInsuredValue',
                'numOfPassenger',
                'registrationYear',
                'hasInterestedParties',
                'cc',
                'manufacturedYear',
                'installedAccessoriesValue',
                'rideSharePeriod',
                'parkId'
              )}
              validateOnBlur
              validationSchema={Yup.object().shape({
                // email: Yup.string().email("Invalid email").trim().required("Email required"),
                // address: Yup.string().required("Address is required"),
                firstName: Yup.string().required('First name is required.'),
                lastName: Yup.string().required('Last name is required.'),
                phoneNumber: Yup.string()
                  .required('Phone number is required.')
                  .min(10, 'Your phone number should be 10 digits')
                  .max(10, 'Your phone number should be 10 digits'),
                vehicleType: Yup.string().required('Vehicle type required.'),
                cc: Yup.number().required('cc required.'),
                insurer: Yup.string().required('Preferred insurer is required.'),
                monthlyIncome: Yup.string().required('Monthly Income is required.'),
                registrationYear: Yup.string().required('Registration year is required.'),
                idExpiryDate: Yup.string().required('Id expiry date is required'),
                dateOfBirth: Yup.string().required('Date of birth is required'),
                vehicleMake: Yup.string().required('Vehicle make is required'),
                vehicleModel: Yup.string().required('Vehicle model is required'),
                vehicleUse: Yup.string().required('Vehicle usage is required.'),
                protectionType: Yup.string().required('Protection type is required.'),
                occupation: Yup.string().required('Occupation is required.'),
                manufacturedYear: Yup.string().required('Vehicle manufactured year is required.'),
                chassisNumber: Yup.string()
                  .required('Vin is required.')
                  .min(17, 'Vin must be 17 characters long'),
                registrationNumber: Yup.string()
                  .required('Registration number is required')
                  .matches(
                    /^\b[A-Z]{2}\s\w{1,5}\b$/,
                    'Invalid format. Please use 2 capital letters, space, then up to 5 numbers or letters. Do not add the suffix'
                  ),
                refereePhone: Yup.string().when('vehicleUse', {
                  is: (usage: string) => {
                    return (
                      isCommercial(usage) &&
                      !policy.isFullPayment() &&
                      !policy.isRideSharing() &&
                      !policy.isBolt()
                    );
                  },
                  then: Yup.string().trim().required('Phone number is required')
                }),
                refereeName: Yup.string().when('vehicleUse', {
                  is: (usage: string) => {
                    return (
                      isCommercial(usage) &&
                      !policy.isFullPayment() &&
                      !policy.isRideSharing() &&
                      !policy.isBolt()
                    );
                  },
                  then: Yup.string().trim().required('Referee full name is required')
                }),
                refereeRelationship: Yup.string().when('vehicleUse', {
                  is: (usage: string) => {
                    return (
                      isCommercial(usage) &&
                      !policy.isFullPayment() &&
                      !policy.isRideSharing() &&
                      !policy.isBolt()
                    );
                  },
                  then: Yup.string().trim().required('Referee relationship with user is required')
                }),
                refereeYearsKnown: Yup.string().when('vehicleUse', {
                  is: (usage: string) => {
                    return (
                      isCommercial(usage) &&
                      !policy.isFullPayment() &&
                      !policy.isRideSharing() &&
                      !policy.isBolt()
                    );
                  },
                  then: Yup.string()
                    .trim()
                    .required('Number of years referee has known user is required')
                }),
                hasInterestedParties: Yup.string().required('Interest in vehicle required.'),
                previouslyDeclined: Yup.string().required(
                  'Prevously declined claim status required'
                ),
                previouslyIssuedClaim: Yup.string().required(
                  'Prevously issued claim status required'
                ),
                goodRepairState: Yup.string().required('Vehicle repair state is required.'),
                repairState: Yup.string().when('goodRepairState', {
                  is: (value: string) => value === 'no',
                  then: Yup.string().trim().required('Vehicle repair state details is required')
                })
                // natureOfinterestInvehicle: Yup.string().required(
                //   'Nature of interest in vehicle is required'
                // ),
                // ownerName: Yup.string().when(['composerIsOwner', 'natureOfinterestInvehicle'], {
                //   is: (composerIsOwner: string, natureOfinterestInvehicle: string) =>
                //     composerIsOwner === 'no' &&
                //     natureOfinterestInvehicle != NEW_OWNER_YET_TO_CHANGE_AT_DVLA,
                //   then: Yup.string().trim().required('Vehicle repair state details are required')
                // })
              })}
              onSubmit={(values) => {
                submitHandler(values);
              }}
              validate={(values) => {}}>
              {({
                values,
                handleSubmit,
                handleChange,
                handleBlur,
                setFieldValue,
                submitForm,
                touched,
                errors,
                isValid,
                isSubmitting
              }) => (
                <Form onSubmit={handleSubmit}>
                  <div className="mt-5 flex flex-col space-y-14">
                    <div className="space-y-8">
                      <p className="font-semibold text-lg">
                        About {policy?.isApproved() ? 'Policy Holder' : 'Proposer'}
                      </p>
                      <div className="space-y-8 md:space-y-0 md:grid md:grid-cols-3 md:gap-4">
                        <Input
                          type="text"
                          id="firstName"
                          label="First Name"
                          placeholder="Eg: Jay"
                          className="rounded-[0px] placeholder-[#848484] focus:ring-primary-border"
                          value={`${values?.firstName ?? ''}`}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={touched?.firstName ? errors.firstName : undefined}
                          helperText={touched?.firstName ? errors.firstName : undefined}
                        />

                        <Input
                          type="text"
                          id="lastName"
                          label="Last Name"
                          placeholder="Eg: Walker"
                          className="rounded-[0px] placeholder-[#848484] focus:ring-primary-border"
                          value={`${values?.lastName ?? ''}`}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={touched?.lastName ? errors.lastName : undefined}
                          helperText={touched?.lastName ? errors.lastName : undefined}
                        />
                        <Input
                          type="text"
                          id="email"
                          label="Email"
                          placeholder="Eg: someone@domain.com"
                          className="rounded-[0px] placeholder-[#848484] focus:ring-primary-border"
                          value={values?.email ?? ''}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <div className="">
                          {policy?.applicant?.phoneNumber && (
                            <VerifyInput
                              label="Phone Number"
                              readOnly
                              verified={policy?.applicant?.phoneVerified}
                              value={getPhoneNumberFromShortCodeFormat(
                                policy?.applicant?.phoneNumber
                              )}
                              onClick={() =>
                                showCustomerPhoneVerificationModal(
                                  policy?.applicant?.phoneNumber ?? ''
                                )
                              }
                              error={touched?.phoneNumber ? errors.phoneNumber : undefined}
                              helperText={touched?.phoneNumber ? errors.phoneNumber : undefined}
                            />
                          )}
                        </div>

                        <VerifyInput
                          label="Other Phone"
                          name="otherPhone"
                          id={'otherPhone'}
                          readOnly={policy?.applicant?.otherPhoneVerified}
                          verified={policy?.applicant?.otherPhoneVerified}
                          value={values.otherPhone}
                          onChange={handleChange}
                          onClick={() => showCustomerPhoneVerificationModal(values?.otherPhone)}
                          error={touched?.otherPhone ? errors.otherPhone : undefined}
                          helperText={touched?.otherPhone ? errors.otherPhone : undefined}
                        />

                        <PlaceComponent
                          type="text"
                          name="address"
                          label="Address"
                          placeholder="Eg: No. 12 Kpong Street, Achimota"
                          classes="rounded-[0px] placeholder-[#848484] focus:ring-primary-border"
                          value={values?.address ?? ''}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={touched?.address ? errors.address : undefined}
                          helperText={touched?.address ? errors.address : undefined}
                          onAddressChange={(location: string) => {
                            setFieldValue('address', location);
                          }}
                        />

                        <InputSelectMenu
                          label="Occupation"
                          name="occupation"
                          listSelectedValue={values.occupation}
                          onChange={(val: string | null) => {
                            setFieldValue('occupation', val);
                          }}
                          onBlur={handleBlur}
                          list={OCCUPATIONS}
                          placeholder="Select protection type"
                          error={touched.occupation ? errors.occupation : undefined}
                          required
                          sort
                          search
                          emptyListMessage="Select occupation to proceed"
                          helperText={touched?.occupation ? errors.occupation : undefined}
                        />

                        <SelectDropdown
                          label="ID Type"
                          name="idType"
                          listSelectedValue={values.idType}
                          onChange={(val: ISelectMenuItemValue | null) => {
                            setFieldValue('idType', val);
                          }}
                          list={ID_TYPES_LIST}
                          placeholder="Select ID Type"
                          error={touched.idType ? errors.idType : undefined}
                          helperText={touched?.idType ? errors.idType : undefined}
                        />

                        <Input
                          value={values.idNumber}
                          name="idNumber"
                          label="ID Number"
                          placeholder={getIdPlaceholder(values.idType)}
                          type="text"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={touched.idNumber ? errors.idNumber : undefined}
                          helperText={touched?.idNumber ? errors.idNumber : undefined}
                        />

                        <Input
                          id="idExpiryDate"
                          value={values.idExpiryDate}
                          type="date"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          label="ID Expiry Date"
                          placeholder="20 June, 2023"
                          error={touched.idExpiryDate ? errors.idExpiryDate : undefined}
                          helperText={touched?.idExpiryDate ? errors.idExpiryDate : undefined}
                        />

                        <Input
                          id="dateOfBirth"
                          value={values.dateOfBirth}
                          type="date"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          label="Date of birth"
                          placeholder="20 June, 2002"
                          required
                          error={touched.dateOfBirth ? errors.dateOfBirth : undefined}
                          helperText={touched?.dateOfBirth ? errors.dateOfBirth : undefined}
                        />

                        {policy?.isRideSharing() ? (
                          <>
                            <SelectMenu
                              label="Daily Average Income"
                              name="monthlyIncome"
                              listSelectedValue={values.monthlyIncome}
                              onChange={(val: ISelectMenuItemValue | null) => {
                                setFieldValue('monthlyIncome', val);
                              }}
                              search={false}
                              searchPlaceHolder="Search by average income ..."
                              list={RIDE_SHARING_DAILY_AVERAGE_INCOME}
                              placeholder="Select average daily income"
                              error={touched.monthlyIncome ? errors.monthlyIncome : undefined}
                              helperText={touched?.monthlyIncome ? errors.monthlyIncome : undefined}
                            />

                            <Input
                              value={values.rideSharePeriod}
                              name="rideSharePeriod"
                              label="How long have you been doing the ride share business?"
                              placeholder="Eg. 5"
                              append={values.rideSharePeriod > 1 ? 'yrs' : 'yr'}
                              type="number"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />

                            <SelectDropdown
                              name="liability"
                              label={'Do you have any other liabilities?'}
                              list={[
                                {
                                  name: 'Yes',
                                  value: 'yes'
                                },
                                {
                                  name: 'No',
                                  value: 'no'
                                }
                              ]}
                              listSelectedValue={
                                values.liability !== undefined
                                  ? values.liability === 'yes'
                                    ? 'yes'
                                    : 'no'
                                  : ''
                              }
                              onChange={(val: string | null) => {
                                setFieldValue('liability', val);
                              }}
                              onBlur={handleBlur}
                              error={touched.liability ? errors.liability : undefined}
                              required
                              sort
                              helperText={touched?.liability ? errors.liability : undefined}
                            />

                            {values?.liability === 'yes' && (
                              <>
                                <Input
                                  name="liabilityDetails"
                                  value={values.liabilityDetails}
                                  label="What other liabilites do you have?"
                                  // description='Seperate the liabilities with a , '
                                  placeholder="Eg. Quick Loan, MTN Loan"
                                  type="text"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />

                                <Input
                                  name="liabilityDebt"
                                  value={values.liabilityDebt}
                                  label="How much are you owing?"
                                  placeholder="Eg: 5000"
                                  prepend={'GHc'}
                                  type="number"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />

                                <Input
                                  name="liabilityModeOfPayment"
                                  value={values.liabilityModeOfPayment}
                                  label="How are you paying for the extra liabities?"
                                  placeholder=""
                                  type="text"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                              </>
                            )}
                          </>
                        ) : (
                          <>
                            <Input
                              value={values.employerName}
                              name="employerName"
                              label="Employer's Name"
                              placeholder="Employer's Name"
                              type="text"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />

                            <Input
                              value={values.employerAddress}
                              name="employerAddress"
                              label="Employer's Address"
                              placeholder="Employer's Address"
                              type="text"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              // error={touched?.employerAddress ? errors.employerAddress : undefined}
                              // helperText={touched?.employerAddress ? errors.employerAddress : undefined}
                            />

                            <Input
                              value={values.employerPhoneNumber}
                              name="employerPhoneNumber"
                              label="Employer's Contact Number"
                              placeholder="Eg. 0245000000"
                              type="tel"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              // error={touched?.employerPhoneNumber ? errors.employerPhoneNumber : undefined}
                              // helperText={touched?.employerPhoneNumber ? errors.employerPhoneNumber : undefined}
                              // countryCode
                            />
                            <SelectMenu
                              label="Monthly Income Band"
                              name="monthlyIncome"
                              listSelectedValue={values.monthlyIncome}
                              onChange={(val: ISelectMenuItemValue | null) => {
                                setFieldValue('monthlyIncome', val);
                              }}
                              search={false}
                              searchPlaceHolder="Search by Income Band ..."
                              list={EMPLOYEE_INCOME_BAND}
                              placeholder="Select Monthly Income band"
                              error={touched.monthlyIncome ? errors.monthlyIncome : undefined}
                              helperText={touched?.monthlyIncome ? errors.monthlyIncome : undefined}
                            />
                          </>
                        )}
                      </div>
                    </div>
                    {/* End summary for about you */}
                    <div className="space-y-8 border-t border-t-gray-200">
                      <p className="font-semibold text-lg">About Your Insurance</p>
                      <div className="space-y-8 md:space-y-0 md:grid md:grid-cols-3 md:gap-4">
                        <SelectMenu
                          label="Protection Type"
                          name="protectionType"
                          listSelectedValue={values.protectionType}
                          onChange={(val: ISelectMenuItemValue | null) => {
                            setFieldValue('protectionType', val);
                          }}
                          onBlur={handleBlur}
                          search
                          searchPlaceHolder="Search by protection type ..."
                          list={PROTECTION_TYPES}
                          placeholder="Select protection type"
                          disabled
                          error={touched.protectionType ? errors.protectionType : undefined}
                          helperText={touched?.protectionType ? errors.protectionType : undefined}
                        />

                        <Input
                          type="text"
                          id="excess"
                          label="Excess"
                          className="rounded-[0px] placeholder-[#848484] focus:ring-primary-border px-3"
                          value={values?.excess ?? 0}
                          onChange={() => {}}
                          onBlur={() => {}}
                          disabled={true}
                        />

                        <Input
                          type="date"
                          name="startsOn"
                          label="Policy Start Date"
                          className="rounded-[0px] placeholder-[#848484] focus:ring-primary-border px-3"
                          value={values?.startsOn}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={touched.startsOn ? errors.startsOn : undefined}
                          helperText={touched?.startsOn ? errors.startsOn : undefined}
                        />

                        {!isThirdPartyProtection(values?.protectionType ?? '') && (
                          <Input
                            type="text"
                            id="vehicleInsuredValue"
                            label="Vehicle's Insured Value (Sum Insured)"
                            className="rounded-[0px] placeholder-[#848484] focus:ring-primary-border px-3"
                            value={values?.vehicleInsuredValue ?? ''}
                            onChange={() => {}}
                            onBlur={() => {}}
                            disabled
                          />
                        )}
                      </div>
                    </div>
                    {/* End summary for about your insurance */}
                    <div className="space-y-8 border-t border-t-gray-200">
                      <p className="font-semibold text-lg">About Your Vehicle</p>
                      <div className="space-y-8 md:space-y-0 md:grid md:grid-cols-3 md:gap-4">
                        <SelectMenu
                          label="Vehicle Type"
                          name="vehicleType"
                          listSelectedValue={values.vehicleType}
                          onChange={(val: ISelectMenuItemValue | null) => {
                            setFieldValue('vehicleType', val);
                          }}
                          onBlur={handleBlur}
                          search
                          searchPlaceHolder="Search by vehicle type ..."
                          list={VEHICLE_BODY_TYPES_LIST}
                          placeholder="Select vehicle type"
                          error={touched.vehicleType ? errors.vehicleType : undefined}
                          helperText={touched?.vehicleType ? errors.vehicleType : undefined}
                          required
                        />

                        <InputSelectMenu
                          label="Vehicle Make"
                          name="vehicleMake"
                          listSelectedValue={values.vehicleMake}
                          onChange={(val: ISelectMenuItemValue | null) => {
                            setFieldValue('vehicleMake', val);
                          }}
                          onBlur={handleBlur}
                          search
                          searchPlaceHolder="Search by vehicle make ..."
                          list={vehicleMakes}
                          placeholder="Select vehicle make"
                          error={touched.vehicleMake ? errors.vehicleMake : undefined}
                          helperText={touched?.vehicleMake ? errors.vehicleMake : undefined}
                          required
                        />

                        <Input
                          label="Vehicle Model"
                          name="vehicleModel"
                          value={values.vehicleModel}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder="Vehicle model"
                          error={touched.vehicleModel ? errors.vehicleModel : undefined}
                          helperText={touched?.vehicleModel ? errors.vehicleModel : undefined}
                          required
                        />

                        <InputSelectMenu
                          label="Vehicle Colour"
                          name="vehicleColor"
                          listSelectedValue={values.vehicleColor}
                          onChange={(val: ISelectMenuItemValue | null) => {
                            setFieldValue('vehicleColor', val);
                          }}
                          onBlur={handleBlur}
                          search
                          searchPlaceHolder="Search by vehicle colour ..."
                          list={VEHICLE_COLORS}
                          placeholder="Select vehicle colour"
                          error={touched.vehicleColor ? errors.vehicleColor : undefined}
                          helperText={touched?.vehicleColor ? errors.vehicleColor : undefined}
                          required
                        />

                        <SelectMenu
                          label="Vehicle Use"
                          name="vehicleUse"
                          listSelectedValue={values.vehicleUse}
                          onChange={(val: ISelectMenuItemValue | null) => {
                            setFieldValue('vehicleUse', val);
                          }}
                          onBlur={handleBlur}
                          search
                          searchPlaceHolder="Search by vehicle use ..."
                          list={VEHICLE_USAGES_LIST}
                          placeholder="Select vehicle use"
                          error={touched.vehicleUse ? errors.vehicleUse : undefined}
                          helperText={touched?.vehicleUse ? errors.vehicleUse : undefined}
                          required
                          disabled
                        />

                        <Input
                          type="number"
                          id="numOfPassenger"
                          label="Number of Passengers"
                          className="rounded-[0px] placeholder-[#848484] focus:ring-primary-border px-3"
                          value={values?.numOfPassenger ?? ''}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder="Vehicle max seat"
                          required
                          error={touched?.numOfPassenger ? errors.numOfPassenger : undefined}
                          helperText={touched?.numOfPassenger ? errors.numOfPassenger : undefined}
                        />

                        <Input
                          label={values.powerType === 'hp' ? 'Horse Power' : 'Cubic Capacity'}
                          name="cc"
                          value={values.cc}
                          append={
                            <MiniSelectMenu
                              name="powerType"
                              options={['cc', 'hp']}
                              value={values.powerType}
                              onChange={(e: any) => {
                                const val = e?.target?.value;
                                if (val) {
                                  setFieldValue('powerType', val);
                                  if (val == 'cc') {
                                    setFieldValue('cc', Math.round(values.cc * 15));
                                  } else {
                                    setFieldValue('cc', Math.round((values.cc / 15) * 100) / 100);
                                  }
                                }
                              }}
                            />
                          }
                          type="number"
                          min={50}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder="Cubic capacity"
                          error={touched.cc ? errors.cc : undefined}
                          required
                          helperText={touched.cc ? errors.cc : undefined}
                        />

                        <InputSelectMenu
                          label="Registration Year"
                          name="registrationYear"
                          listSelectedValue={values.registrationYear}
                          onChange={(val: string | null) => {
                            setFieldValue('registrationYear', val);
                            setRegistrationYearSuffix(predictRegYearSuffix(String(val)));
                          }}
                          onBlur={handleBlur}
                          list={MANUFACTURED_YEARS_LIST}
                          placeholder="Select registration year"
                          error={touched.registrationYear ? errors.registrationYear : undefined}
                          helperText={
                            touched.registrationYear ? errors.registrationYear : undefined
                          }
                          required
                          sort
                          emptyListMessage="Select registration year"
                        />

                        <InputSelectMenu
                          label="Manufactured Year"
                          name="manufacturedYear"
                          listSelectedValue={values.manufacturedYear}
                          onChange={(val: string | null) => {
                            setFieldValue('manufacturedYear', val);
                          }}
                          onBlur={handleBlur}
                          list={validManufacturingYears}
                          placeholder="Select manufactured year"
                          required
                          sort
                          emptyListMessage="Select manufactured year to proceed"
                          error={touched.manufacturedYear ? errors.manufacturedYear : undefined}
                          helperText={
                            touched.manufacturedYear ? errors.manufacturedYear : undefined
                          }
                        />

                        <SelectMenu
                          label="Region"
                          name="vehicleRegion"
                          listSelectedValue={sentenceCase(values?.vehicleRegion ?? '')}
                          onChange={(val: ISelectMenuItemValue | null) => {
                            setFieldValue('vehicleRegion', val);
                          }}
                          onBlur={handleBlur}
                          search
                          searchPlaceHolder="Search by region ..."
                          list={GHANA_REGIONS}
                          placeholder="Select region"
                          error={touched.vehicleRegion ? errors.vehicleRegion : undefined}
                          helperText={touched?.vehicleRegion ? errors.vehicleRegion : undefined}
                          required
                        />
                        <Input
                          description={''}
                          type="text"
                          id="registrationNumber"
                          label="Registration Number"
                          placeholder="Eg. GT 2440"
                          className="rounded-[0px] placeholder-[#848484] focus:ring-primary-border px-3"
                          wrapperClasses="pr-0"
                          value={values?.registrationNumber.toUpperCase()}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={
                            touched?.registrationNumber ? errors.registrationNumber : undefined
                          }
                          helperText={
                            touched?.registrationNumber ? errors.registrationNumber : undefined
                          }
                          append={
                            values.registrationYear &&
                            registrationYearSuffix && (
                              <span className="px-2 bg-gray-300 py-2">
                                {registrationYearSuffix}
                              </span>
                            )
                          }
                          required
                        />

                        {policy?.vehicle && (
                          <VerifyInput
                            name="chassisNumber"
                            label="Chassis Number / VIN"
                            verified={policy?.vehicle?.verified}
                            value={values?.chassisNumber ?? ''}
                            onClick={() => manuallyVerifyVIN(values)}
                            onChange={handleChange}
                            readOnly={vehicleVerified && !!values.chassisNumber}
                            error={errors.chassisNumber}
                            helperText={errors.chassisNumber}
                            required
                          />
                        )}
                      </div>

                      <div className="space-y-8 md:space-y-0 md:grid md:grid-cols-3 md:gap-4">
                        <InputRadio
                          id="vehicleAltered"
                          name="vehicleAltered"
                          options={[
                            { name: 'Yes', value: 'yes' },
                            { name: 'No', value: 'no' }
                          ]}
                          label="Has this vehicle been altered or modified in any way?"
                          selected={
                            values.vehicleAltered !== undefined
                              ? values.vehicleAltered === 'yes'
                                ? 'yes'
                                : 'no'
                              : ''
                          }
                          onSelect={handleChange}
                          error={touched?.vehicleAltered ? errors.vehicleAltered : undefined}
                          helperText={touched?.vehicleAltered ? errors.vehicleAltered : undefined}
                        />
                        {values?.vehicleAltered === 'yes' && (
                          <TextArea
                            id="alterationDetails"
                            label="Vehicle Alteration Details"
                            placeholder=""
                            className="rounded-[0px] placeholder-[#848484] focus:ring-primary-border px-3"
                            value={
                              values?.vehicleAltered
                                ? values.alterationDetails
                                : 'Vehicle not altered'
                            }
                            onValueChanged={handleChange}
                            onFocusOut={handleBlur}
                          />
                        )}
                      </div>

                      <div className="space-y-8 md:space-y-0 md:grid md:grid-cols-3 md:gap-4">
                        <InputRadio
                          name="goodRepairState"
                          label={'Is your vehicle in a good state of repair?'}
                          options={[
                            { name: 'Yes', value: 'yes' },
                            { name: 'No', value: 'no' }
                          ]}
                          selected={
                            values.goodRepairState !== undefined
                              ? values.goodRepairState === 'yes'
                                ? 'yes'
                                : 'no'
                              : ''
                          }
                          onSelect={handleChange}
                          error={touched?.goodRepairState ? errors.goodRepairState : undefined}
                          helperText={touched?.goodRepairState ? errors.goodRepairState : undefined}
                        />

                        {values.goodRepairState === 'no' && (
                          <Input
                            type="text"
                            id="repairState"
                            label="If no, provide details of the vehicle's state of repair."
                            placeholder=""
                            className="rounded-[0px] placeholder-[#848484] focus:ring-primary-border px-3"
                            value={values.repairState ?? ''}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={touched?.repairState ? errors.repairState : undefined}
                            helperText={touched?.repairState ? errors.repairState : undefined}
                            required
                          />
                        )}
                      </div>

                      <div className="space-y-8 md:space-y-0 md:grid md:grid-cols-3 md:gap-4">
                        <SelectDropdown
                          label="Have you installed any extra accessories that you would like to insure separately?"
                          name="hasInstalledAccessories"
                          listSelectedValue={values.hasInstalledAccessories}
                          onChange={(val: string | null) => {
                            setFieldValue('hasInstalledAccessories', val);
                          }}
                          onBlur={handleBlur}
                          list={[...booleanSelectDropdownList]}
                          placeholder="Click to select option"
                          error={
                            touched.hasInstalledAccessories
                              ? errors.hasInstalledAccessories
                              : undefined
                          }
                          helperText={
                            touched?.hasInstalledAccessories
                              ? errors.hasInstalledAccessories
                              : undefined
                          }
                          required
                        />
                        {values.hasInstalledAccessories === 'yes' && (
                          <div className="flex flex-col lg:flex-row lg:gap-4">
                            {/* <p>Here {values.installedAccessoriesDetails }</p> */}
                            <div className="w-full my-3 lg:w-1/2">
                              <Input
                                type="text"
                                id="installedAccessoriesDetails"
                                label="If yes, provide details of accessories"
                                placeholder=""
                                className="rounded-[0px] placeholder-[#848484] focus:ring-primary-border px-3"
                                value={values.installedAccessoriesDetails ?? ''}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={
                                  touched?.installedAccessoriesDetails
                                    ? errors.installedAccessoriesDetails
                                    : undefined
                                }
                                helperText={
                                  touched?.installedAccessoriesDetails
                                    ? errors.installedAccessoriesDetails
                                    : undefined
                                }
                              />
                            </div>
                            <div className="w-full my-3 lg:w-1/2">
                              <Input
                                type="number"
                                id="installedAccessoriesValue"
                                label="Insured value of accessories"
                                placeholder=""
                                className="rounded-[0px] placeholder-[#848484] focus:ring-primary-border px-3"
                                value={values.installedAccessoriesValue ?? ''}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={
                                  touched?.installedAccessoriesValue
                                    ? errors.installedAccessoriesValue
                                    : undefined
                                }
                                helperText={
                                  touched?.installedAccessoriesValue
                                    ? errors.installedAccessoriesValue
                                    : undefined
                                }
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    {/* End summary for about your vehicle */}
                    {/* End summary for hire purchase provider */}
                    {/* About vehicle owner */}
                    <div className="space-y-8 border-t border-t-gray-200">
                      <p className="font-semibold text-lg">About The Vehicle Owner</p>
                      <div className="space-y-8 md:space-y-0 md:grid md:grid-cols-2 md:gap-4">
                        <SelectDropdown
                          name="composerIsOwner"
                          label={'Are you the registered owner of the vehicle in the DVLA records?'}
                          list={[
                            {
                              name: 'Yes',
                              value: 'yes'
                            },
                            {
                              name: 'No, Another person',
                              value: 'no'
                            }
                          ]}
                          listSelectedValue={
                            values.composerIsOwner !== undefined
                              ? values.composerIsOwner === 'yes'
                                ? 'yes'
                                : 'no'
                              : ''
                          }
                          onChange={(val: string | null) => {
                            setFieldValue('composerIsOwner', val);
                          }}
                          onBlur={handleBlur}
                          error={touched.composerIsOwner ? errors.composerIsOwner : undefined}
                          required
                          sort
                          helperText={touched?.composerIsOwner ? errors.composerIsOwner : undefined}
                        />

                        {values.composerIsOwner === 'no' && (
                          <SelectMenu
                            label="What is the nature of your interest in the vehicle?"
                            name="natureOfInterestInVehicle"
                            listSelectedValue={values.natureOfInterestInVehicle}
                            onChange={(val: any) => {
                              setFieldValue('natureOfInterestInVehicle', val);
                            }}
                            onBlur={handleBlur}
                            list={[
                              NEW_OWNER_YET_TO_CHANGE_AT_DVLA,
                              WORK_AND_PAY_DRIVER,
                              HIRE_PURCHASER,
                              EMPLOYEE_DRIVER,
                              OTHER_AUTHORISED_KEEPER_OR_USER
                            ]}
                            placeholder=""
                            // error={
                            //   touched?.natureOfInterestInVehicle
                            //     ? errors.natureOfInterestInVehicle
                            //     : undefined
                            // }
                            // helperText={
                            //   touched?.natureOfInterestInVehicle
                            //     ? errors.natureOfInterestInVehicle
                            //     : undefined
                            // }
                          />
                        )}
                      </div>

                      {/* If registered owner different from composer */}
                      {values.composerIsOwner === 'no' &&
                        values.natureOfInterestInVehicle &&
                        values.natureOfInterestInVehicle !== NEW_OWNER_YET_TO_CHANGE_AT_DVLA && (
                          <div className={`space-y-8 md:space-y-0 md:grid ${values.hasInterestedParties === 'yes' ? 'md:grid-cols-4' : 'md:grid-cols-2'} md:gap-4`}>
                            <Input
                              type="text"
                              id="ownerName"
                              label="Vehicle Owner's Name"
                              placeholder="Eg. Kwaku Manu"
                              className="rounded-[0px] placeholder-[#848484] focus:ring-primary-border px-3"
                              value={values?.ownerName}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={touched?.ownerName ? errors.ownerName : undefined}
                              helperText={touched?.ownerName ? errors.ownerName : undefined}
                            />

                            {values.composerIsOwner === 'no' &&
                              values.natureOfInterestInVehicle &&
                              values.natureOfInterestInVehicle !==
                                NEW_OWNER_YET_TO_CHANGE_AT_DVLA && (
                                <div className="space-y-8 border-t-gray-200">
                                  <div className="space-y-8 md:space-y-0 md:grid md:grid-cols-1 md:gap-4">
                                    <InputRadio
                                      name="hasInterestedParties"
                                      label={'Is this vehicle on hire purchase?'}
                                      options={[
                                        { name: 'Yes', value: 'yes' },
                                        { name: 'No', value: 'no' }
                                      ]}
                                      selected={
                                        values.hasInterestedParties !== undefined
                                          ? values.hasInterestedParties === 'yes'
                                            ? 'yes'
                                            : 'no'
                                          : ''
                                      }
                                      onSelect={handleChange}
                                      error={
                                        touched?.hasInterestedParties
                                          ? errors.hasInterestedParties
                                          : undefined
                                      }
                                      helperText={
                                        touched?.hasInterestedParties
                                          ? errors.hasInterestedParties
                                          : undefined
                                      }
                                    />
                                  </div>
                                </div>
                              )}

                            {(values.hasInterestedParties === 'yes' ) && (
                              <Input
                                type="text"
                                id="interestedParties"
                                label="If yes, provide name of interested party."
                                placeholder=""
                                className="rounded-[0px] placeholder-[#848484] focus:ring-primary-border px-3"
                                value={values.interestedParties ?? ''}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={
                                  touched?.interestedParties ? errors.interestedParties : undefined
                                }
                                helperText={
                                  touched?.interestedParties ? errors.interestedParties : undefined
                                }
                              />
                            )}
                          
                           
                          </div> 
                        )}
                         
                         {values.composerIsOwner === 'no' &&
                        values.natureOfInterestInVehicle &&
                        values.natureOfInterestInVehicle !== NEW_OWNER_YET_TO_CHANGE_AT_DVLA && (
                           <div className='grid md:grid-cols-2 gap-4'>
                            <PlaceComponent
                              type="text"
                              name="ownerAddress"
                              label="Owner's Address"
                              placeholder="Eg. Old barrier, Kasoa"
                              classes="rounded-[0px] placeholder-[#848484] focus:ring-primary-border px-3"
                              value={values.ownerAddress}
                              onAddressChange={(location: string) => {
                                setFieldValue('ownerAddress', location);
                              }}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={touched?.ownerAddress ? errors.ownerAddress : undefined}
                              helperText={touched?.ownerAddress ? errors.ownerAddress : undefined}
                            />

                            <Input
                              type="tel"
                              id="ownerPhone"
                              label="Owner's Phone"
                              placeholder="Eg. Kwaku Manu"
                              className="rounded-[0px] placeholder-[#848484] focus:ring-primary-border px-3"
                              value={values.ownerPhone}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={touched?.ownerPhone ? errors.ownerPhone : undefined}
                              helperText={touched?.ownerPhone ? errors.ownerPhone : undefined}
                            />
                            </div>)}
                    </div>
                    {/* End vehicle owner */}
                    <div className="space-y-8 border-t border-t-gray-200">
                      <p className="font-semibold text-lg">About The Main Driver</p>
                      <div className="space-y-8 md:space-y-0 md:grid md:grid-cols-3 md:gap-4">
                        <Input
                          type="text"
                          id="vehicleMainDriver"
                          label="Vehicle Main Driver"
                          placeholder="Eg. Francis Atuahene"
                          className="rounded-[0px] placeholder-[#848484] focus:ring-primary-border px-3"
                          value={values?.vehicleMainDriver ?? ''}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />

                        <InputRadio
                          name="hasDisease"
                          selected={
                            values.hasDisease !== undefined
                              ? values.hasDisease === 'yes'
                                ? 'yes'
                                : 'no'
                              : ''
                          }
                          label="Does driver have any disease or complications?"
                          options={[
                            { name: 'Yes', value: 'yes' },
                            { name: 'No', value: 'no' }
                          ]}
                          onSelect={handleChange}
                        />

                        {values?.hasDisease === 'yes' && (
                          <div className="flex flex-col">
                            <TextArea
                              name="diseaseOrComplications"
                              id="diseaseOrComplications"
                              label="Disease or complications of main driver."
                              rows={1}
                              className="border border-gray-200 bg-transparent rounded-[0px] placeholder-[#848484] focus:ring-0 px-4 py-3 focus:outline-none resize-none"
                              value={values.diseaseOrComplications ?? ''}
                              onValueChanged={handleChange}
                              onFocusOut={handleChange}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                    {/* End summary for about the main driver */}
                    <div className="space-y-8 border-t border-t-gray-200">
                      <p className="font-semibold text-lg">Information</p>
                      <div className="space-y-8 md:space-y-0 md:grid md:grid-cols-3 md:gap-4">
                        <InputRadio
                          id="previouslyDeclined"
                          name="previouslyDeclined"
                          options={[
                            { name: 'Yes', value: 'yes' },
                            { name: 'No', value: 'no' }
                          ]}
                          label="Ever had a proposal for insurance declined by another insurer?"
                          selected={
                            values.previouslyDeclined !== undefined
                              ? values.previouslyDeclined === 'yes'
                                ? 'yes'
                                : 'no'
                              : ''
                          }
                          onSelect={handleChange}
                          disabled={true}
                          error={
                            touched?.previouslyDeclined ? errors.previouslyDeclined : undefined
                          }
                          helperText={
                            touched?.previouslyDeclined ? errors.previouslyDeclined : undefined
                          }
                        />
                        {values?.previouslyDeclined === 'yes' && (
                          <TextArea
                            name="declinedByOtherInsurer"
                            id="declinedByOtherInsurer"
                            label="Reasons for previously declined insurance."
                            rows={2}
                            className="border border-gray-200 bg-transparent rounded-[0px] placeholder-[#848484] focus:ring-0 px-4 py-3 focus:outline-none resize-none"
                            value={values?.declinedByOtherInsurer ?? ''}
                            // onChange={() => { }}
                            onValueChanged={handleChange}
                            onFocusOut={handleChange}
                            // error={
                            //   touched?.declinedByOtherInsurer ? errors.declinedByOtherInsurer : undefined
                            // }
                            // helperText={
                            //   touched?.declinedByOtherInsurer ? errors.declinedByOtherInsurer : undefined
                            // }
                          />
                        )}
                      </div>

                      <div className="space-y-8 md:space-y-0 md:grid md:grid-cols-3 md:gap-4">
                        <InputRadio
                          id="previouslyIssuedClaim"
                          name="previouslyIssuedClaim"
                          options={[
                            { name: 'Yes', value: 'yes' },
                            { name: 'No', value: 'no' }
                          ]}
                          label="Have you ever made a claim on another insurance policy?"
                          selected={
                            values.previouslyIssuedClaim !== undefined
                              ? values.previouslyIssuedClaim === 'yes'
                                ? 'yes'
                                : 'no'
                              : ''
                          }
                          onSelect={handleChange}
                          // disabled={true}
                          error={
                            touched?.previouslyIssuedClaim
                              ? errors.previouslyIssuedClaim
                              : undefined
                          }
                          helperText={
                            touched?.previouslyIssuedClaim
                              ? errors.previouslyIssuedClaim
                              : undefined
                          }
                        />

                        {values?.previouslyIssuedClaim === 'yes' && (
                          <TextArea
                            name="issuedClaimDetails"
                            id="issuedClaimDetails"
                            label="Details for previously issued claim"
                            rows={2}
                            className="border border-gray-200 bg-transparent rounded-[0px] placeholder-[#848484] focus:ring-0 px-4 py-3 focus:outline-none resize-none"
                            value={values?.issuedClaimDetails ?? ''}
                            onValueChanged={handleChange}
                            onFocusOut={handleChange}
                          />
                        )}
                      </div>
                    </div>
                    {/* End summary for extra information */}
                    {/* <div className="space-y-8 border-t border-t-gray-200">
                  <p className="font-semibold text-lg">Renewal Notice</p>
                  <div className="space-y-8 md:space-y-0 md:grid md:grid-cols-3 md:gap-4">
                    <DocumentPreview
                      document={policyData.renewalNotice?.name}
                      onView={(_doc, _type) => {
                        setPreviewDoc({
                          doc: `${process.env.NEXT_PUBLIC_INSURANCE_DOCS_STORAGE_LINK}${_doc}`,
                          type: _type,
                        });
                      }}
                      onDelete={() => { }}
                    />
                  </div>
                </div>  */}
                    {/* Insurers Section */}
                    <div
                      className={clsx('space-y-8 border-t border-t-gray-200', {
                        ['hidden']: user.isInsurer() && values.insurer
                      })}>
                      <p className="font-semibold text-lg">Insurer</p>
                      <div className="space-y-8 md:space-y-0 md:grid md:grid-cols-3 md:gap-4">
                        <SelectDropdown
                          label="Preferred Insurer"
                          name="insurer"
                          listSelectedValue={values.insurer}
                          onChange={(val: ISelectDropdownItem | null) => {
                            setFieldValue('insurer', val);
                          }}
                          onBlur={handleBlur}
                          search
                          searchPlaceHolder="Search by insurer ..."
                          list={insurers
                            ?.filter((insurer) => insurer.status === 'ACTIVE')
                            .map(
                              (insurer: Insurer) =>
                                ({
                                  name: insurer.name,
                                  value: insurer.id
                                } as ISelectDropdownItem)
                            )}
                          placeholder="Select insurer"
                          error={touched.insurer ? errors.insurer : undefined}
                          helperText={touched?.insurer ? errors.insurer : undefined}
                          required
                        />
                      </div>
                    </div>
                    {/* End Insurers section */}
                    {/* Community section */}
                    <div className="space-y-8 border-t border-t-gray-200">
                      <p className="font-semibold text-lg">Community</p>
                      <div className="col-span-2">
                        <div className="p-y-8 md:space-y-0 md:grid md:grid-cols-2 md:gap-4">
                          <SelectDropdown
                            label="Select the community the customer belongs to."
                            name="community"
                            listSelectedValue={values.community}
                            onChange={(val: ISelectDropdownItem | null) => {
                              setFieldValue('community', val);
                            }}
                            onBlur={handleBlur}
                            search
                            searchPlaceHolder="Search by community ..."
                            list={communities?.map(
                              (community: Community) =>
                                ({
                                  name: community.name,
                                  value: community.id
                                } as ISelectDropdownItem)
                            )}
                            placeholder="Select community"
                            error={touched.community ? errors.community : undefined}
                            helperText={touched?.community ? errors.community : undefined}
                          />
                          <div className="flex items-end">
                            <Button
                              label="Add Community"
                              className="bg-primary-main w-[160px] h-fit"
                              onClick={addCommunity}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* End Community section */}
                    {/* Park section */}
                    {policy?.isYango() && (
                      <div className="space-y-8 border-t border-t-gray-200 ">
                        <p className="font-semibold text-lg">Park</p>
                        <div className="space-y-8 md:space-y-0 md:grid md:grid-cols-3 md:gap-5">
                          <div className="  ">
                            <SelectDropdown
                              label="Select the park the customer belongs to."
                              name="parkId"
                              listSelectedValue={values.parkId}
                              onChange={(val: ISelectDropdownItem | null) => {
                                setFieldValue('parkId', val);
                              }}
                              onBlur={handleBlur}
                              search
                              searchPlaceHolder="Search by park ..."
                              list={parks?.map(
                                (park: any) =>
                                  ({
                                    name: park.parkName,
                                    value: park.parkId
                                  } as ISelectDropdownItem)
                              )}
                              placeholder="Select park"
                              error={touched.parkId ? errors.parkId : undefined}
                              helperText={touched?.parkId ? errors.parkId : undefined}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                    {/* End Park section */}
                    {/* End Community section */}
                    {/* Info About Referee*/}
                    {isCommercial(values.vehicleUse) &&
                      !policy?.isFullPayment() &&
                      !policy.isRideSharing() && (
                        <div className="space-y-8 border-t-gray-200">
                          <p className="font-semibold text-lg">Referee Details</p>
                          <div className="space-y-8 md:space-y-0 md:grid md:grid-cols-3 md:gap-4">
                            {!policy?.referee && (
                              <>
                                <Input
                                  type="text"
                                  name="refereeName"
                                  label="Referee’s Full Name"
                                  placeholder="Eg. Francis Adjei"
                                  className="rounded-[0px] placeholder-[#848484] focus:ring-primary-border px-3"
                                  value={values?.refereeName}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  error={touched.refereeName ? errors.refereeName : undefined}
                                  helperText={touched?.refereeName ? errors.refereeName : undefined}
                                  required
                                />
                                <SelectMenu
                                  name="refereeRelationship"
                                  label="Relationship to the Driver"
                                  listSelectedValue={values.refereeRelationship}
                                  onChange={(val: string | null) => {
                                    setFieldValue('refereeRelationship', val);
                                  }}
                                  onBlur={handleBlur}
                                  list={REFEREE_RELATIONSHIP_OPTIONS}
                                  placeholder="Select relationship with referee"
                                  error={
                                    touched.refereeRelationship
                                      ? errors.refereeRelationship
                                      : undefined
                                  }
                                  emptyListMessage="Select relationship to proceed"
                                  helperText={
                                    touched?.refereeRelationship
                                      ? errors.refereeRelationship
                                      : undefined
                                  }
                                  required
                                />
                                <Input
                                  type="number"
                                  name="refereeYearsKnown"
                                  label="Number of years known"
                                  placeholder="Eg. 5"
                                  className="rounded-[0px] placeholder-[#848484] focus:ring-primary-border px-3"
                                  value={values?.refereeYearsKnown}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  error={
                                    touched.refereeYearsKnown ? errors.refereeYearsKnown : undefined
                                  }
                                  helperText={
                                    touched?.refereeYearsKnown
                                      ? errors.refereeYearsKnown
                                      : undefined
                                  }
                                  required
                                />
                                <Input
                                  type="tel"
                                  name="refereePhone"
                                  label="Referee’s Phone Number"
                                  placeholder="Eg. 0244363625"
                                  className="rounded-[0px] placeholder-[#848484] focus:ring-primary-border "
                                  value={values?.refereePhone}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  error={touched.refereePhone ? errors.refereePhone : undefined}
                                  helperText={
                                    touched?.refereePhone ? errors.refereePhone : undefined
                                  }
                                  required
                                />
                              </>
                            )}
                            {policy?.referee && policy?.referee?.verified === false && (
                              <>
                                <Input
                                  type="text"
                                  name="refereeName"
                                  label="Referee’s Full Name"
                                  placeholder="Eg. Francis Adjei"
                                  className="rounded-[0px] placeholder-[#848484] focus:ring-primary-border px-3"
                                  value={values?.refereeName}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  error={touched.refereeName ? errors.refereeName : undefined}
                                  helperText={touched?.refereeName ? errors.refereeName : undefined}
                                />
                                <SelectMenu
                                  name="refereeRelationship"
                                  label="Relationship to the Driver"
                                  listSelectedValue={values.refereeRelationship}
                                  onChange={(val: string | null) => {
                                    setFieldValue('refereeRelationship', val);
                                  }}
                                  onBlur={handleBlur}
                                  list={REFEREE_RELATIONSHIP_OPTIONS}
                                  placeholder="Select relationship with referee"
                                  error={
                                    touched.refereeRelationship
                                      ? errors.refereeRelationship
                                      : undefined
                                  }
                                  emptyListMessage="Select relationship to proceed"
                                  helperText={
                                    touched?.refereeRelationship
                                      ? errors.refereeRelationship
                                      : undefined
                                  }
                                  required
                                />
                                <Input
                                  type="number"
                                  name="refereeYearsKnown"
                                  label="Number of years known"
                                  placeholder="Eg. 5"
                                  className="rounded-[0px] placeholder-[#848484] focus:ring-primary-border px-3"
                                  value={values?.refereeYearsKnown}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  error={
                                    touched.refereeYearsKnown ? errors.refereeYearsKnown : undefined
                                  }
                                  helperText={
                                    touched?.refereeYearsKnown
                                      ? errors.refereeYearsKnown
                                      : undefined
                                  }
                                  required
                                />
                                <Input
                                  type="tel"
                                  name="refereePhone"
                                  label="Referee’s Phone Number"
                                  placeholder="Eg. 0244363625"
                                  className="rounded-[0px] placeholder-[#848484] focus:ring-primary-border "
                                  value={getPhoneNumberFromShortCodeFormat(values?.refereePhone)}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  error={touched.refereePhone ? errors.refereePhone : undefined}
                                  helperText={
                                    touched?.refereePhone ? errors.refereePhone : undefined
                                  }
                                  required
                                />
                              </>
                            )}

                            {policy?.referee && policy?.referee?.verified && (
                              <>
                                <Input
                                  type="text"
                                  name="refereeName"
                                  label="Referee’s Full Name"
                                  placeholder=""
                                  className="rounded-[0px] placeholder-[#848484] focus:ring-primary-border px-3"
                                  value={values?.refereeName}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  disabled
                                />
                                <Input
                                  type="text"
                                  name="refereeRelationship"
                                  label="Relationship to the Driver"
                                  placeholder=""
                                  className="rounded-[0px] placeholder-[#848484] focus:ring-primary-border px-3"
                                  value={values?.refereeRelationship}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  disabled
                                />
                                <Input
                                  type="number"
                                  name="refereeYearsKnown"
                                  label="Number of years known"
                                  placeholder=""
                                  className="rounded-[0px] placeholder-[#848484] focus:ring-primary-border px-3"
                                  value={values?.refereeYearsKnown}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  disabled
                                />
                                <Input
                                  type="tel"
                                  name="refereePhone"
                                  label="Referee’s Phone Number"
                                  placeholder=""
                                  className="rounded-[0px] placeholder-[#848484] focus:ring-primary-border "
                                  value={values?.refereePhone}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  disabled
                                />
                              </>
                            )}
                          </div>
                          {policy?.referee && policy?.referee?.verified === false && (
                            <div className="flex flex-col gap-5">
                              <div className="flex">
                                <div className="">
                                  <Input
                                    type="text"
                                    name="code"
                                    label="Referee’s Verification code"
                                    placeholder=""
                                    className="rounded-[0px] placeholder-[#848484] focus:ring-primary-border "
                                    value={values.code}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    prepend={'B-'}
                                  />
                                </div>

                                <div className="flex items-end ">
                                  <Button
                                    label="Verify OTP"
                                    title=" "
                                    bgColor="primary"
                                    className="rounded"
                                    onClick={() => verifyRefereeOTP(values)}
                                  />
                                </div>
                              </div>
                              <div className="">
                                <span
                                  className="text-blue-700 cursor-pointer hover:underline"
                                  onClick={() => sendRefOTP(values)}>
                                  Resend verification code
                                </span>
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    {/* End summary for referee details */}
                    {/* End summary for renewal notice */}
                    <div className="space-y-8 border-t border-t-gray-200">
                      <p className="font-semibold text-lg">Payment &amp; Commencement</p>
                      <div className="space-y-8 md:space-y-0 md:grid md:grid-cols-3 md:gap-4">
                        <div>
                          <h3 className={labelClass}>Policy Start Date</h3>
                          <div>
                            {policy?.startsOn
                              ? moment(policy?.startsOn).format('Do MMM, YYYY HH:mm a')
                              : 'N/A'}
                          </div>
                        </div>

                        <div>
                          <h3 className={labelClass}>Outright Premium</h3>
                          <div>
                            &#8373;{' '}
                            {numberWithCommas(
                              policy?.quote?.quotePlan?.outRightPremium?.toFixed(2) ?? 0
                            )}
                          </div>
                        </div>
                        {!user.isInsurer() && (
                          <>
                            <div>
                              <h3 className={labelClass}>Initial Payment</h3>
                              <div>
                                &#8373;{' '}
                                {numberWithCommas(
                                  policy?.quote?.quotePlan?.initialDeposit?.toFixed(2) ?? 0
                                )}
                              </div>
                            </div>

                            <div>
                              <h3 className={labelClass}>
                                {getInstallmentTypeFromKey(policy?.quote?.installmentType ?? '')}{' '}
                                Installment
                              </h3>
                              <div>
                                &#8373;{' '}
                                {numberWithCommas(
                                  policy?.quote?.quotePlan?.monthlyInstallment?.toFixed(2) ?? 0
                                )}
                              </div>
                            </div>

                            <div>
                              <h3 className={labelClass}>Installment Period</h3>
                              {/* <div>{policy?.getPaymentType()}</div> */}
                              <div>
                                {getInstallmentPeriodFromKey(
                                  policy?.quote?.quotePlan?.installmentPeriod ?? ''
                                )}{' '}
                                Installment
                              </div>{' '}
                            </div>
                          </>
                        )}
                      </div>
                    </div>{' '}
                    {/* End summary for payment and announcement */}
                  </div>

                  {/* Owner's Photos */}
                  <div className="space-y-8 border-t border-t-gray-200 mt-10">
                    <p className="font-semibold text-lg">Applicant&apos;s Documents</p>
                    <div className="space-y-8 md:space-y-0 md:grid md:grid-cols-2 md:gap-4 lg:grid-cols-4">
                      <FileUpload
                        onFileLoad={(images: any) => {
                          if (images && images[0]) {
                            setPolicyOwnerDocumentFiles({
                              ...policyOwnerDocumentFiles,
                              ownerPhoto: images[0]
                            });
                          }
                        }}
                        label="Vehicle Owner's Photograph"
                        maxHeight={350}
                        maxWidth={300}
                        imageContainerWidth="100%"
                        cropImage={true}
                        onFileDeleted={() => {
                          setPolicyOwnerDocumentFiles({
                            ...policyOwnerDocumentFiles,
                            ownerPhoto: null
                          });
                        }}
                        showFileMax={false}
                        savedImage={
                          policy?.account?.documents?.find(
                            (document: IPolicyDocument) =>
                              document?.docType === POLICY_DOCUMENT_TYPES.PASSPORT_PHOTO
                          )?.docURL
                        }
                      />

                      <FileUpload
                        onFileLoad={(images: any) => {
                          if (images && images[0]) {
                            setPolicyOwnerDocumentFiles({
                              ...policyOwnerDocumentFiles,
                              ownerId: images[0]
                            });
                          }
                        }}
                        label="Vehicle Owner's ID"
                        maxHeight={200}
                        maxWidth={350}
                        imageContainerWidth="100%"
                        cropImage={true}
                        onFileDeleted={() => {
                          setPolicyOwnerDocumentFiles({
                            ...policyOwnerDocumentFiles,
                            ownerId: null
                          });
                        }}
                        showFileMax={false}
                        savedImage={
                          policy?.account?.documents?.find(
                            (document: IPolicyDocument) =>
                              document?.docType === POLICY_DOCUMENT_TYPES.ID_CARD
                          )?.docURL
                        }
                      />
                      <FileUpload
                        onFileLoad={(images: any) => {
                          if (images && images[0]) {
                            setPolicyOwnerDocumentFiles({
                              ...policyOwnerDocumentFiles,
                              driverLicenseFront: images[0]
                            });
                          }
                        }}
                        label="Driver's License - Front"
                        maxHeight={177}
                        maxWidth={350}
                        imageContainerWidth="100%"
                        cropImage={false}
                        onFileDeleted={() => {
                          setPolicyOwnerDocumentFiles({
                            ...policyOwnerDocumentFiles,
                            driverLicenseFront: null
                          });
                        }}
                        showFileMax={false}
                        savedImage={
                          policy?.account?.documents?.find(
                            (document: IPolicyDocument) =>
                              document?.docType === POLICY_DOCUMENT_TYPES.DRIVER_LICENSE_FRONT
                          )?.docURL
                        }
                      />

                      <FileUpload
                        onFileLoad={(images: any) => {
                          if (images && images[0]) {
                            setPolicyOwnerDocumentFiles({
                              ...policyOwnerDocumentFiles,
                              driverLicenseBack: images[0]
                            });
                          }
                        }}
                        label="Driver's License - Back"
                        maxHeight={177}
                        maxWidth={350}
                        imageContainerWidth="100%"
                        cropImage={false}
                        onFileDeleted={() => {
                          setPolicyOwnerDocumentFiles({
                            ...policyOwnerDocumentFiles,
                            driverLicenseBack: null
                          });
                        }}
                        showFileMax={false}
                        savedImage={
                          policy?.account?.documents?.find(
                            (document: IPolicyDocument) =>
                              document?.docType === POLICY_DOCUMENT_TYPES.DRIVER_LICENSE_BACK
                          )?.docURL
                        }
                      />
                    </div>
                  </div>
                  {/* End User Photos */}

                  {/* Policy Documents */}
                  <div className="space-y-8 border-t border-t-gray-200 mt-10">
                    <p className="font-semibold text-lg">Policy Documents</p>
                    <div className="space-y-8 md:space-y-0 md:grid md:grid-cols-2 md:gap-4 lg:grid-cols-4">
                      <FileUpload
                        onFileLoad={(images: any) => {
                          if (images && images[0]) {
                            setPolicyDocumentFiles({
                              ...policyDocumentFiles,
                              sticker: images[0]
                            });
                          }
                        }}
                        label="Insurance Sticker"
                        maxHeight={177}
                        maxWidth={350}
                        imageContainerWidth="100%"
                        cropImage={false}
                        defaultImage={policy?.getInsuranceSticker()?.docURL}
                        onFileDeleted={() => {
                          setPolicyDocumentFiles({
                            ...policyDocumentFiles,
                            sticker: null
                          });
                        }}
                        showFileMax={false}
                        savedImage={
                          policy?.documents?.find(
                            (document: IPolicyDocument) =>
                              document?.docType === POLICY_DOCUMENT_TYPES.INSURANCE_STICKER
                          )?.docURL
                        }
                      />

                      <FileUpload
                        onFileLoad={(images: any) => {
                          if (images && images[0]) {
                            setPolicyDocumentFiles({
                              ...policyDocumentFiles,
                              insuranceCertificate: images[0]
                            });
                          }
                        }}
                        label="Insurance Certificate"
                        maxHeight={177}
                        maxWidth={350}
                        imageContainerWidth="100%"
                        cropImage={false}
                        defaultImage={policy?.getInsuranceCertificate()?.docURL}
                        onFileDeleted={() => {
                          setPolicyDocumentFiles({
                            ...policyDocumentFiles,
                            insuranceCertificate: null
                          });
                        }}
                        showFileMax={false}
                        savedImage={
                          policy?.documents?.find(
                            (document: IPolicyDocument) =>
                              document?.docType === POLICY_DOCUMENT_TYPES.INSURANCE_CERTIFICATE
                          )?.docURL
                        }
                      />

                      <FileUpload
                        onFileLoad={(images: any) => {
                          if (images && images[0]) {
                            setPolicyDocumentFiles({
                              ...policyDocumentFiles,
                              contractSummary: images[0]
                            });
                          }
                        }}
                        label="Policy Schedule"
                        maxHeight={177}
                        maxWidth={350}
                        imageContainerWidth="100%"
                        cropImage={false}
                        defaultImage={policy?.getContractSummary()?.docURL}
                        onFileDeleted={() => {
                          setPolicyDocumentFiles({
                            ...policyDocumentFiles,
                            contractSummary: null
                          });
                        }}
                        showFileMax={false}
                        savedImage={
                          policy?.documents?.find(
                            (document: IPolicyDocument) =>
                              document?.docType === POLICY_DOCUMENT_TYPES.POLICY_SCHEDULE
                          )?.docURL
                        }
                      />

                      <FileUpload
                        onFileLoad={(images: any) => {
                          if (images && images[0]) {
                            setPolicyDocumentFiles({
                              ...policyDocumentFiles,
                              roadWorthy: images[0]
                            });
                          }
                        }}
                        label="Road Worthy"
                        maxHeight={177}
                        maxWidth={350}
                        imageContainerWidth="100%"
                        cropImage={false}
                        defaultImage={policy?.getRoadWorthy()}
                        onFileDeleted={() => {
                          setPolicyDocumentFiles({
                            ...policyDocumentFiles,
                            roadWorthy: null
                          });
                        }}
                        showFileMax={false}
                        savedImage={
                          policy?.documents?.find(
                            (document: IPolicyDocument) =>
                              document?.docType === POLICY_DOCUMENT_TYPES.ROADWORTHY
                          )?.docURL
                        }
                      />
                    </div>
                  </div>
                  {/* End Policy Documents */}

                  {/* Submit button */}
                  <div className="flex justify-end m-8 space-x-6 items-center">
                    <div className="">
                      <Button
                        label="Cancel"
                        className="border-[1px] border-primary-main w-fit !py-2 px-10 text-[16px] font-bold rounded-0"
                        onClick={() => {
                          navigate(`${getDashboardBase()}/policies/${policyId}`, { replace: true });
                        }}
                      />
                    </div>
                    <div className="flex py-4 justify-end">
                      {getSaveActions(submitForm)?.length > 0 && (
                        <PopOnMe
                          actions={getSaveActions(submitForm, errors)}
                          popOptionClasses="!h-[35px]">
                          <button
                            type="button"
                            className={clsx(
                              'flex justify-between items-center space-x-4 p-1 outline-none border-[1px] border-primary-main px-4 py-2 w-fit bg-primary-main',
                              { ['opacity-50']: submitting }
                            )}
                            disabled={submitting}>
                            <span>{submitting ? 'Saving...' : 'Save'}</span>
                            <ChevronDownIcon className="w-4" />
                          </button>
                        </PopOnMe>
                      )}
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        )
      )}
      <ModalComponent />
      <PaymentModalComponent />
    </div>
  );
};

export default EditPolicy;

interface IFormData {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  otherPhone: string;
  email: string;
  address: string;
  occupation: string;
  protectionType: string;
  excess: number;
  vehicleInsuredValue: number;
  vehicleType: string;
  makeOfVehicle: string;
  vehicleModel: string;
  vehicleColor: string;
  vehicleUse: string;
  vehicleMake: string;
  numOfPassenger: number;
  goodRepairState: string | undefined;
  repairState: string;
  chassisNumber: string;
  registrationNumber: string;
  registrationYear: string;
  vehicleRegion: string;
  ownerName: string;
  vehicleAltered: string | undefined;
  alterationDetails: string;
  hasInterestedParties: string | undefined;
  interestedParties: string;
  vehicleMainDriver: string;
  hasDisease: string | undefined;
  diseaseOrComplications: string;
  previouslyDeclined: string | undefined;
  declinedByOtherInsurer: string;
  previouslyIssuedClaim: string | undefined;
  issuedClaimDetails: string;
  cc: number;
  powerType: string;
  dateOfBirth: string;
  idExpiryDate: string;
  manufacturedYear: string;
  idNumber: string;
  idType: string;
  employerName: string;
  employerAddress: string;
  employerPhoneNumber: string;
  monthlyIncome: string;
  insurer: string;
  refereeName: string;
  refereePhone: string;
  refereeYearsKnown: number;
  refereeRelationship: string;
  code: string;
  community: string;
  startsOn: string;
  composerIsOwner: string;
  natureOfInterestInVehicle: string;
  ownerAddress: string;
  ownerPhone: string;
  hasInstalledAccessories: string | undefined;
  installedAccessoriesDetails: string;
  installedAccessoriesValue: number;
  rideSharePeriod: number;
  liability: string;
  liabilityDebt: string;
  liabilityModeOfPayment: string;
}
