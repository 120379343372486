import React from 'react';
import { BellIcon } from '@heroicons/react/24/outline';
import Tooltip from '../ToolTip';

interface NotificationIconProps {
  notifications: number;
}

const NotificationIcon: React.FC<NotificationIconProps> = ({ notifications }) => {
  return (
    <div className="relative h-6 w-6 rounded-full py-1 bg-white hover:bg-primary-main ease-in-out duration-200	">
      <Tooltip direction="bottom" bgColor="bg-gray-200" message={'Notifications'}>
        <BellIcon className="h-[18px] w-[18px]" />
        {notifications > 0 && (
          <span className="absolute -top-1 -right-1 flex w-3 h-3 bg-red-500 text-white text-xs font-semibold rounded-full items-center justify-center">
            {notifications}
          </span>
        )}
      </Tooltip>
    </div>
  );
};

export default NotificationIcon;
