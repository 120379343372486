import { ReactNode, useEffect, useState } from 'react';
import Button from '../../../form-control/Button';
import { CheckBoxInput } from '../../../form-control/CheckBoxInput';
import { POLICY_TYPES } from '../../../../resources/enums';
import { ReactComponent as BusinessIcon } from "../imgs/business.svg";
import { ReactComponent as HomeIcon } from "../imgs/home.svg";
import { ReactComponent as CarIcon } from "../imgs/car.svg";
import { ReactComponent as TravelIcon } from "../imgs/travel.svg";
import clsx from 'clsx';
import { getAllProducts, getInsurerProfile, updateInsurerStore } from '../../../../api/requests';
import { IProduct, IUpdateInsurerStoreDTO } from '../../../../resources/interfaces';
import { toast } from 'react-toastify';
import { Insurer } from '../../../../models/insurer/Insurer';
import Loader from '../../../Loader';

interface IInsurerDetailsProps {
  proceed: () => void;
  onBack: () => void;
}

const INSURANCE_SERVICES: IInsuranceService[] = [
  {
    name: "Car",
    icon: <CarIcon className='w-[4rem] h-[4rem] text-[4rem] text-grey-300' />,
    key: POLICY_TYPES.AUTO
  },
  {
    name: "Renters / Home Owners",
    icon: <HomeIcon className='w-[4rem] h-[4rem] text-grey-300' />,
    key: POLICY_TYPES.HOME
  },
  {
    name: "Business",
    icon: <BusinessIcon className='w-[4rem] h-[4rem] text-[4rem]' />,
    key: POLICY_TYPES.BUSINESS
  },
  {
    name: "Travel",
    icon: <TravelIcon className='w-[4rem] h-[4rem] text-grey-300' />,
    key: POLICY_TYPES.TRAVEL
  },
]

interface IInsuranceService {
  name: string;
  icon: JSX.Element | ReactNode;
  key: string;
  id?: string;
}

const InsuranceServices = (props: IInsurerDetailsProps) => {
  const [insurer, setInsurer] = useState<any>();

  const [insuranceServices, setInsuranceServices] = useState<IInsuranceService[]>([]);
  const [selectedServices, setSelectedServices] = useState<IInsuranceService[]>([]);

  useEffect(() => {
    getInsurerProducts();

    getInsurerProfile().then((response: any) => {
      setInsurer(response);
    });
  }, []);

  const getInsurerProducts = () => {
    getAllProducts().then((response: any) => {
      const services: IInsuranceService[] = [];
      response?.forEach((product: IProduct) => {
        const service = INSURANCE_SERVICES.find((service: IInsuranceService) =>
          product?.name?.toLowerCase()?.includes(service.key)
        );

        if (service) {
          service.id = product.id;

          services.push(service);
        }
      });

      setInsuranceServices(services);
    });
  };
  console.log(insuranceServices);
  const isServiceSelected = (value: string): boolean => {
    const service = selectedServices.find((service: IInsuranceService) => service.key === value);

    return service ? true : false;
  };

  const submitHandler = () => {
    if (selectedServices?.length === 0) {
      toast.error('Select at least one service to continue', {
        autoClose: 3000
      });
      return;
    }

    const productIds: string[] = [];
    selectedServices.forEach((service: IInsuranceService) => {
      if (service.id) {
        productIds.push(service.id);
      }
    });

    const payload: IUpdateInsurerStoreDTO = {
      description: insurer?.description ?? '',
      benefits: insurer?.benefits ?? [],
      products: productIds
    };

    updateInsurerStore(payload).then((response: any) => {
      props.proceed();
    });
  };

  return (
    <div className="px-4">
      {insuranceServices.length == 0 ? (
        <Loader message="Loading products. Please wait..." brollyLoader fullscreen />
      ) : (
        <>
          <h2 className="text-xl font-semibold w-full text-center">
            Which insurance products do you offer?
          </h2>
          <div className="flex flex-col md:flex-row  items-center gap-2 md:gap-2 mt-5 justify-center">
            {insuranceServices.map((service: IInsuranceService, index: number) => (
              <div
                className={clsx(
                  'w-full md:w-[250px] border-[1px] border-gray-300 rounded flex flex-col items-center justify-center space-y-8 py-8',
                  { ['border-primary-main bg-primary-surface']: isServiceSelected(service.key) }
                )}
                key={index}>
                {service.icon}
                <h3 className="uppercase text-grey-300 text-[11px] text-center">
                  {service.name} <br />
                  {service.key === POLICY_TYPES.HOME && (
                    <span className="normal-case text-gray-400">
                      Home policies will be available soon
                    </span>
                  )}
                </h3>
                <CheckBoxInput
                  label=""
                  name={service.name}
                  checked={isServiceSelected(service.key)}
                  onChange={(selected: boolean) => {
                    if (selected) {
                      setSelectedServices([...selectedServices, service]);
                    } else {
                      setSelectedServices(
                        selectedServices.filter(
                          (service: IInsuranceService) => service.key !== service.key
                        )
                      );
                    }
                  }}
                  classes="border-[1px] border-grey-300 w-6 h-6 rounded-[2px]"
                />
              </div>
            ))}
          </div>
          <div className="flex-row items-center md:flex justify-center md:mt-[56px] mt-8 md:space-x-4">
            <div className="">
              <Button
                label="Back"
                className="w-full mb-5 md:mb-0  md:w-[150px] h-[52px] rounded !border border-black hover:bg-white hover:border-black py-0 bg-black text-white !font-bold"
                onClick={props.onBack}
              />
            </div>
            <Button
              // loading={isSubmitting}
              label="Save"
              // type="submit"
              className="w-full md:w-[150px] bg-primary-main rounded h-[52px] font-bold"
              onClick={submitHandler}
            />
          </div>{' '}
        </>
      )}
    </div>
  );
};

export default InsuranceServices;
