import { XMarkIcon } from '@heroicons/react/24/solid';
import { useState } from 'react';
import { BsSend } from 'react-icons/bs';
import * as Yup from 'yup';
import Button from '../../../components/form-control/Button';
import SelectDropdown from '../../../components/form-control/SelectDropdown';
import { Policy } from '../../../models/policy/Policy';
import Input from '../../../components/form-control/Input';
import { Formik } from 'formik';
import {
  getFormattedPhoneNumber,
  getPhoneNumberFromShortCodeFormat
} from '../../../utils/miscFunctions';
import { adminRequestFeedbackFromCustomer } from '../../../api/requests';
import { toast } from 'react-toastify';

type RequestReviewModalProps = {
  policy: Policy;
  onClose: () => void;
  onProceed?: (url: string) => void;
};

const RequestReviewModal = ({ policy, onClose, onProceed }: RequestReviewModalProps) => {
  const [loading, setLoading] = useState<boolean>(false);

  return (
    <div className="w-full">
      <div className="bg-primary-main flex justify-between">
        <div className="p-[1rem] flex justify-between w-full items-center">
          <div className="flex items-center justify-center gap-5">
            <div className="flex items-center justify-center bg-white h-[2.5rem] w-[2.5rem] rounded-full gap-3">
              <BsSend className="h-5 w-5" />
            </div>
            <h2 className="font-[gilroy-bold] text-lg">Request Review</h2>
          </div>
          <XMarkIcon onClick={onClose} width={'18px'} height={'18px'} className="cursor-pointer" />
        </div>
      </div>

      <div className="p-5">
        <Formik
          initialValues={{
            channel: '',
            email: policy?.applicant?.email,
            phone: getPhoneNumberFromShortCodeFormat(policy?.applicant?.phoneNumber ?? '')
          }}
          validateOnChange={false}
          validateOnBlur={true}
          validationSchema={Yup.object({
            channel: Yup.string().trim().required('Channel is required.')
          })}
          onSubmit={async (values) => {
            setLoading(true);
            const phone = getFormattedPhoneNumber(values.phone);
            const identifier =
              values.channel === 'sms' || values.channel === 'whatsapp' ? phone : values.email;

            // Ensure identifier is valid
            if (!identifier) {
              toast.error('Please provide a valid phone number or email.');
              setLoading(false);
              return;
            }

            try {
              const response = await adminRequestFeedbackFromCustomer(policy?.ref, values.channel, identifier);

              if (response) {
                toast.success(response?.message ?? 'Feedback link shared successfully.', {
                  autoClose: 3000
                });
                onProceed?.(response?.data);
              }
            } catch (error: any) {
              toast.error(error?.description);
            } finally {
              setLoading(false);
            }
          }}
        >
          {({ values, errors, handleSubmit, handleChange, setFieldValue, handleBlur, touched }) => (
            <form className="" onSubmit={handleSubmit}>
              <div className="mt-5">
                <SelectDropdown
                  label="Channel"
                  name="channel"
                  listSelectedValue={values.channel}
                  list={[
                    { name: 'Email', value: 'email' },
                    { name: 'SMS', value: 'sms' },
                    { name: 'WhatsApp', value: 'whatsapp' }
                  ]}
                  onChange={(val: string | null) => setFieldValue('channel', val)}
                  onBlur={handleBlur}
                  placeholder="Select channel of transfer"
                  error={touched.channel ? errors.channel : undefined}
                  helperText={touched.channel ? errors.channel : undefined}
                  required
                  wrapperClasses="w-full"
                />
              </div>

              <div className="mt-5">
                {values.channel == 'email' && (
                  <Input
                    name="email"
                    label="Email"
                    value={values.email}
                    type="text"
                    wrapperClasses=""
                    required
                    onChange={handleChange}
                    onBlur={handleChange}
                    error={errors.email}
                    helperText={errors.email}
                  />
                )}
                {(values.channel == 'sms' || values.channel == 'whatsapp') && (
                  <Input
                    name="phone"
                    label="Phone"
                    value={values.phone}
                    type="tel"
                    wrapperClasses=""
                    onChange={handleChange}
                    onBlur={handleChange}
                    error={errors.phone}
                    helperText={errors.phone}
                    required
                  />
                )}
              </div>

              <div className="flex justify-end mt-5">
                <Button
                  type="submit"
                  label="Submit"
                  bgColor="primary"
                  curved
                  className="w-fit px-6 text-[14px]"
                  loading={loading}
                  disabled={loading}
                />
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default RequestReviewModal;
