import { MdOutlineLogout as LogoutIcon } from 'react-icons/md';
import { IVehicleUsages } from './interfaces';
import { ACCESS_RIGHTS, INSURER_ACCESS_RIGHTS } from './enums';
export const PERSISTENT_STORAGE_KEY = 'adm-k89';
import { ReactComponent as DashboardOverviewIcon } from './icons/DashboardOverviewIcon.svg';
import { ReactComponent as DashboardLeadsIcon } from './icons/DashboardLeadsIcon.svg';
import { ReactComponent as DashboardQuotesIcon } from './icons/DashboardQuotesIcon.svg';
import { ReactComponent as DashboardSettingsIcon } from './icons/DashboardSettingsIcon.svg';
import { ReactComponent as DashboardPaymentsIcon } from './icons/DashboardPaymentsIcon.svg';
import { ReactComponent as DashboardPayoutsIcon } from './icons/DashboardPayoutsIcon.svg';
import { ReactComponent as DashboardTeamsIcon } from './icons/DashboardTeamsIcon.svg';
import { ReactComponent as DashboardPoliciesIcon } from './icons/DashboardPoliciesIcon.svg';
import { ReactComponent as DashboardCommunitiesIcon } from './icons/DashboardCommunitiesIcon.svg';
import { ReactComponent as DashboardCustomersIcon } from './icons/DashboardCustomersIcon.svg';
import { ReactComponent as DashboardStoreIcon } from './icons/DashboardStoreIcon.svg';
import { ReactComponent as DashboardClaimsIcon } from './icons/claimsIcon.svg';
import { ReactComponent as DashboardSalesIcon } from './icons/DashboardSalesIcon.svg';
import { ReactComponent as DashboardReviewIcon } from './icons/DashboardReviewIcon.svg';
import { ReactComponent as DashboardResourceIcon } from './icons/DashboardFilesIcon.svg';
import { ReactComponent as DashboardAuditIcon } from './icons/DashboardAuditIcon.svg';
import { ReactComponent as DashboardFinancialsIcon } from './icons/DashboardFinancialsIcon.svg';
import { ReactComponent as DashboardRnPlIcon } from './icons/DashboardRnPlIcon.svg';
import { ReactComponent as ApprovalsIcon } from './icons/ApprovalIcon.svg';
import { FaHome } from 'react-icons/fa';
import { LuFuel, LuListTodo } from 'react-icons/lu';
import { FaCar } from 'react-icons/fa';
import { ChartBarIcon } from '@heroicons/react/24/outline';
import { LiaReadme } from 'react-icons/lia';

export const SIDE_BAR_NAVS = {
  top: [
    {
      title: 'Overview',
      path: '/dashboard',
      icon: DashboardOverviewIcon,
      access: [
        ACCESS_RIGHTS.SUPER_ADMINISTRATOR,
        ACCESS_RIGHTS.REVIEWER,
        ACCESS_RIGHTS.ADMINISTRATOR,
        ACCESS_RIGHTS.TECHNICAL,
        ACCESS_RIGHTS.AGENT,
        ACCESS_RIGHTS.AGENCY_ADMIN,
        ACCESS_RIGHTS.INSURER_ADMIN,
        ACCESS_RIGHTS.INSURER_AGENT,
        ACCESS_RIGHTS.INSURER_FINANCE_ADMIN,
        ACCESS_RIGHTS.INSURER_POLICY_ADMIN,
        ACCESS_RIGHTS.INSURER_TECH_ADMIN,
        ACCESS_RIGHTS.INSURER_MARKETING_ADMIN,
        ACCESS_RIGHTS.RIDE_SHARING_ADMIN,
        ACCESS_RIGHTS.RIDE_SHARING_AGENT,
        ACCESS_RIGHTS.FLEET_MANAGER,
        ACCESS_RIGHTS.FLEET_ADMIN,
        ACCESS_RIGHTS.FUEL_MANAGER,
        ACCESS_RIGHTS.FUEL_ADMIN,
        ACCESS_RIGHTS.FUEL_VENDOR
      ],
      key: 'dashboard'
    }
  ],

  middle: [
    {
      title: 'Approvals',
      path: '/approvals',
      icon: ApprovalsIcon,
      access: [ACCESS_RIGHTS.SUPER_ADMINISTRATOR, ACCESS_RIGHTS.TECHNICAL],
      key: 'approvals'
    },
    // {
    //   title: 'Tasks',
    //   path: '/tasks',
    //   icon: LuListTodo,
    //   access: [
    //     ACCESS_RIGHTS.SUPER_ADMINISTRATOR,
    //     ACCESS_RIGHTS.REVIEWER,
    //     ACCESS_RIGHTS.ADMINISTRATOR,
    //     ACCESS_RIGHTS.TECHNICAL,
    //     ACCESS_RIGHTS.AGENCY_ADMIN,
    //     ACCESS_RIGHTS.AGENT,
    //     ACCESS_RIGHTS.INSURER_ADMIN,
    //     ACCESS_RIGHTS.INSURER_AGENT,
    //     ACCESS_RIGHTS.INSURER_FINANCE_ADMIN,
    //     ACCESS_RIGHTS.INSURER_POLICY_ADMIN,
    //     ACCESS_RIGHTS.INSURER_TECH_ADMIN,
    //     ACCESS_RIGHTS.INSURER_CLAIM_ADMIN,
    //     ACCESS_RIGHTS.INSURER_MARKETING_ADMIN
    //   ],
    //   key: 'tasks'
    // },
    {
      title: 'Signups',
      path: '/signups',
      icon: DashboardLeadsIcon,
      access: [
        ACCESS_RIGHTS.SUPER_ADMINISTRATOR,
        ACCESS_RIGHTS.REVIEWER,
        ACCESS_RIGHTS.ADMINISTRATOR,
        ACCESS_RIGHTS.AGENCY_ADMIN,
        ACCESS_RIGHTS.AGENT,
        ACCESS_RIGHTS.TECHNICAL
        // ACCESS_RIGHTS.RIDE_SHARING_ADMIN,
        // ACCESS_RIGHTS.RIDE_SHARING_AGENT
      ],
      key: 'signups'
    },
    // {
    //   title: 'Quotes',
    //   path: '/quotes',
    //   icon: DashboardQuotesIcon,
    //   access: [
    //     ACCESS_RIGHTS.SUPER_ADMINISTRATOR,
    //     ACCESS_RIGHTS.REVIEWER,
    //     ACCESS_RIGHTS.ADMINISTRATOR,
    //     ACCESS_RIGHTS.TECHNICAL,
    //     ACCESS_RIGHTS.AGENCY_ADMIN,
    //     ACCESS_RIGHTS.AGENT,
    //     ACCESS_RIGHTS.AGENCY_ADMIN,
    //     ACCESS_RIGHTS.FLEET_MANAGER,
    //     ACCESS_RIGHTS.FLEET_ADMIN
    //   ],
    //   key: 'quotes'
    // },
    {
      title: 'Policies',
      path: '/policies',
      icon: DashboardPoliciesIcon,
      access: [
        ACCESS_RIGHTS.SUPER_ADMINISTRATOR,
        ACCESS_RIGHTS.REVIEWER,
        ACCESS_RIGHTS.ADMINISTRATOR,
        ACCESS_RIGHTS.TECHNICAL,
        ACCESS_RIGHTS.AGENCY_ADMIN,
        ACCESS_RIGHTS.AGENT,
        ACCESS_RIGHTS.INSURER_ADMIN,
        ACCESS_RIGHTS.INSURER_AGENT,
        ACCESS_RIGHTS.INSURER_POLICY_ADMIN,
        ACCESS_RIGHTS.INSURER_MARKETING_ADMIN,
        ACCESS_RIGHTS.FLEET_MANAGER,
        ACCESS_RIGHTS.FLEET_ADMIN
      ],
      key: 'policies',
      hasChildren: true,
      children: [
        {
          title: 'Auto',
          path: '/policies/?type=auto',
          icon: FaCar
        },
        {
          title: 'Home',
          path: '/policies/?type=home',
          icon: FaHome
        }
      ]
    },
    {
      title: 'Fuel',
      path: '/fuel',
      icon: LuFuel,
      access: [ACCESS_RIGHTS.SUPER_ADMINISTRATOR, ACCESS_RIGHTS.REVIEWER, ACCESS_RIGHTS.TECHNICAL],
      key: 'fuel'
    },
    {
      title: 'RNPL',
      path: '/rnpl',
      icon: DashboardRnPlIcon,
      access: [
        ACCESS_RIGHTS.SUPER_ADMINISTRATOR,
        ACCESS_RIGHTS.REVIEWER,
        ACCESS_RIGHTS.ADMINISTRATOR,
        ACCESS_RIGHTS.AGENCY_ADMIN,
        ACCESS_RIGHTS.AGENT,
        ACCESS_RIGHTS.TECHNICAL 
      ],
      key: 'rnpl'
    },
    {
      title: 'Claims',
      path: '/claims',
      icon: DashboardClaimsIcon,
      access: [
        ACCESS_RIGHTS.SUPER_ADMINISTRATOR,
        ACCESS_RIGHTS.REVIEWER,
        // ACCESS_RIGHTS.ADMINISTRATOR,
        ACCESS_RIGHTS.TECHNICAL,
        ACCESS_RIGHTS.AGENT,
        ACCESS_RIGHTS.AGENCY_ADMIN
      ],
      key: 'claims'
    },
    // {
    //   title: 'Communities',
    //   path: '/communities',
    //   icon: DashboardCommunitiesIcon,
    //   access: [
    //     ACCESS_RIGHTS.SUPER_ADMINISTRATOR,
    //     ACCESS_RIGHTS.REVIEWER,
    //     ACCESS_RIGHTS.ADMINISTRATOR,
    //     ACCESS_RIGHTS.TECHNICAL,
    //     ACCESS_RIGHTS.AGENCY_ADMIN,
    //     ACCESS_RIGHTS.AGENT
    //   ],
    //   key: 'communities'
    // },
    {
      title: 'Customers',
      path: '/customers',
      icon: DashboardCustomersIcon,
      access: [
        ACCESS_RIGHTS.SUPER_ADMINISTRATOR,
        ACCESS_RIGHTS.REVIEWER,
        ACCESS_RIGHTS.ADMINISTRATOR,
        ACCESS_RIGHTS.TECHNICAL
      ],
      key: 'customers'
    },
    {
      title: 'Payments',
      path: '/payments',
      icon: DashboardPaymentsIcon,
      access: [
        ACCESS_RIGHTS.SUPER_ADMINISTRATOR,
        ACCESS_RIGHTS.REVIEWER,
        ACCESS_RIGHTS.ADMINISTRATOR,
        ACCESS_RIGHTS.TECHNICAL,
        ACCESS_RIGHTS.AGENCY_ADMIN,
        ACCESS_RIGHTS.FLEET_ADMIN,
        ACCESS_RIGHTS.FLEET_MANAGER
      ],
      key: 'payments'
    },
    {
      title: 'Sales',
      path: '/sales',
      icon: DashboardSalesIcon,
      access: [
        ACCESS_RIGHTS.INSURER_ADMIN,
        ACCESS_RIGHTS.INSURER_AGENT,
        ACCESS_RIGHTS.INSURER_FINANCE_ADMIN,
        ACCESS_RIGHTS.INSURER_MARKETING_ADMIN,
        // ACCESS_RIGHTS.FLEET_MANAGER,
        // ACCESS_RIGHTS.FUEL_MANAGER,
        ACCESS_RIGHTS.FUEL_ADMIN,
        ACCESS_RIGHTS.FUEL_VENDOR,
        ACCESS_RIGHTS.TECHNICAL
      ],
      key: 'sales'
    },
    {
      title: 'Payouts',
      path: '/payouts',
      icon: DashboardPayoutsIcon,
      access: [
        ACCESS_RIGHTS.INSURER_ADMIN,
        ACCESS_RIGHTS.INSURER_FINANCE_ADMIN,
        ACCESS_RIGHTS.AGENCY_ADMIN,
        ACCESS_RIGHTS.TECHNICAL
      ],
      key: 'payouts'
    },
    {
      title: 'Team',
      path: '/team',
      icon: DashboardTeamsIcon,
      access: [ACCESS_RIGHTS.AGENCY_ADMIN, ACCESS_RIGHTS.TECHNICAL],
      key: 'team'
    },

    {
      title: 'Financials',
      path: '/financials',
      icon: DashboardFinancialsIcon,
      access: [
        ACCESS_RIGHTS.TECHNICAL,
        ACCESS_RIGHTS.AGENCY_ADMIN,
        ACCESS_RIGHTS.RIDE_SHARING_ADMIN,
        ACCESS_RIGHTS.RIDE_SHARING_AGENT,
        ACCESS_RIGHTS.FLEET_MANAGER,
        ACCESS_RIGHTS.FLEET_ADMIN
      ],
      key: 'financials'
    },

    {
      title: 'Groups',
      path: '/teams',
      icon: DashboardTeamsIcon,
      access: [ACCESS_RIGHTS.SUPER_ADMINISTRATOR, ACCESS_RIGHTS.REVIEWER, ACCESS_RIGHTS.TECHNICAL],
      key: 'teams'
    },
    {
      title: 'Blog',
      path: '/blogs',
      icon: LiaReadme,
      access: [ACCESS_RIGHTS.SUPER_ADMINISTRATOR, ACCESS_RIGHTS.ADMINISTRATOR],
      key: 'blog'
    },
    {
      title: 'Audit Logs',
      path: '/audit',
      icon: DashboardAuditIcon,
      access: [ACCESS_RIGHTS.SUPER_ADMINISTRATOR, ACCESS_RIGHTS.TECHNICAL],
      key: 'audit'
    },

    {
      title: 'Resources',
      path: '/resources',
      icon: DashboardResourceIcon,
      access: [ACCESS_RIGHTS.INSURER_ADMIN, ACCESS_RIGHTS.TECHNICAL],
      key: 'resources'
    },
    {
      title: 'Store',
      path: '/store',
      icon: DashboardStoreIcon,
      access: [
        ACCESS_RIGHTS.INSURER_ADMIN,
        ACCESS_RIGHTS.INSURER_TECH_ADMIN,
        ACCESS_RIGHTS.INSURER_MARKETING_ADMIN
      ],
      key: 'store'
    },
    {
      title: 'Quota',
      path: '/quota',
      icon: ChartBarIcon,
      access: [ACCESS_RIGHTS.FUEL_MANAGER, ACCESS_RIGHTS.TECHNICAL],
      key: 'reviews'
    },
    {
      title: 'Audit',
      path: '/audit',
      icon: DashboardReviewIcon,
      access: [ACCESS_RIGHTS.FUEL_ADMIN, ACCESS_RIGHTS.TECHNICAL, ACCESS_RIGHTS.FUEL_MANAGER],
      key: 'audit'
    },
    {
      title: 'Reviews',
      path: '/reviews',
      icon: DashboardReviewIcon,
      access: [
        ACCESS_RIGHTS.TECHNICAL,
        ACCESS_RIGHTS.INSURER_ADMIN,
        ACCESS_RIGHTS.INSURER_AGENT,
        ACCESS_RIGHTS.INSURER_FINANCE_ADMIN,
        ACCESS_RIGHTS.INSURER_POLICY_ADMIN,
        ACCESS_RIGHTS.INSURER_CLAIM_ADMIN,
        ACCESS_RIGHTS.INSURER_TECH_ADMIN,
        ACCESS_RIGHTS.INSURER_MARKETING_ADMIN
      ],
      key: 'reviews'
    }

    // {
    //   title: 'Settings',
    //   path: '/settings',
    //   icon: DashboardSettingsIcon,
    //   access: [
    //     ACCESS_RIGHTS.SUPER_ADMINISTRATOR,
    //     ACCESS_RIGHTS.REVIEWER,
    //     ACCESS_RIGHTS.ADMINISTRATOR,
    //     ACCESS_RIGHTS.TECHNICAL,
    //     ACCESS_RIGHTS.AGENCY_ADMIN,
    //     ACCESS_RIGHTS.AGENT,
    //     ACCESS_RIGHTS.INSURER_ADMIN,
    //     ACCESS_RIGHTS.INSURER_AGENT,
    //     ACCESS_RIGHTS.INSURER_FINANCE_ADMIN,
    //     ACCESS_RIGHTS.INSURER_POLICY_ADMIN,
    //     ACCESS_RIGHTS.INSURER_TECH_ADMIN,
    //     ACCESS_RIGHTS.INSURER_CLAIM_ADMIN,
    //     ACCESS_RIGHTS.INSURER_MARKETING_ADMIN,
    //     ACCESS_RIGHTS.RIDE_SHARING_ADMIN,
    //     ACCESS_RIGHTS.RIDE_SHARING_AGENT,
    //     ACCESS_RIGHTS.FLEET_ADMIN,
    //     ACCESS_RIGHTS.FLEET_MANAGER,
    //     ACCESS_RIGHTS.FUEL_MANAGER,
    //     ACCESS_RIGHTS.FUEL_ADMIN
    //   ],
    //   key: 'settings'
    // }
  ],

  bottom: [
    {
      title: 'Log out',
      path: '/settings',
      icon: LogoutIcon,
      access: [
        ACCESS_RIGHTS.SUPER_ADMINISTRATOR,
        ACCESS_RIGHTS.REVIEWER,
        ACCESS_RIGHTS.ADMINISTRATOR,
        ACCESS_RIGHTS.TECHNICAL,
        ACCESS_RIGHTS.AGENT,
        ACCESS_RIGHTS.INSURER_ADMIN,
        ACCESS_RIGHTS.INSURER_AGENT,
        ACCESS_RIGHTS.INSURER_FINANCE_ADMIN,
        ACCESS_RIGHTS.INSURER_POLICY_ADMIN,
        ACCESS_RIGHTS.INSURER_TECH_ADMIN,
        ACCESS_RIGHTS.INSURER_CLAIM_ADMIN,
        ACCESS_RIGHTS.RIDE_SHARING_ADMIN,
        ACCESS_RIGHTS.RIDE_SHARING_AGENT
      ],
      key: 'settings'
    }
  ]
};

export const RIDE_SHARING_SIDEBAR_NAVS = ['dashboard', 'leads', 'sales', 'payouts', 'settings'];

export const SETTINGS_NAV = {
  profile: 'profile',
  notifications: 'notifications',
  account: 'account',
  paymentOptions: 'paymentOptions'
};

export const VEHICLE_USAGES: Record<string, IVehicleUsages> = {
  BUS: {
    title: 'Bus (Private or Commercial use)',
    description: ''
  },
  PRIVATE_USE_OWN_VEHICLE: {
    title: 'Private Use (Individual Owned)',
    description: ''
  },
  SPECIAL_TYPE_SITE: {
    title: 'Special Type (Site use only)',
    description: ''
  },
  PRIVATE_USE_COMPANY_OWNED: {
    title: 'Private Use (Company Owned)',
    description: ''
  },
  GENERAL_CARTAGE_ABOVE_3000: {
    title: 'General Cartage (Above 3000 cc)',
    description: ''
  },
  HIRING_CAR: {
    title: 'Hiring Car',
    description: ''
  },
  OWN_GOODS_CARRYING_VEHICLE_BELOW_3000: {
    title: 'Own Goods Carrying Vehicle (Below 3000 cc)',
    description: ''
  },
  HAILING_SERVICE: {
    title: 'Hailing Services (Uber/Bolt/Yango ...)',
    description: 'Comprises of Uber, Bolt, Yango, ...etc'
  },
  TRADE_PLATE: {
    title: 'Trade Plate (Personal Use only)',
    description: ''
  },
  GENERAL_CARTAGE_BELOW_3000: {
    title: 'General Cartage (Below 3000 cc)',
    description: ''
  },
  TAXI: {
    title: 'Taxi',
    description: ''
  },
  OWN_GOODS_CARRYING_VEHICLE_OVER_3000: {
    title: 'Own Goods Carrying Vehicle (Above 3000 cc)',
    description: ''
  },
  SPECIAL_SITE_TYPE_ROAD: {
    title: 'Special Type (Site use and Road use)',
    description: ''
  },
  ARTICULATOR_HEAD: {
    title: 'Articulated Truck Head',
    description: ''
  }
};

export const VEHICLE_PROTECTION_TYPES: Record<string, IVehicleUsages> = {
  THIRD_PARTY_FIRE_THEFT: {
    title: 'Third Party (Fire/Theft)',
    description: ''
  },
  COMPREHENSIVE_85: {
    title: '85% Comprehensive',
    description: ''
  },
  COMPREHENSIVE_90: {
    title: '90% Comprehensive',
    description: ''
  },
  THIRD_PARTY: {
    title: 'Third Party',
    description: ''
  },
  'THIRD PARTY': {
    title: 'Third Party',
    description: ''
  },
  COMPREHENSIVE_100: {
    title: '100% Comprehensive',
    description: ''
  },
  SMART_DRIVE: {
    title: 'Smart Drive',
    description: ''
  }
};

export const VEHICLE_COLORS = [
  'White',
  'Black',
  'Gray',
  'Silver',
  'Red',
  'Blue',
  'Brown',
  'Green',
  'Beige',
  'Orange',
  'Yellow'
];

export const GHANA_REGIONS = [
  'Ahafo',
  'Ashanti',
  'Bono East',
  'Brong Ahafo',
  'Central',
  'Eastern',
  'Greater-Accra',
  'Western',
  'Western North',
  'Northern',
  'North-East',
  'Oti',
  'Savannah',
  'Upper East',
  'Upper West',
  'Volta'
];

export const SERVER_DATE_FORMAT = 'MM-DD-YYYY';

export const INPUT_DATE_FORMAT = 'YYYY-MM-DD';

export const ID_TYPES = ['National ID', 'Passport', "Voter's ID", "Driver's Lincense"];

export const OCCUPATION_TYPES: Record<string, IVehicleUsages> = {
  MINING_AND_ENGINEERING: {
    title: 'Mining and Engineering',
    description: ''
  },
  UNEMPLOYED: {
    title: 'Unemployed',
    description: ''
  },
  COMMERCIAL_DRIVER: {
    title: 'Commercial Driver',
    description: ''
  },
  SELF_EMPLOYED: {
    title: 'Self Employed',
    description: ''
  },
  SALESPERSON: {
    title: 'Sales Person',
    description: ''
  },
  HEALTH_WORKER: {
    title: 'Health Worker',
    description: ''
  },
  SECURITY_SERVICES: {
    title: 'Security Services',
    description: ''
  },
  TRAVEL_AGENT: {
    title: 'Travel Agent',
    description: ''
  },
  ANY_OTHER_PROFESSION: {
    title: 'Any Other Profession',
    description: ''
  },
  BUSINESS_EXECUTIVE: {
    title: 'Business Executive',
    description: ''
  }
};

export const libraries: Array<'drawing' | 'places' | 'geometry'> = [
  'drawing',
  'places',
  'geometry'
];

export const MINIMUM_ALLOWABLE_MANUFACTURING_YEAR = new Date().getFullYear() - 23;

export const EMPLOYEE_INCOME_BAND = [
  '1,000.00 - 5,000.00',
  '5,0001.00 - 10,000.00',
  '10,0001.00 - 20,000.00',
  '20,001.00 +'
];

export const RIDE_SHARING_DAILY_AVERAGE_INCOME = [
  'Below 200.00',
  '200.00 - 399.00',
  '400.00 - 599.00',
  '600.00 - 799.00',
  '800.00 - 999.00',
  '1000.00 +'
];

export const SUCCESS_PAYMENT_STATUS_COLOR = `bg-[#0F7125] text-white`;
export const NEW = `bg-[#57A9F4]`;
export const IN_APPROVAL_PAYMENT_STATUS_COLOR = `bg-[#F18F34]`;
export const IN_REVIEW_PAYMENT_STATUS_COLOR = `bg-[#c1e5c1]`;
export const ON_HOLD_PAYMENT_STATUS_COLOR = `bg-[#E8AA0E]`;
export const DECLINED_PAYMENT_STATUS_COLOR = `bg-[#F06565]`;
export const CANCELLED_PAYMENT_STATUS_COLOR = `bg-[#4F5357] text-white`;
export const ARCHIVED_POLICY_STATUS_COLOR = `bg-gray-500 text-white`;
export const DUE_LATER_POLICY_STATUS_COLOR = `bg-[#BFC9CA]`;
export const EXPIRED_POLICY_STATUS_COLOR = `bg-[#ff3e00]`;
export const OVERDUE_STATUS_COLOR = `bg-red-700 text-white`;
export const COMMISSIONS_WITHHOLDING_TAX_PERCENTAGE = 5;

export const DISCOUNT_ACTIVE_STATUS_COLOR = 'bg-[#006400]';
export const DISCOUNT_INACTIVE_STATUS_COLOR = 'bg-[#FF5E5B]';

export const BANKS_OF_GHANA = [
  'Absa Bank Ghana Limited',
  'Access Bank Ghana Plc',
  'Agricultural Development Bank of Ghana',
  'Bank of Africa Ghana Limited',
  'CalBank Limited',
  'Consolidated Bank Ghana Limited',
  'Ecobank Ghana Limited',
  'FBN Bank Ghana Limited',
  'Fidelity Bank Ghana Limited',
  'First Atlantic Bank Limited',
  'First National Bank Ghana',
  'GCB Bank Limited',
  'Guaranty Trust Bank Ghana Limited',
  'National Investment Bank Limited',
  'OmniBSIC Bank Ghana Limited',
  'Prudential Bank Limited',
  'Republic Bank Ghana Limited',
  'Société Générale Ghana Limited',
  'Stanbic Bank Ghana Limited',
  'Standard Chartered Bank Ghana Limited',
  'United Bank for Africa Ghana Limited',
  'Universal Merchant Bank Limited',
  'Zenith Bank Ghana Limited.'
];

export const COUNTRY_OPTIONS = ['Ghana'];

export const LeadsDonutBgColors = [
  '#FCD36C',
  '#6EE0AB',
  '#00ADC6',
  '#666578',
  '#FBC02D',
  '#000000',
  '#807E7B',
  '#7E6017',
  '#027180',
  '#22935F'
];
//export const LeadsDonutBgColors = ['#FACC15', '#14B8A6', '#3B82F6', '#6366F1', '#F59E0B'];
export const ChannelDonutsBgColors = [
  '#6EE0AB',
  '#FCD36C',
  '#00ADC6',
  '#666578',
  '#027180',
  '#FD1D1D'
];
//export const ChannelDonutsBgColors = ['#FACC15', '#14B8A6', '#3B82F6', '#6366F1', '#F59E0B'];
export const SalesMixDonutsBgColors = ['#FBC02D', '#30D287'];
//export const SalesMixDonutsBgColors = ['#FBC02D', '#15DFB5'];
export const LeadCategoriesChartBgColors = ['#FDE6AB', '#ACEDCF', '#99DEE8', '#D3D3DE', '#999999','#9AC6CC','#FFFCF5','#9190AC'];

export const InsurerAgentRoles = [
  'Chief Executive Officer',
  'General Manager',
  'Operations Manager',
  'Accountant',
  'Software Developer',
  'Sales',
  'Marketing'
];

export const MONTHS_OF_THE_YEAR = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

export const ALPHABETS = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z'
];

export const TAG_NEW_COLOR = `bg-[#57A9F4]`;
export const TAG_CONTACTED_COLOR = `bg-[#F18F34]`;
export const TAG_QUALIFIED_COLOR = `bg-[#c1e5c1]`;
export const TAG_UNRESPONSIVE_COLOR = `bg-[#E8AA0E]`;
export const TAG_DISQUALIFIED_COLOR = `bg-[#F06565]`;
export const TAG_IN_NEGOTIATION_COLOR = `bg-[#4F5357] text-white`;
export const TAG_NURTURING_COLOR = `bg-gray-500 text-white`;
export const TAG_CONVERTED_COLOR = `bg-[#006400] text-white`;
export const TAG_NA_COLOR = `bg-[#BFC9CA]`;
