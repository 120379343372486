import React from 'react';
import { BellIcon } from '@heroicons/react/24/outline';
import Tooltip from '../ToolTip';
import { GoReport } from 'react-icons/go';



const ReportIcon = () => {
  return (
    <div className="relative  h-6 w-6 rounded-full py-[3px] bg-white hover:bg-primary-main ease-in-out duration-200	">
      <Tooltip direction="bottom" bgColor="bg-gray-200" message={'Create slack report'}>
        <GoReport size={'17px'} />
      </Tooltip>
    </div>
  );
};

export default ReportIcon;
