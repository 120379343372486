import { EllipsisVerticalIcon, PencilSquareIcon } from '@heroicons/react/24/solid';
import { useEffect, useMemo, useState } from 'react';
import AddUpdate from './AddUpdate';
import { getLeadsQuotes, getSingleLead } from '../../../api/requests';
import { Lead } from '../../../models/lead/Lead';
import { Link, useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import Loader from '../../../components/Loader';
import {
  capitalizeSentence,
  getFormattedLeadSource,
  getPhoneNumberFromShortCodeFormat,
  getVehicleProtectionFromKey
} from '../../../utils/miscFunctions';
import useUserAccount from '../../../hooks/userAccountHook';
import { User } from '../../../models/User';
import useRoute from '../../../hooks/routesHook';
import EditLead from './EditLead';
import useModal from '../../../hooks/modalHook';
import BackButton from '../../../components/BackButton';
import AssignLead from './AssignLead';
import PopOnMe from '../../../components/elements/poponme';
import CreateTask from './CreateTask';
import UpdateTaskModal from './UpdateTask';
import PolicyStatus from '../../../components/elements/PolicyStatus';
import Stepper from '../../../components/ActivityStepper';
import GTable from '../../../components/elements/GTable';
import { SUCCESS_PAYMENT_STATUS_COLOR } from '../../../resources/constants';
import TaskChecklistStrength from '../../../components/TaskChecklistStrength';
import ChecklistStrength from '../../../components/CheckListStrength';
import { ILeadTask } from '../../../models/lead/ILead';
import TabNavItem from '../../../components/tabs/TabNavItem';
import TabContent from '../../../components/tabs/TabContent';
import { ITask } from '../../../resources/interfaces';
import LeadTagStatus from './LeadTagStatus';
import QuoteIframe from '../../../components/dashboard/quoteWidget/QuoteWidget';
import { Quote } from '../../../models/quote/Quote';
import { toast } from 'react-toastify';
import { Policy } from '../../../models/policy/Policy';
import usePolicy from '../../../hooks/policyHook';
import { ITableRowAction } from '../../../components/elements/GTable/GTable';

const LeadDetailsView = () => {
  const [lead, setLead] = useState<Lead>();
  const [quote, setQuote] = useState<[]>();
  const [loading, setLoading] = useState<boolean>(false);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState('tasks');
  const [showUpdates, setShowUpdates] = useState<boolean>(false);
  const { getProfile } = useUserAccount();
  const { getDashboardBase } = useRoute();
  const { ModalComponent, updateModalStates, closeModal } = useModal();
  const { id } = useParams();
  const navigate = useNavigate();
  const user = new User(getProfile());
  const { generatePolicyFromQuote } = usePolicy();

  const [completedChecklist, setCompletedChecklist] = useState<string[]>([]);

  useEffect(() => {
    if (id) {
      setLoading(true);
      fetchLead(id);
      fetchQuote(id)
    }
  }, [id]);

  useEffect(() => {
    id && fetchLead(id);
  }, [refresh]);

  useEffect(() => {
    if (lead) {
      setCompletedChecklist(
        lead.tasks
          ? lead?.tasks
            ?.filter(
              (task: ILeadTask) => task.completed && task.type && options?.includes(task?.type)
            )
            ?.map((tast: ILeadTask) => tast.type ?? '')
          : []
      );
    }
  }, [lead]);

  const fetchLead = (id: string) => {
    getSingleLead(id)
      .then((response) => {
        setLead(new Lead(response.data));
      })
      .catch(() => { })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchQuote = (id: string) => {
    getLeadsQuotes(id)
      .then((response) => {
        setQuote(response.data);
      })
      .catch(() => { })
      .finally(() => {
        setLoading(false);
      });
  };

  const editLead = (section: string) => {
    lead &&
      updateModalStates({
        show: true,
        size: '2xl',
        children: (
          <EditLead
            lead={lead}
            onProceed={() => {
              setRefresh(true); 
              closeModal();
              fetchLead(lead.id)
            }}
            onClose={closeModal}
            section={section}
          />
        ),
        showCloseButton: false,
        classes: '!p-0 r-[5px]'
      });
  };

  const assignLeadToAdmin = () => {
    updateModalStates({
      show: true,
      size: 'xl',
      children: (
        <AssignLead
          onProceed={() => {
            closeModal();
            if (id) fetchLead(id);
          }}
          onClose={closeModal}
          leadData={lead}
        />
      ),
      showTitleSection: false,
      classes: '!p-0 r-[5px]'
    });
  };

  const showCreateTaskModal = (task?: ITask, isEdit = false, selectedoption?:string) => {
    updateModalStates({
      show: true,
      size: 'xl',
      children: (
        <CreateTask
          onProceed={() => {
            if (id) fetchLead(id);
            closeModal();
          }}
          onClose={closeModal}
          id={id}
          isEdit={isEdit}
          task={task}
          type={'LEAD'}
          selectedOption={selectedoption}
          />
      ),
      showTitleSection: false,
      classes: '!p-0 r-[5px]'
    });
  };

  const updateTask = (task?: ITask, isEdit = true) => {
    updateModalStates({
      show: true,
      size: 'xl',
      children: (
        <CreateTask
          onProceed={() => {
            if (id) fetchLead(id);
            closeModal();
          }}
          onClose={closeModal}
          id={id}
          isEdit={isEdit}
          task={task}
          type={'LEAD'} />
      ),
      showTitleSection: false,
      classes: '!p-0 r-[5px]'
    });
  };

  const addQuote = () => {
    lead&&
    updateModalStates({
      show: true,
      size: 'lg',
      children: (
          <QuoteIframe
            onProceed={() => {
              if (id) fetchQuote(id);
              closeModal();
            }}
          onClose={closeModal}
          signup={lead}
          />
      ),
      static: true
    });
  };

  const getActions = () => {
    let actions = [
      {
        label: 'Re(assign) signup',
        callback: () => assignLeadToAdmin()
      },
      {
        label: 'Create task',
        callback: () => showCreateTaskModal()
      },

      {
        label: 'View / Add update',
        callback: () => setShowUpdates(true)
      },
      {
        label: 'Get a quote',
        callback: () => addQuote()
      }
    ];

    return actions;
  };

  const getTableRowActions = (data: any) => {
    let actions = [
      {
        label: 'Update task',
        callback: () => updateTask(data, true)
      }
    ];

    return actions;
  };
  const handleCreatePolicy = (quote: Quote) => {
    updateModalStates({
      show: true,
      size: 'md',
      children: <Loader message="Just a sec..." size="2xl" />
    });

    if (!quote.phone) {
      toast.error('Phone number is required to create a policy from quote.', {
        autoClose: 3000
      });
      closeModal();
      return;
    }

    generatePolicyFromQuote(quote.id)
      .then((policy: Policy) => {
        if (policy) {
          toast.success(
            'Policy created successfully from quote. Proceed to provide policy details.',
            {
              autoClose: 5000
            }
          );
          navigate(`${getDashboardBase()}/policies/${policy.ref}/edit`, { replace: true });
        }
      })
      .catch((error: any) => {
        closeModal();
        toast.error(error.message ?? 'Failed to create policy from quote. Please try again later', {
          autoClose: 3000
        });
      });
  };
  
  const getQuoteTableRowActions = (data: any): ITableRowAction[] => {
    const actions = [
      {
        label: 'View',
        callback: (quote: Quote) => {
          navigate(`${getDashboardBase()}/quotes/${quote.ref}`, { replace: true });
        },
        data: data
      }
    ];

    if (user.isAdmin()) {
      actions.push({
        label: 'Create policy from quote',
        callback: (quote: Quote) => {
          handleCreatePolicy(quote);
        },
        data: data
      });
    }
    return actions;
  };

  const getStatus = (status: string) => {
    return status ? (
      <div
        className={`text-xs  w-fit px-5 flex items-center justify-center rounded ${SUCCESS_PAYMENT_STATUS_COLOR}`}>
        Done
      </div>
    ) : (
      <div className="text-xs  w-fit px-5 flex items-center justify-center rounded bg-gray-500 text-white">
        TO DO
      </div>
    );
  };

  const taskColumns = useMemo(
    () => [
      {
        Header: 'M',
        columns: [
          {
            Header: 'Name',
            accessor: 'name',
            Cell: (row: any) => {
              return <span>{row.cell.value ? capitalizeSentence(row.cell.value) : 'N/A'}</span>;
            }
          },

          {
            Header: 'Description',
            accessor: 'description',
            Cell: (row: any) => {
              return <span>{row.cell.value ?? 'N/A'}</span>;
            }
          },
          {
            Header: 'Type',
            accessor: 'type',
            Cell: (row: any) => {
              return <span>{row.cell.value ?? 'N/A'}</span>;
            }
          },
          {
            Header: 'Assigned To',
            accessor: 'assignedTo.firstName',
            Cell: (row: any) => {
              return (
                <span>
                  {row.cell.value
                    ? capitalizeSentence(
                      row.row.original.assignedTo.firstName +
                      ' ' +
                      row.row.original.assignedTo.lastName
                    )
                    : 'N/A'}
                </span>
              );
            }
          },
          {
            Header: 'Status',
            accessor: 'completed',
            Cell: (row: any) => {
              return getStatus(row.cell.value);
            }
          },
          {
            Header: 'Due date',
            accessor: 'dueOn',
            Cell: (row: any) => {
              return (
                <span>
                  {row.cell.value ? moment(row.cell.value).format('MMM Do YY, h:mm a') : 'N/A'}
                </span>
              );
            }
          },
          {
            id: 'Action',
            Cell: (row: any) => {
              return (
                <div className="cursor-pointer flex justify-center">
                  {getTableRowActions(row.row.original).length > 0 && (
                    <PopOnMe actions={getTableRowActions(row.row.original)}>
                      <EllipsisVerticalIcon className="h-4 w-4" />
                    </PopOnMe>
                  )}
                </div>
              );
            }
          }
        ]
      }
    ],
    []
  );

  const quotesColumns = useMemo(
    () => [
      {
        Header: 'M',
        columns: [
          {
            Header: 'Name',
            accessor: 'firstName',
            Cell: (row: any) => {
              return !row.row.original.firstName && !row.row.original.lastName ? (
                <span>N/A</span>
              ) : (
                <span>
                  {row.row.original.firstName} {row.row.original.lastName}
                </span>
              );
            }
          },
          {
            Header: 'Source',
            accessor: 'source',
            Cell: (row: any) => {
              return <span className="">{row.cell.value ? getFormattedLeadSource(row.cell.value) : 'N/A'}</span>;
            }
          },
          {
            Header: 'Phone',
            accessor: 'phone',
            Cell: (row: any) => {
              return (
                <span>
                  {row.cell.value ? getPhoneNumberFromShortCodeFormat(row.cell.value) : 'N/A'}
                </span>
              );
            }
          },
          {
            Header: 'Protection Type',
            accessor: 'protectionType',
            Cell: (row: any) => {
              return (
                <span>
                  {' '}
                  {row.cell.value ? (
                    <>{getVehicleProtectionFromKey(row.cell.value)?.title}</>
                  ) : (
                    'N/A'
                  )}
                </span>
              );
            }
          },
          {
            Header: 'Date',
            accessor: 'quotePlan',
            Cell: (row: any) => {
              return (
                <span>
                  {row.cell.value
                    ? moment(row.cell.value.createdOn).format('MMMM Do YYYY, h:mm:ss a')
                    : 'N/A'}
                </span>
              );
            }
          },
          {
            id: 'Action',
            Cell: (row: any) => {
              return (
                <div className="cursor-pointer flex justify-center">
                  <PopOnMe actions={getQuoteTableRowActions(row.row.original)}>
                    <EllipsisVerticalIcon className="h-4 w-4" />
                  </PopOnMe>
                </div>
              );
            }
          }
        ]
      }
    ],
    []
  );

  const options = [
    'Intro call',
    'Attempt to covert 1',
    'Attempt to convert 2',
    'Share First Digital Content ',
    'Share Second Digital Content',
    'Attempt to covert 3',
    'Attempt to convert 4',
    'Final Attempt to convert 5'
  ];

  return (
    <>
      {loading && (
        <div className="h-screen flex items-center justify-center bg-white">
          <Loader message={'Fetching lead Please wait...'} brollyLoader />
        </div>
      )}

      {!loading && lead && (
        <div className="flex flex-col w-full bg-white text-sm p-5">
          {/* Top Section */}
          <div className=" flex flex-col md:flex-row justify-between md:items-center gap-5 md:gap-0">
            <div className="flex flex-col md:flex-row gap-5 md:items-center">
              <div className="flex items-center gap-8 ">
                <Link to={`${getDashboardBase()}/signups`}>
                  <BackButton />
                </Link>

                <h2 className="font-semibold text-lg">
                  {lead?.firstname} {lead?.lastname}
                </h2>
              </div>

              <div className="flex items-center gap-5">
                <PolicyStatus
                  status={capitalizeSentence(lead?.leadStatus ?? 'N/A')}
                  classes="rounded-full px-2  w-fit"
                />

                <div className="bg-primary-main min-w-fit py-1 px-2 rounded-full text-sm">
                  {lead?.leadSource
                    ? capitalizeSentence(getFormattedLeadSource(lead?.leadSource))
                    : 'N/A'}
                </div>

                 <LeadTagStatus status={lead?.tag ? capitalizeSentence(lead?.tag) : 'N/A'} classes="rounded-full px-2 w-[120px]" />
              </div>
            </div>

            <div className="flex  items-center gap-5 mb-2 min-[400px]:mb-0">
              <span className="font-bold">
                {lead?.owner ? `${lead?.owner?.firstName} ${lead?.owner?.lastName}` : ''}
              </span>

              {getActions().length > 0 && (
                <PopOnMe actions={getActions()}>
                  <div className="cursor-pointer py-1 px-2  border border-primary-main rounded">
                    Actions
                  </div>
                </PopOnMe>
              )}
            </div>
          </div>

          {/* Details section */}
          <div className="grid grid-cols-1 md:grid-cols-5 gap-5 py-10">
            {/* Information section */}
            <div className="flex flex-col gap-5 md:col-span-2">
              <div className="">
                <div className="flex items-center justify-between">
                  <span className="font-semibold">Contact Information</span>
                  <PencilSquareIcon
                    className="h-4 w-4 cursor-pointer"
                    onClick={() => editLead('CONTACT_INFO')}
                  />
                </div>

                <div className=" flex flex-col gap-2 border border-gray-300 p-2 rounded-lg">
                  <LeadDetailsItem name="First Name" value={lead.firstname ?? 'N/A'} />
                  <LeadDetailsItem name="Last Name" value={lead.lastname ?? 'N/A'} />
                  <LeadDetailsItem
                    name="Mobile Number"
                    value={getPhoneNumberFromShortCodeFormat(lead.phone) ?? 'N/A'}
                  />
                  {/* <LeadDetailsItem name="WhatsApp Number" value={getPhoneNumberFromShortCodeFormat(lead.phone )?? 'N/A'} /> */}
                  <LeadDetailsItem name="Email Address" value={lead?.email ?? 'N/A'} />
                </div>
              </div>
              <div className="">
                <div className="flex items-center justify-between">
                  <span className="font-semibold">Insurance Information</span>
                  <PencilSquareIcon
                    className="h-4 w-4 cursor-pointer"
                    onClick={() => editLead('INSURANCE_INFO')}
                  />
                </div>

                <div className=" flex flex-col gap-2 border border-gray-300 p-2 rounded-lg">
                  <LeadDetailsItem name="Current Insurer" value={lead.currentInsurer ?? 'N/A'} />
                  <LeadDetailsItem name="Insurance Status" value={lead.leadStatus ?? 'N/A'} />
                  <LeadDetailsItem
                    name="Protection Type"
                    value={getVehicleProtectionFromKey(lead.insuranceType ?? '')?.title ?? 'N/A'}
                  />
                  <LeadDetailsItem
                    name="Expiry Date"
                    value={lead.expiryDate ? moment(lead.expiryDate).format('l') : 'N/A'}
                  />
                  <LeadDetailsItem
                    name="Vehicle Number"
                    value={lead.registrationNumber ? lead.registrationNumber : 'N/A'}
                  />
                  <LeadDetailsItem name="Tag" value={lead.tag ? capitalizeSentence(lead.tag):  'N/A'} />
                </div>
              </div>
            </div>

            {/* Updates section */}
            <div className="border-x border-primary-border md:col-span-2">
              <span className="font-semibold pl-4"> Activity feed</span>

              <div className=" py-5 overflow-y-auto h-full md:max-h-[20rem]">
                {lead?.activityStreams && lead?.activityStreams?.length > 0 ? (
                  <div className="pl-4 ">
                    <Stepper
                      activityStreams={lead.activityStreams}
                      orientation="vertical"
                      currentStep={0}
                    />
                  </div>
                ) : (
                  <div className="w-full h-full flex items-center justify-center font-semibold">
                    No activity
                  </div>
                )}
              </div>
            </div>

            {/* Task progress tracker */}
            <div className="col-span-1">
              <ChecklistStrength
                options={options}
                title={'Signup Checklist'}
                completedOptions={completedChecklist}
                onOptionChange={(option: string, remove?: boolean) => {
                  if (remove) {
                    setCompletedChecklist(completedChecklist.filter((item) => item !== option));
                  } else {
                    setCompletedChecklist([...completedChecklist, option]);
                  }
                }}
                disableClick={true}
                onClick={(option) => {
                  let task: any;

                  showCreateTaskModal(task, false, option);
                }}
              />
            </div>
          </div>

          <div className="flex items-center gap-x-10 mt-10">
            <TabNavItem
              id="tasks"
              title="Tasks"
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />
              <TabNavItem
              id="quotes"
              title="Quotes"
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />
            {/* <TabNavItem
              id="Tab"
              title="tab"
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            /> */}
          </div>

          <div className="py-5">
            <TabContent id={'tasks'} activeTab={activeTab}>
              <h2 className="font-semibold text-2xl">Tasks</h2>
              <div className="">
                {lead.tasks && (
                  <GTable
                    columns={taskColumns}
                    data={lead.tasks}
                    classes="h-fit"
                    showSearch={false}
                    tableType={'task'}
                    stickyHeader
                  />
                )}
              </div>
            </TabContent>
            <TabContent id={'quotes'} activeTab={activeTab}>
              <h2 className="font-semibold text-2xl">Quotes</h2>
              <div className="">
                {quote && (
                  <GTable
                    columns={quotesColumns}
                    data={quote}
                    classes="h-fit"
                    showSearch={false}
                    tableType={'quote'}
                    stickyHeader
                  />
                )}
              </div>
            </TabContent>
            {/* 
            <TabContent id={'tab'} activeTab={activeTab}>
              <h2 className="font-semibold text-2xl">Tasks Tab</h2>
              <div className="">
                {lead.tasks && (
                  <GTable
                    columns={taskColumns}
                    data={lead.tasks}
                    classes="h-fit"
                    showSearch={false}
                    tableType={'task'}
                    stickyHeader
                  />
                )}
              </div>
            </TabContent> */}
            
          </div>

          {showUpdates && (
            <AddUpdate
              show={showUpdates}
              onClose={() => {
                setShowUpdates(false);
              }}
              leadID={lead && lead.id}
              onProceed={() => {}}
              updates={lead?.leadUpdates ?? []}
              onLeadUpdated={(lead: Lead) => setLead(lead)}
            />
          )}
        </div>
      )}
      <ModalComponent />
    </>
  );
};

type LeadDetailsItemProps = {
  name: string;
  value: string;
};

const LeadDetailsItem = ({ name, value }: LeadDetailsItemProps) => {
  return (
    <div className="grid grid-cols-2 justify-start">
      <span className="text-gray-600">{name}</span>
      <span className="truncate font-medium">{value}</span>
    </div>
  );
};

export default LeadDetailsView;
