import { Transition } from '@headlessui/react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useModal from '../../../../hooks/modalHook';
import useRoute from '../../../../hooks/routesHook';
import useUserAccount from '../../../../hooks/userAccountHook';
import { User } from '../../../../models/User';
import InsurerDetails from './InsurerDetails';
import InsuranceServices from './InsuranceServices';
import Lottie from 'react-lottie';
import loadingData from '../../../../resources/lotties/onboarding-loader.json';
import Button from '../../../form-control/Button';
import InviteInsurerUsers from './InviteInsurerUsers';
import { getTeamProfile } from '../../../../api/requests';
import { Team } from '../../../../models/team/Team';


interface IInsurerOnboardingStep {
  progress: number;
  component: JSX.Element;
  key: string;
}

function InsurerOnboarding(): JSX.Element {
  const [progress, setProgress] = useState<number>(1);
  const { updateModalStates, ModalComponent } = useModal()
  const { getProfile, updateProfile, user } = useUserAccount();
  const { getDashboardBase } = useRoute();
  const navigate = useNavigate();
  const [settingUp, setSettingUp] = useState<boolean>(false);
  const [storeIsReady, setStoreIsReady] = useState<boolean>(false);
  const [team, setTeam] = useState<Team>();

  const profile = getProfile()

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  const onboardingSteps: IInsurerOnboardingStep[] = [
    {
      progress: 1,
      component: <InsurerDetails
        proceed={() => {
          proceed();
        }}

      />,
      key: "insurer-details"
    },
    {
      progress: 2,
      component: <InsuranceServices
        proceed={() => {
          proceed();
        }}
        onBack={goBack}
      />,
      key: "insurer-services"
    },
    {
      progress: 3,
      component: <InviteInsurerUsers
        proceed={() => {
          generateStore();
        }}
        onBack={goBack}
        team={team}
      />,
      key: "invite-users"
    },
  ]

  useEffect(() => {
    getTeamProfile()
      .then((response: any) => {
        setTeam(new Team(response?.data));
        const _user = { ...profile, team: response?.data };
        updateProfile(_user);
      })
  }, [])


  const proceed = () => {
    setProgress(progress + 1);
  }

  function goBack() {
    setProgress(progress - 1);
  }

  const generateStore = () => {
    setSettingUp(true);

    setTimeout(() => {
      setStoreIsReady(true);
    }, 7000)
  }

  const viewStore = () => {
    setSettingUp(false);
    // @ts-ignore
    updateProfile({ ...profile, team: { ...profile.team, setupCompleted: true } })
    navigate(`${getDashboardBase()}/store/preview`);
  }


  console.log("Insurer:", profile)
  return (
    <div className='flex items-center flex-col py-6 text-dark'>
      {settingUp && (
        <>
          <div className='flex flex-col justify-center items-center absolute inset-0 z-10 bg-black/[0.8]'>
            {storeIsReady ? (
              <>
                <h3 className='font-semibold font-poppins w-full text-center py-6 z-10 text-white text-[30px]'>Your store is ready</h3>
                <Button
                  label='View Store'
                  className='bg-primary-main rounded-[8px] border-none font-poppins px-8 !font-bold'
                  onClick={viewStore}
                />
              </>
            ) : (
              <>
                <Lottie
                  options={defaultOptions}
                  height={400}
                  width={400}
                />
                <h3 className='font-semibold font-poppins w-full text-center py-6 z-10 text-white text-[30px]'>One moment {user.firstName} <br /> While I get your store set up...</h3>
              </>
            )}

          </div>
        </>

      )}

      {onboardingSteps.map((onboardingStep: IInsurerOnboardingStep, index: number) => (
        <Transition
          as={"div"}
          show={progress === (index + 1)}
          enter="transform transition ease-out duration-200 delay-200"
          enterFrom="opacity-0 -translate-x-6"
          enterTo="opacity-100 translate-x-0"
          leave="transform transition ease-in duration-200"
          leaveFrom="opacity-100 translate-x-0"
          leaveTo="opacity-0 -translate-x-6"
          key={index}
          className="w-full md:w-[70%]"
        >
          {onboardingStep.component}
        </Transition>
      ))}
      <ModalComponent />

    </div>
  );
}

export default InsurerOnboarding;
