import { useEffect, useState } from 'react';
import SelectMenu from '../../../components/form-control/SelectMenu';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { XMarkIcon } from '@heroicons/react/24/solid';
import Button from '../../../components/form-control/Button';
import { assignLead, getAllAdminAccounts } from '../../../api/requests';
import SelectDropdown, {
  ISelectDropdownItem
} from '../../../components/form-control/SelectDropdown';
import { getFormattedAccessRight } from '../../../utils/miscFunctions';

export interface IAssignLead {
  adminEmail: string;
}

const AssignLead = ({
  onProceed,
  onClose,
  leadData
}: {
  onProceed: () => void;
  onClose: () => void;
  leadData: any;
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [admins, setAdmins] = useState<any>([]);

  const submitHandler = (values: any) => {
    const id: string = leadData?.id;
    setSubmitting(true);
    assignLead(id, values.adminEmail)
      .then((response: any) => {
        toast.success(response?.message ?? `Lead assigned to admin successfully`, {
          autoClose: 5000
        });
        onProceed();
      })
      .catch((error: any) => {
        toast.error(error.description);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  useEffect(() => {
    getAllAdmins();
  }, []);

  const getAllAdmins = (page = 0) => {
    getAllAdminAccounts()
      .then((response: any) => {
        const _admins = response?.map((admin: any) => ({
          name: `${admin.firstName} ${admin.lastName} (${getFormattedAccessRight(
            admin.accessRight
          )})`,
          value: admin.email
        }));
        setAdmins(_admins);
      })
      .catch((error: Error) => { });
  };
  
  return (
    <div className="bg-white">
      {/* <div className="p-[1rem] bg-primary-main flex justify-between items-center">
        <div className="flex items-center justify-center gap-4">
          <div className="bg-white h-[45px] w-[45px] flex items-center justify-center rounded-full">
            <svg
              width="18"
              height="21"
              viewBox="0 0 18 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M9 15.3999L13 11.3999L11.6 9.9999L10 11.5499V7.3999H8V11.5499L6.4 9.9999L5 11.3999L9 15.3999ZM2 20.3999C1.45 20.3999 0.979 20.2042 0.587 19.8129C0.195667 19.4209 0 18.9499 0 18.3999V4.3999C0 3.8499 0.195667 3.3789 0.587 2.9869C0.979 2.59557 1.45 2.3999 2 2.3999H6.2C6.41667 1.7999 6.77933 1.31657 7.288 0.949902C7.796 0.583236 8.36667 0.399902 9 0.399902C9.63333 0.399902 10.2043 0.583236 10.713 0.949902C11.221 1.31657 11.5833 1.7999 11.8 2.3999H16C16.55 2.3999 17.021 2.59557 17.413 2.9869C17.8043 3.3789 18 3.8499 18 4.3999V18.3999C18 18.9499 17.8043 19.4209 17.413 19.8129C17.021 20.2042 16.55 20.3999 16 20.3999H2ZM9 3.6499C9.21667 3.6499 9.396 3.5789 9.538 3.4369C9.67933 3.29557 9.75 3.11657 9.75 2.8999C9.75 2.68324 9.67933 2.5039 9.538 2.3619C9.396 2.22057 9.21667 2.1499 9 2.1499C8.78333 2.1499 8.60433 2.22057 8.463 2.3619C8.321 2.5039 8.25 2.68324 8.25 2.8999C8.25 3.11657 8.321 3.29557 8.463 3.4369C8.60433 3.5789 8.78333 3.6499 9 3.6499Z"
                fill="black"
              />
            </svg>
          </div>
          <div className="">
            <h2 className="text-lg font-medium">Assign Lead</h2>
          </div>
        </div>
        <XMarkIcon onClick={onClose} width={'20px'} height={'20px'} className="cursor-pointer" />
      </div> */}

      <div className="flex items-center px-5 mt-[26px]">
        <span>This lead will be assigned to the admin you select</span>
      </div>

      <div className="p-[1rem] w-full">
        <Formik
          initialValues={{
            adminEmail: ''
          }}
          validateOnChange={false}
          validateOnBlur={false}
          validationSchema={Yup.object({})}
          onSubmit={(values) => {
            submitHandler(values);
          }}>
          {({ values, errors, handleSubmit, handleChange, setFieldValue, handleBlur, touched }) => (
            <form className="space-y-4" onSubmit={handleSubmit}>
              <div className="">
                <SelectDropdown
                  label="Admins"
                  name="adminEmail"
                  list={admins}
                  listSelectedValue={values.adminEmail}
                  onChange={(val: ISelectDropdownItem | null) => {
                    setFieldValue('adminEmail', val);
                  }}
                  search
                  searchPlaceHolder='Search by name'
                  onBlur={handleBlur}
                  placeholder="Select an admin"
                  error={touched.adminEmail ? errors.adminEmail : undefined}
                  helperText={touched?.adminEmail ? errors.adminEmail : undefined}
                  required
                />
              </div>

              <div className="flex justify-end">
                <div className="mr-5">
                  <Button
                    type="button"
                    label="Cancel"
                    className="w-fit px-6 text-[14px] rounded border-primary-border"
                  // loading={submitting}
                  // disabled={submitting}
                  />
                </div>
                <div className="">
                  <Button
                    type="submit"
                    label="Assign"
                    bgColor="primary"
                    className="w-fit px-6 text-[14px] rounded"
                    loading={submitting}
                    disabled={submitting}
                  />
                </div>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default AssignLead;
