import moment from 'moment';
import { VEHICLE_USAGES } from '../../resources/constants';
import {
  INSTALLMENT_TYPE,
  INSTALLMENT_TYPES,
  LEAD_SOURCE,
  PAYMENT_STATUS_STATES,
  POLICY_DOCUMENT_TYPES,
  POLICY_REVIEW_STATES,
  TEAM_TYPES
} from '../../resources/enums';
import { ICustomerPayments, IPolicyDocument, ITask } from '../../resources/interfaces';
import { getPolicyDocumentTitleFromType } from '../../utils/miscFunctions';
import { Applicant } from '../applicant/Applicant';
import { Community } from '../community/Community';
import { Discount } from '../discount/Discount';
import { IDiscount } from '../discount/IDiscount';
import { Insurer } from '../insurer/Insurer';
import { Quote } from '../quote/Quote';
import { User } from '../User';
import UserAccount, { IUserAccount } from '../UserAccount';
import { IVehicle } from '../vehicle/IVehicle';
import { Vehicle } from '../vehicle/Vehicle';
import {
  IPolicy,
  IPolicyActivityStreams,
  IPolicyMetaData,
  IPolicyOwner,
  IPolicyReferee,
  IPolicyUpdate
} from './IPolicy';


export class Policy {

  oldPolicyNumber?:string; 
  renewedPolicyNumber?:string;
  renewed?:boolean; 

  ref: string;
  vehicle: Vehicle | null;
  createdOn: Date | undefined;
  startDate: Date | undefined;
  startsOn: Date | undefined;
  expiresOn: Date | undefined;
  outrightPremium: boolean;
  initialDeposit: number | undefined;
  monthlyInstallment: boolean;
  noOfInstallments: string | undefined;
  vehicleInsuranceId: string | undefined;
  countryInfoId: string;
  previouslyDeclined: boolean | undefined;
  declinedInsuranceDetails: string | undefined;
  previouslyIssuedClaim: boolean | undefined;
  previousInsuranceClaims: string | undefined;
  region: string;
  installmentType: string;
  renewalNotice: string;
  quote: Quote | undefined;
  applicant: Applicant;
  active: boolean;
  ended: boolean;
  discount: Discount | undefined;
  discountedAmount: number | undefined;
  documents: IPolicyDocument[] | undefined;
  reviewStage: POLICY_REVIEW_STATES | undefined;
  payments: ICustomerPayments[] | undefined;
  insurer: Insurer | undefined;
  manager: User | undefined;
  updates: IPolicyUpdate[];
  referee: IPolicyReferee;
  community: Community | undefined;
  insurerPolicyNumber: string | undefined;
  midReference: string | undefined;
  midStickerNumber: string | undefined;
  account: UserAccount | undefined;
  protectionType?: string;
  owner: IPolicyOwner | undefined;
  totalPaid: number;
  balanceRemaining: number;
  metaData: IPolicyMetaData | undefined;
  activityStreams?: IPolicyActivityStreams[];
  totalPaymentObjects: number;
  rideSharePeriod: number | undefined;
  liability?: boolean;
  liabilityDetails?: string;
  liabilityDebt?: string;
  liabilityModeOfPayment?: string;
  tasks: ITask[];
  constructor(Imodel: IPolicy) {
    this.oldPolicyNumber = Imodel.oldPolicyNumber;
    this.renewedPolicyNumber = Imodel.renewedPolicyNumber; 
    this.renewed = Imodel.renewed;

    this.ref = Imodel.policyNumber;
    this.vehicle = Imodel.vehicle
      ? new Vehicle({
          ...Imodel.vehicle,
          region: Imodel.region,
          alterationDetails: Imodel.alterationDetails,
          repairState: Imodel.repairState,
          driverComplications: Imodel.driverComplications,
          driverHasComplications: Imodel.driverHasComplications,
          issuedValue: Imodel.quote?.insuredValue ?? Imodel.vehicle?.issuedValue,
          hasInterestedParties:
            Imodel?.hasInterestedParties ?? Imodel.vehicle?.hasInterestedParties,
          interestedParties: Imodel?.interestedParties ?? Imodel.vehicle?.interestedParties
        } as IVehicle)
      : null;
    this.createdOn = Imodel.createdOn ? new Date(Imodel.createdOn) : new Date();
    this.startsOn = Imodel.startsOn ? new Date(Imodel.startsOn) : undefined;
    this.expiresOn = Imodel.expiresOn ? new Date(Imodel.expiresOn) : undefined;
    this.outrightPremium = Imodel.outrightPremium ?? false;
    this.initialDeposit = Imodel.initialDeposit ?? undefined;
    this.monthlyInstallment = Imodel.monthlyInstallment ?? false;
    this.noOfInstallments = Imodel.noOfInstallments ?? undefined;
    this.vehicleInsuranceId = Imodel.vehicleInsuranceId ?? undefined;
    this.countryInfoId = Imodel.countryInfoId ?? '';
    this.previouslyDeclined = Imodel.previouslyDeclinedInsurance ?? undefined;
    this.declinedInsuranceDetails = Imodel.declinedInsuranceDetails;
    this.previouslyIssuedClaim = Imodel.hasPreviousInsuranceClaims ?? undefined;
    this.previousInsuranceClaims = Imodel.previousInsuranceClaims ?? undefined;
    this.region = Imodel.region ?? '';
    this.installmentType = Imodel.installmentType;
    this.renewalNotice = Imodel.renewalNotice ?? '';
    this.quote = Imodel.quote ? new Quote(Imodel?.quote) : undefined;
    this.active = Imodel.active ?? false;
    this.ended = Imodel.expired ?? false;
    this.reviewStage = Imodel.reviewStage;
    this.payments = Imodel.payments ?? [];
    this.discount = Imodel.discount ? new Discount(Imodel.discount) : undefined;
    this.discountedAmount = this.quote ? this.quote?.quotePlan?.initialDeposit : undefined;
    this.documents = Imodel.documents?.map((document: IPolicyDocument) => ({
      id: document.id,
      title: getPolicyDocumentTitleFromType(document.docType),
      docType: document.docType,
      docURL: document.docURL
    }));

    this.applicant = new Applicant({
      address: Imodel.customer?.address ?? Imodel.address,
      occupation: Imodel.customer?.occupation ?? Imodel.occupation,
      phoneNumber: Imodel.customer?.phone ?? Imodel?.phone,
      phoneVerified: Imodel.customer?.phoneVerified ?? Imodel?.phoneVerified,
      otherPhone: Imodel.customer?.otherPhone ?? Imodel?.otherPhone,
      otherPhoneVerified: Imodel.customer?.otherPhoneVerified ?? Imodel?.otherPhoneVerified,
      customerId: Imodel.customer?.id,
      email: Imodel.customer?.email ?? Imodel.email,
      emailVerified: Imodel.customer?.emailVerified,
      firstName: Imodel.customer?.firstName,
      lastName: Imodel.customer?.lastName,
      hasDisease: Imodel.hasDisease,
      diseaseOrComplications: Imodel.diseaseOrComplications,
      dateOfBirth: Imodel.customer?.dateOfBirth,
      idType: Imodel.customer?.idType ?? Imodel.idType,
      idNumber: Imodel.customer?.idNumber ?? Imodel.idNumber,
      idVerified: Imodel.customer?.idVerified ?? Imodel.idVerified,
      idExpiryDate: Imodel.customer?.idExpiry ?? Imodel.idExpiryDate,
      employerName: Imodel.employerName ?? Imodel.customer?.employerName,
      employerAddress: Imodel.customer?.employerAddress ?? Imodel.employerAddress,
      employerPhoneNumber: Imodel.customer?.employerPhone ?? Imodel.employerContact,
      monthlyIncome: Imodel.customer?.monthlyIncomeBand,
      socialMedia: Imodel.socialMedia,
      account: Imodel.account,
      rideSharePeriod: Imodel.rideSharePeriod,
      liability: Imodel.liability,
      liabilityDebt: Imodel.liabilityDebt,
      liabilityDetails: Imodel.liabilityDetails,
      liabilityModeOfPayment: Imodel.liabilityModeOfPayment
    });

    this.insurer = Imodel.insurer ? new Insurer(Imodel.insurer) : undefined;
    this.manager = Imodel.manager ? new User(Imodel.manager) : undefined;
    this.updates = Imodel.policyUpdates ?? [];
    this.referee = Imodel.referee ?? undefined;
    this.community = Imodel.community ? new Community(Imodel.community) : undefined;
    this.insurerPolicyNumber = Imodel.insurerPolicyNumber;
    this.midReference = Imodel.midReference;
    this.midStickerNumber = Imodel.midStickerNumber;
    this.account = Imodel.account ? new UserAccount(Imodel.account) : undefined;
    this.protectionType = Imodel.quote?.protectionType ?? undefined;
    this.tasks = Imodel?.tasks ?? [];
    this.owner = Imodel.owner ?? undefined;
    this.totalPaid = this.getTotalPaid() ?? 0;
    this.totalPaymentObjects = this.getTotalPaymentObjects();
    this.balanceRemaining = this.getBalanceRemaining();
    this.metaData = Imodel.metaData
      ? {
          ...Imodel.metaData,
          minutesToCompletion: Imodel?.metaData?.completedOn
            ? moment
                .duration(
                  moment(Imodel.metaData?.completedOn).diff(moment(Imodel?.metaData?.createdOn))
                )
                ?.asMinutes()
                ?.toFixed()
            : undefined,
          completedOn: Imodel?.metaData?.completedOn
            ? new Date(Imodel?.metaData?.completedOn)
            : undefined,
          completed: Imodel?.metaData?.completed
        }
      : undefined;
    this.activityStreams = Imodel.activityStreams;
  }

  isCommercial = (): boolean => {
    return (
      this.vehicle?.usage?.title === VEHICLE_USAGES?.HIRING_CAR?.title ||
      this.vehicle?.usage?.title === VEHICLE_USAGES?.HAILING_SERVICE?.title ||
      this.vehicle?.usage?.title === VEHICLE_USAGES?.TAXI?.title
    );
  };

  isFullPayment(): boolean {
    return this.installmentType === INSTALLMENT_TYPES.FULL_PAYMENT;
  }

  isThirdParty(): boolean {
    return this.quote?.protectionType?.title === 'Third Party';
  }

  isPersonalDetailsProvided(): boolean {
    let isAllValid = false;

    if (this.isFullPayment()) {
      if (this.applicant?.phoneNumber && this.applicant?.address && this.applicant?.occupation) {
        isAllValid = true;
      }
    } else {
      if (
        this.applicant?.phoneNumber &&
        this.applicant?.phoneVerified &&
        this.applicant?.address &&
        this.applicant?.occupation &&
        this.applicant?.dateOfBirth &&
        this.applicant?.idType &&
        this.applicant?.idNumber &&
        this.applicant?.idExpiryDate &&
        this.applicant?.employerName &&
        this.applicant?.employerAddress &&
        this.applicant?.employerPhoneNumber &&
        this.applicant?.monthlyIncome
      ) {
        isAllValid = true;
      }
    }

    return isAllValid;
  }

  isVehicleDetailsProvided(): boolean {
    let isAllValid = false;

    if (
      this.vehicle?.make &&
      this.vehicle?.model &&
      this.vehicle?.manufacturedYear &&
      this.vehicle?.chassisNumber &&
      // (this.vehicle?.goodRepairState || (!this.vehicle?.goodRepairState && this.vehicle?.repairState)) &&
      // (!this.vehicle?.vehicleAltered || (this.vehicle?.vehicleAltered && this.vehicle?.alterationDetails)) &&
      this.vehicle?.color &&
      this.vehicle?.type &&
      this.vehicle?.region &&
      this.vehicle?.ownerName &&
      this.vehicle?.usage &&
      this.vehicle?.maxSeat
    ) {
      isAllValid = true;
    }

    return isAllValid;
  }

  isOtherPartiesProvided(): boolean {
    let isAllValid = false;

    if (this.vehicle?.hasInterestedParties && this.vehicle?.interestedParties) {
      isAllValid = true;
    } else if (!this.vehicle?.hasInterestedParties) {
      isAllValid = true;
    }

    return isAllValid;
  }

  isMainDriverProvided(): boolean {
    let isAllValid = false;

    if (
      this.vehicle?.driver?.fullName &&
      (!this.vehicle?.driver?.hasDisease ||
        (this.vehicle?.driver?.hasDisease && this.vehicle?.driver?.diseaseOrComplications))
    ) {
      isAllValid = true;
    }

    return isAllValid;
  }

  isExtraInfoProvided(): boolean {
    let isAllValid = false;

    if (
      (!this.previouslyDeclined || (this.previouslyDeclined && this.declinedInsuranceDetails)) &&
      (!this.previouslyIssuedClaim || (this.previouslyIssuedClaim && this.previousInsuranceClaims))
    ) {
      isAllValid = true;
    }

    return isAllValid;
  }

  isPolicyDetailsProvided(): boolean {
    let isAllValid = false;

    if (
      (!this.previouslyDeclined || (this.previouslyDeclined && this.declinedInsuranceDetails)) &&
      (!this.previouslyIssuedClaim || (this.previouslyIssuedClaim && this.previousInsuranceClaims))
    ) {
      isAllValid = true;
    }

    return isAllValid;
  }

  isAboutPolicyDetailsProvided(): boolean {
    let isAllValid = false;

    if (
      this.quote?.quotePlan?.initialDeposit &&
      this.quote?.quotePlan?.monthlyInstallment &&
      this.quote?.quotePlan?.outRightPremium &&
      this.quote?.quotePlan?.totalInterest &&
      this.quote?.quotePlan?.totalPremium &&
      this.quote?.protectionType &&
      this.quote?.installmentType
    ) {
      isAllValid = true;
    }

    return isAllValid;
  }

  isAllDetailsProvided(): boolean {
    return (
      this.isPersonalDetailsProvided() &&
      this.isVehicleDetailsProvided() &&
      this.isOtherPartiesProvided() &&
      this.isMainDriverProvided() &&
      this.isExtraInfoProvided()
    );
  }

  getPaymentType = (): string => {
    return this.installmentType === INSTALLMENT_TYPE.FULL_PAYMENT
      ? 'Full Payment'
      : this.installmentType === INSTALLMENT_TYPE.WEEKLY_PAYMENT
      ? 'Weekly Installment'
      : 'Monthly Installment';
  };

  applyDiscount = (discount: IDiscount): void => {
    this.discount = new Discount(discount);

    this.discountedAmount = this.quote
      ? this.quote?.quotePlan?.initialDeposit - this.discount?.value
      : 0;
  };

  clearDiscount = (): void => {
    this.discount = undefined;

    this.discountedAmount = this.quote?.quotePlan?.initialDeposit;
  };

  getStatus = (): string => {
    let status = 'Inactive';

    if (this.active) {
      status = 'Active';
    }

    return status;
  };

  isApproved = (): boolean => {
    return this.reviewStage === POLICY_REVIEW_STATES.APPROVED;
  };

  isExpired = (): boolean => {
    return this.reviewStage === POLICY_REVIEW_STATES.EXPIRED;
  };

  isOnHold = (): boolean => {
    return this.reviewStage === POLICY_REVIEW_STATES.ON_HOLD;
  };

  isDueLater = (): boolean => {
    return this.reviewStage === POLICY_REVIEW_STATES.DUE_LATER;
  };

  isInApproval = (): boolean => {
    return this.reviewStage === POLICY_REVIEW_STATES.IN_APPROVAL;
  };

  isInReview = (): boolean => {
    return this.reviewStage === POLICY_REVIEW_STATES.IN_REVIEW;
  };

  isDeclined = (): boolean => {
    return this.reviewStage === POLICY_REVIEW_STATES.DECLINED;
  };

  getRenewalNotice(): IPolicyDocument | undefined {
    return this.documents?.find(
      (document: IPolicyDocument) => document.docType === POLICY_DOCUMENT_TYPES.RENEWAL_NOTICE
    );
  }

  getInsuranceCertificate(): IPolicyDocument | undefined {
    return this.documents?.find(
      (document: IPolicyDocument) =>
        document.docType === POLICY_DOCUMENT_TYPES.INSURANCE_CERTIFICATE
    );
  }

  getInsuranceSticker(): IPolicyDocument | undefined {
    return this.documents?.find(
      (document: IPolicyDocument) => document.docType === POLICY_DOCUMENT_TYPES.INSURANCE_STICKER
    );
  }

  getContractSummary(): IPolicyDocument | undefined {
    return this.documents?.find(
      (document: IPolicyDocument) => document.docType === POLICY_DOCUMENT_TYPES.CONTRACT_SUMMARY
    );
  }
  getRoadWorthy(): IPolicyDocument | undefined {
    return this.documents?.find(
      (document: IPolicyDocument) =>
        document.docType === POLICY_DOCUMENT_TYPES.ROADWORTHY_CERTIFICATE
    );
  }

  isAutoPolicy = (): boolean => {
    return this.vehicle !== (undefined || null);
  };

  isComprehensive = (): boolean => {
    return (
      this.protectionType === 'COMPREHENSIVE_100' ||
      this.protectionType === 'COMPREHENSIVE_90' ||
      this.protectionType === 'COMPREHENSIVE_85'
    );
  };

  getFirstUnpaidPayment(): ICustomerPayments | undefined {
    return this.payments?.find((payment: ICustomerPayments) => payment.paymentStatus !== 'SUCCESS');
  }

  isPaid(): boolean {
    if (this.isFullPayment()) {
      return this.getFirstUnpaidPayment() === undefined;
    } else {
      return this.payments?.[0]?.paymentStatus === 'SUCCESS';
    }
  }

  composerIsOwner(): boolean {
    return (
      `${this.applicant?.firstName?.toLowerCase()} ${this.applicant?.lastName?.toLowerCase()}` ===
      `${this.vehicle?.ownerName?.toLowerCase()}`
    );
  }

  isRideSharing = (): boolean => {
    return this.owner?.type === TEAM_TYPES.RIDE_SHARING;
  };

  isBolt = (): boolean => {
    return this.owner?.name === 'Bolt' || this?.quote?.source === LEAD_SOURCE.BOLT_APP;
  };

  getTotalPaid = (): number => {
    let totalPiad = 0;

    this.payments?.forEach((payment: ICustomerPayments) => {
      if (payment?.paymentStatus === PAYMENT_STATUS_STATES.SUCCESS) {
        totalPiad += payment?.amount ?? 0;
      }
    });
    return totalPiad;
  };

  getTotalPaymentObjects = (): number => {
    let totalPayments = 0;

    this.payments?.forEach((payment: ICustomerPayments) => {
      totalPayments += payment?.amount ?? 0;
    });
    return totalPayments;
  };

  getBalanceRemaining = (): number => {
    const totalPremium = this.quote?.quotePlan?.totalPremium ?? 0;
    return totalPremium > this.totalPaymentObjects
      ? totalPremium - this.totalPaid
      : this.totalPaymentObjects - this.totalPaid;
  };

  isWeeklyInstallment(): boolean {
    if (this.quote?.installmentType) {
      return (
        this.quote?.installmentType === 'TWELVE_WEEKS' ||
        this.quote.installmentType.includes('WEEK')
      );
    }

    return false;
  }

  isDailyInstallment(): boolean {
    if (this.quote?.installmentType) {
      return this.quote?.installmentType === 'DAILY' || this.quote.installmentType.includes('DAY');
    }
    return false;
  }

  isMonthlyInstallment(): boolean {
    if (this.quote?.installmentType) {
      return (
        this.quote?.installmentType === 'MONTHLY' || this.quote.installmentType.includes('MONTH')
      );
    }
    return false;
  }

  isInitialPaymentReceived(): boolean {
    return this.metaData?.firstPaymentReceived ? true : false;
  }

  isYango(): boolean {
    return this.owner?.name === 'Yango' || this?.quote?.source === LEAD_SOURCE.YANGO_APP;
  }
}
