import clsx from "clsx";
import { useEffect, useRef, useState } from "react";
import { ScrollToFormikError } from "../elements/ScrollToFormikError";

export const InputRadio = ({ label, name, options, onSelect, selected, error, helperText, id, disabled, inlineOptions = true, classes, required = false }: IInputRadio): JSX.Element => {
  const [current, setCurrent] = useState<string>();
  const fieldRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setCurrent(selected);
  }, [selected]);
  return (
    <div
      className={clsx(" ", {
      })}
    >
      {label && (
        <>
          <span className="text-xs md:text-sm font-medium text-gray-900 ">{label}</span>
          <span className="text-danger-main ml-1">{required && "*"}</span>
        </>
      )}
      <div className={clsx(
        "mt-2 ",
        { ["space-x-6"]: inlineOptions },
        { ["grid space-x-0"]: !inlineOptions }
      )}>
        {options.map((input, i) => {
          return (
            <label className="inline-flex items-center cursor-pointer text-xs md:text-sm" key={i}>
              <input
                id={id}
                type="radio"
                className={`form-radio border border-primary-main checked:bg-primary-main checked:border-none cursor-pointer ${classes}`}
                name={name}
                value={input.value}
                checked={current === input.value}
                onChange={onSelect}
              />
              <span className="ml-2">{input.name}</span>
            </label>
          );
        })}
        {/* Helper Text */}
        <div className={clsx("text-xs text-gray-400", { ["text-danger-main"]: error })}>{helperText}</div>
      </div>
      {fieldRef.current && (
        <ScrollToFormikError fieldName={name ?? id ?? ""} fieldRef={fieldRef} />
      )}
    </div>
  );
};
interface IInputRadio {
  id?: string;
  label?: string;
  name: string;
  options: IRadioOptionItem[];
  selected: string;
  onSelect: (e: React.ChangeEvent<any>) => void | null | any;
  error?: string | undefined;
  helperText?: string;
  disabled?: boolean;
  inlineOptions?: boolean;
  classes?: string;
  required?: boolean;
}

export interface IRadioOptionItem {
  name: string;
  value: string;
}
