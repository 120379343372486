import { TEAM_TYPES } from '../../resources/enums';
import { ITeamPaymentAccount } from '../../resources/interfaces';
import { Partner } from '../partner/Partner';
import { IUser, User } from '../User';
import { ITeam } from './ITeam';

export class Team {
  id: string;
  name: string;
  numberOfMembers: number;
  relationshipManager?: User;
  commissionRate?: string;
  summary: any;
  teamLead: string | undefined;
  type: TEAM_TYPES;
  createdOn: Date;
  updatedOn: Date;
  commissionRateComprehensive: number;
  commissionRateThirdParty: number;
  email: string;
  address: string;
  mobilePhone: string;
  corporatePhone: string;
  setupCompleted: boolean;
  paymentAccount: ITeamPaymentAccount;
  logo: string;
  members: User[];
  policyCreationTime?: number;
  partner?: Partner;

  constructor(Imodel: ITeam) {
    this.id = Imodel.id;
    this.name = Imodel.name;
    this.numberOfMembers = Imodel.numberOfMembers;
    this.relationshipManager = Imodel.relationshipManager
      ? new User(Imodel.relationshipManager)
      : undefined;
    this.commissionRate = Imodel.commissionRate;
    this.summary = Imodel.summary;
    this.teamLead = Imodel.teamLead ?? undefined;
    this.type = Imodel.type;
    this.createdOn = new Date(Imodel.createdOn);
    this.updatedOn = new Date(Imodel.updatedOn);
    this.commissionRateComprehensive = Imodel.commissionRateCP;
    this.commissionRateThirdParty = Imodel.commissionRateTP;
    this.email = Imodel.email;
    this.address = Imodel.address;
    this.mobilePhone = Imodel.mobilePhone;
    this.corporatePhone = Imodel.corporatePhone;
    this.setupCompleted = Imodel.setupCompleted;
    this.paymentAccount = Imodel.paymentAccount;
    this.logo = Imodel.logo;
    this.members = Imodel.members ? Imodel.members?.map((member: IUser) => new User(member)) : [];
    this.policyCreationTime = Imodel.policyCreationTime ?? undefined;
    this.partner = Imodel.partner ? new Partner(Imodel.partner) : undefined;
  }

  getPolicyCompletionTimeInMinutes(): number | undefined {
    return this.policyCreationTime ? this.policyCreationTime / 1000 : undefined;
  }
}
