import React from 'react';

type RetryProps = {
    message?: string;
    onRetry: () => void;
    retryLabel?: string;
}

const Retry: React.FC<RetryProps> = ({
    message = "Network error. Please try again shortly.", 
    onRetry,
    retryLabel = "Retry", 
}) => {
    return (
        <div className="flex flex-col items-center justify-center p-6  rounded-lg shadow-lg w-full max-w-96 mx-auto">
            <div className="flex items-end gap-2">
                <img src="/img/NetworkError.png" alt="Network Error" className='h-20 w-20 rounded-full'/>
                <p className="text-sm xl:text-lg text-gray-700 mb-4 text-center">{message}</p>

            </div>
            <button
                className="px-4 py-2 bg-red-500 text-white rounded-md hover:opacity-70 transition duration-200 w-full mt-5"
                onClick={onRetry}
            >
                {retryLabel}
            </button>
        </div>
    );
};

export default Retry;
