import { useEffect, useMemo, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { downloadPolicyDocument, getSingleCustomer, getSingleRNPLCustomer } from '../../../api/requests';
import { Customer } from '../../../models/customer/Customer';
import Loader from '../../../components/Loader';
import { PencilSquareIcon } from '@heroicons/react/24/solid';
import moment from 'moment';
import {
  capitalizeSentence,
  getInstallmentTypeFromKey,
  getPhoneNumberFromShortCodeFormat,
  getPolicyReviewStatus,
  getVehicleProtectionFromKey,
  numberWithCommas
} from '../../../utils/miscFunctions';
import useUserAccount from '../../../hooks/userAccountHook';
import { User } from '../../../models/User';
import useRoute from '../../../hooks/routesHook';
import useModal from '../../../hooks/modalHook';
import PolicyApproval from '../policies/PolicyApproval';
import { Policy } from '../../../models/policy/Policy';
import {
  POLICY_DOCUMENT_TYPES,
  POLICY_REVIEW_STATES,
  POLICY_STATUS_OPTIONS
} from '../../../resources/enums';
import ReviewPolicy from '../policies/ReviewPolicy';
import { ReactComponent as CancelPolicy } from './imgs/cancel-policy.svg';
import clsx from 'clsx';
import { HiDownload } from 'react-icons/hi';
import BackButton from '../../../components/BackButton';
import PopOnMe from '../../../components/elements/poponme';
import GTable from '../../../components/elements/GTable';
import PolicyStatus from '../../../components/elements/PolicyStatus';
import { EllipsisVerticalIcon } from '@heroicons/react/24/outline';
import { ITableRowAction } from '../../../components/elements/GTable/GTable';
import EditCustomer from './EditCustomer';
import ChecklistStrength from '../../../components/CheckListStrength';
import NewMessage from '../partners/NewMessage';
import TabContent from '../../../components/tabs/TabContent';
import TabNav, { ITab } from '../../../components/tabs/TabNav';
import { IPolicyDocument, ITask } from '../../../resources/interfaces';
import { getStatus } from '../policies/policyDetails';
import { ReactComponent as PdfIcon } from '../policies/imgs/document.svg';
import fileDownload from 'js-file-download';
import { toast } from 'react-toastify';
import { EyeIcon } from '@heroicons/react/24/outline';
import IdCard from '../policies/policyDetails/imgs/OWNER_ID.png';
import OWNER_PHOTO from '../policies/policyDetails/imgs/OWNER_PHOTO.png';
import DriverLicenseIcon from '../policies/policyDetails/imgs/id-card.png';
import CreateTask from '../leads/CreateTask';
import { SERVER_DATE_FORMAT } from '../../../resources/constants';
import { TwoStateVerifiedCheck } from '../../../components/statuses';
import { myCustomer } from '../../../utils/hardCodedResponse';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import PolicyUpdates from '../policies/PolicyUpdates';
import CustomerUpdates from './CustomerUpdates';

export interface IVerifyCustomerDTO {
  accountProviders: string;
  customerId?: number | string;
  identifier: string;
  verificationCode: string;
}

interface ICustomerDetailsProps {
  previousPath: string;
}

const ViewCustomer = (props: ICustomerDetailsProps): JSX.Element => {
  const navigate = useNavigate();
  const [customer, setCustomer] = useState<Customer>();
  const { user } = useUserAccount();
  const { getDashboardBase } = useRoute();
  const { id } = useParams();
  const { ModalComponent, updateModalStates, closeModal } = useModal();
  const [activeTab, setActiveTab] = useState('policies');
  const [completedChecklist, setCompletedChecklist] = useState<string[]>([]);
  const queryClient = useQueryClient();
  const [showUpdates, setShowUpdates] = useState<boolean>(false);

  const getTabs = () => {
    let tabs: ITab[] = [
      {
        id: 'policies',
        title: 'Policies',
        itemCount: customer && customer?.policyList && customer?.policyList?.length
      },
      {
        id: 'tasks',
        title: 'Tasks',
        itemCount: customer && customer?.tasks && customer?.tasks?.length
      },
      // {
      //   id: "claims",
      //   title: "Claims",
      //   itemCount: customer && customer?.policyList && customer?.policyList?.length
      // },
      {
        id: 'documents',
        title: 'Documents',
        itemCount: customer && customer?.customerDocuments && customer?.customerDocuments?.length
      },
      // {
      //   id: 'rnpl',
      //   title: 'RNPL',
      //   itemCount: customer && customer?.customerDocuments && customer?.customerDocuments?.length
      // }
    ];

    return tabs;
  };

  const getCompletedOptions = () => {
    const completedOptions = [];

    if (customer?.emailVerified) {
      completedOptions.push('Email verified');
    }
    if (customer?.phoneVerified) {
      completedOptions.push('Phone verified');
    }
    if (customer?.idVerified) {
      completedOptions.push('ID verified');
    }
    if (customer?.otherPhoneVerified) {
      completedOptions.push('Other phone verified');
    }

    return completedOptions;
  };

  useEffect(() => {
    if (customer) {
      setCompletedChecklist(
        customer.tasks
          ? customer?.tasks
              ?.filter(
                (task: ITask) =>
                  task.completed && task.type && postSaleChecklist?.includes(task?.type)
              )
              ?.map((tast: ITask) => tast.type ?? '')
          : []
      );
      getSingleRNPLCustomer(customer?.id).then((response)=>{
        console.log(response)
      }).catch((error)=>{
        console.log(error)
      })
    }
   
  }, [customer]);
  const [rnplCustomers, setRnplCustomers] = useState<any>([])

  const columnsRNPL = useMemo(() => {
    const baseColumns = [
      {
        Header: 'Applicant',
        accessor: 'customer',
        Cell: ({ row }: any) => (
          <Link to={`${getDashboardBase()}/customers/${row.original.customer.id}`}  target='blank_' className="hover:underline text-link-main">
            {capitalizeSentence(row.original.customer.firstName)} {capitalizeSentence(row.original.customer.lastName) ?? 'N/A'}
        </Link> 
        )
      },
      {
        Header: 'Applicant Phone',
        accessor: 'customer.phone',
        Cell: (row: any) => {
          return (
            <span>
              {row.cell.value ? getPhoneNumberFromShortCodeFormat(row.cell.value) : 'N/A'}
            </span>
          );
        }
      },
      {
        Header: 'Vehicle Number',
        accessor: 'vehicles.registrationNumber',
        Cell: (row: any) => {
          return <span>{row.cell.value ?? 'N/A'}</span>;
        }
      },
      {
        Header: 'Repair Cost',
        accessor: 'repairCost',
        Cell: (row: any) => {
          return <span>{row.cell.value ? row.cell.value : 'N/A'}</span>;
        }
      },   
      {
        Header: 'Repair Type',
        accessor: 'repairType',
        Cell: (row: any) => {
          return <span>{row.cell.value ? capitalizeSentence(row.cell.value.split("_").join(" ")) : 'N/A'}</span>;
        }
      }
    ];

    return [
      {
        Header: 'M',
        columns: baseColumns
      }
    ];
  }, []);

  const fetchCustomer = async () => {
    try {
      if (id) {
        const response: any = await getSingleCustomer(id);
        setCustomer(response);
      }
    } catch (error) { 
    }
  };

  const { isLoading } = useQuery({
    queryKey: ['fetchCustomer'],
    queryFn: fetchCustomer
  });

  const showCreateTaskModal = (task?: ITask, isEdit = false,selectedoption?:string) => {
    id &&
      updateModalStates({
        show: true,
        size: 'xl',
        children: (
          <CreateTask
            onProceed={() => {
              queryClient.invalidateQueries({ queryKey: ['fetchCustomer'] });
              closeModal();
            }}
            onClose={closeModal}
            id={id}
            isEdit={isEdit}
            task={task}
            type={'CUSTOMER'}
            selectedOption={selectedoption}
          />
        ),
        showTitleSection: false,
        classes: '!p-0 r-[5px]'
      });
  };

  const showUpdateTaskModal = (task: ITask, isEdit = true) => {
    id &&
      updateModalStates({
        show: true,
        size: 'xl',
        children: (
          <CreateTask
            onProceed={() => {
              queryClient.invalidateQueries({ queryKey: ['fetchCustomer'] });
              closeModal();
            }}
            onClose={closeModal}
            id={id}
            isEdit={isEdit}
            task={task}
            type={'CUSTOMER'}
          />
        ),
        showTitleSection: false,
        classes: '!p-0 r-[5px]'
      });
  };

  const getTableRowActions = (data: any): ITableRowAction[] => {
    const actions = [
      {
        label: 'View',
        callback: (policy: any) => {
          navigate(`${getDashboardBase()}/policies/${policy?.policyNumber}`, { replace: true });
        },
        data: data
      }
    ];

    if (
      !(user.isSuperAdmin() || user.isReviewer()) &&
      data?.reviewStage !== POLICY_REVIEW_STATES.APPROVED
    ) {
      actions.push({
        label: 'Edit',
        callback: (policy: any) => {
          navigate(`${getDashboardBase()}/policies/${policy?.policyNumber}/edit`, {
            replace: true
          });
        },
        data: data
      });
    }

    if (user.isSuperAdmin() && data?.reviewStage === POLICY_REVIEW_STATES.APPROVED) {
      actions.push({
        label: 'Cancel',
        callback: (policy: Policy) => cancelPolicy(policy),
        data: data
      });
    }

    if (user.isSuperAdmin() && data?.reviewStage === POLICY_REVIEW_STATES.IN_APPROVAL) {
      actions.push({
        label: 'Approve',
        callback: (policy: Policy) => approvePolicy(policy),
        data: data
      });
    }

    return actions;
  };

  const getTaskTableRowActions = (data: any): ITableRowAction[] => {
    const actions = [
      {
        label: 'Update',
        callback: (task: any) => showUpdateTaskModal(task),
        data: data
      }
    ];

    return actions;
  };

  const approvePolicy = (policy: Policy) => {
    updateModalStates({
      show: true,
      children: (
        <PolicyApproval
          policy={policy}
          onApproved={() => {
            closeModal();

            const _customer = customer;
            const policyIndex = _customer?.policyList?.findIndex(
              (_policy: any) => _policy.ref === policy.ref
            );

            if (policyIndex && _customer && _customer.policyList) {
              _customer.policyList[policyIndex].active = true;
              _customer.policyList[policyIndex].reviewStage = POLICY_REVIEW_STATES.APPROVED;
            }
            setCustomer(_customer);
          }}
          onCancel={closeModal}
        />
      ),
      showTitleSection: false,
      classes: '!p-0 r-[5px]'
    });
  };

  const cancelPolicy = (policy: Policy) => {
    updateModalStates({
      show: true,
      children: (
        <ReviewPolicy
          policy={policy}
          onProceed={() => {
            closeModal();

            const _customer = customer;
            const policyIndex = _customer?.policyList?.findIndex(
              (_policy: any) => _policy.ref === policy.ref
            );

            if (policyIndex && _customer && _customer.policyList) {
              _customer.policyList[policyIndex].active = false;
              _customer.policyList[policyIndex].reviewStage = POLICY_REVIEW_STATES.CANCELLED;
            }
            setCustomer(_customer);
          }}
          onCancel={closeModal}
          title="Cancel Policy"
          titleIcon={<CancelPolicy />}
          type={POLICY_REVIEW_STATES.CANCELLED}
        />
      ),
      showTitleSection: false,
      classes: '!p-0 r-[5px]',
      size: 'lg'
    });
  };

  const emailCustomer = () => {
    customer &&
      updateModalStates({
        show: true,
        size: '2xl',
        children: (
          <NewMessage
            onProceed={() => {
              closeModal();
            }}
            onClose={closeModal}
            customerMail={customer?.email}
          />
        ),
        showCloseButton: false,
        classes: '!p-0 r-[5px]'
      });
  };

  const getActions = () => {
    let actions = [{ label: 'Create task', callback: () => showCreateTaskModal() }];

    actions.push({
      label: `View / add ${customer?.customerUpdates?.length === 1 ? ' update' : ' updates'}`,
      callback: () => setShowUpdates(true)
    });

    return actions;
  };

  const handleTabClicked = (id: string) => {
    // navigate(`${getDashboardBase()}/approvals?tab=${id}`);
  };

  const getDocumentPlaceholder = (type: POLICY_DOCUMENT_TYPES | undefined): JSX.Element => {
    switch (type) {
      case POLICY_DOCUMENT_TYPES.ID_CARD:
        return (
          <div className="w-full max-h-20 border-[2px] border-opacity-30 border-black p-3 flex justify-center items-center">
            <img src={IdCard} className="size-10 " />
          </div>
        );

      case POLICY_DOCUMENT_TYPES.PASSPORT_PHOTO:
        return (
          <div className="w-full max-h-20 border-[2px] border-opacity-30 border-black p-3 flex justify-center items-center">
            <img src={OWNER_PHOTO} className="size-10 " />
          </div>
        );

      case POLICY_DOCUMENT_TYPES.DRIVER_LICENSE_BACK:
        return (
          <div className="w-full max-h-20 border-[2px] border-opacity-30 border-black p-3 flex justify-center items-center">
            <img src={DriverLicenseIcon} className="size-10 " />
          </div>
        );
      case POLICY_DOCUMENT_TYPES.DRIVER_LICENSE_FRONT:
        return (
          <div className="w-full max-h-20 border-[2px] border-opacity-30 border-black p-3 flex justify-center items-center">
            <img src={DriverLicenseIcon} className="size-10 " />
          </div>
        );

      default:
        return (
          <div className="w-full max-h-20 border-[2px] border-opacity-30 border-black p-3 flex justify-center items-center"></div>
        );
    }
  };

  const previewDocument = (document: IPolicyDocument) => {
    window.open(document.docURL, '_blank');
  };

  const downloadDocument = (document: IPolicyDocument) => {
    downloadPolicyDocument(document.id)
      .then((response: any) => {
        if (response) {
          fileDownload(response.data, `Brolly Africa - ${document.docType}.pdf`);
        }
      })
      .catch(() => {
        toast.error('Failed to download document. Please try again later');
      });
  };

  const columns = useMemo(
    () => [
      {
        Header: 'M',
        columns: [
          {
            Header: 'Customer',
            accessor: 'customer',
            Cell: (row: any) => {
              return (
                <span>
                  {row.cell.value
                    ? `${row.cell.value?.firstName} ${row.cell.value?.lastName}`
                    : 'N/A'}
                </span>
              );
            }
          },
          {
            Header: 'Phone #',
            accessor: 'customer.phone',
            Cell: (row: any) => {
              return <span>{getPhoneNumberFromShortCodeFormat(row.cell.value) ?? 'N/A'}</span>;
            }
          },
          {
            Header: 'Policy ID',
            accessor: 'policyNumber',
            Cell: (row: any) => {
              //  to={`${getDashboardBase()}/policies/${
              //   row?.row?.original.ref
              // }?filter=${filter}&page=${currentPage}`}>

              return (
                <Link to={`${getDashboardBase()}/policies/${row.cell.value}`}>
                  <span className="hover:underline text-link-main">{row.cell.value ?? 'N/A'}</span>
                </Link>
              );
            }
          },
          {
            Header: 'Review Stage',
            accessor: 'reviewStage',
            Cell: (row: any) => {
              return (
                <PolicyStatus
                  status={getPolicyReviewStatus(row.cell.value)}
                  firstSuccessfulPayment={row?.original?.isInitialPaymentReceived()}
                />
              );
            }
          },
          {
            Header: 'Protection Type',
            accessor: 'quote.protectionType',
            Cell: (row: any) => {
              return (
                <span>
                  {row.cell.value ? getVehicleProtectionFromKey(row.cell.value)?.title : 'N/A'}
                </span>
              );
            }
          },
          {
            Header: 'Installment Type',
            accessor: 'installmentType',
            Cell: (row: any) => {
              return (
                <span>
                  {row.cell.value ? <>{getInstallmentTypeFromKey(row.cell.value)}</> : 'N/A'}
                </span>
              );
            }
          },
          {
            Header: 'Outright Premium',
            accessor: 'quote.quotePlan.outRightPremium',
            Cell: (row: any) => {
              return (
                <div className="min-w-[100px]">
                  <span>&#8373; {numberWithCommas(row.cell.value)}</span>{' '}
                </div>
              );
            }
          },
          {
            Header: 'Initial Deposit',
            accessor: 'quote.quotePlan.initialDeposit',
            Cell: (row: any) => {
              return (
                <div className="min-w-[100px]">
                  <span> &#8373; {numberWithCommas(row.cell.value)}</span>
                </div>
              );
            }
          },
          {
            Header: 'Installments',
            accessor: 'quote.quotePlan.monthlyInstallment',
            Cell: (row: any) => {
              return (
                <div className="min-w-[100px]">
                  <span>
                    {row.cell.value ? (
                      <>
                        <span>&#8373;</span> {numberWithCommas(row.cell.value)}
                      </>
                    ) : (
                      'N/A'
                    )}
                  </span>
                </div>
              );
            }
          },
          {
            Header: 'Total Payment',
            accessor: 'quote.quotePlan.totalPremium',
            Cell: (row: any) => {
              return (
                <div className="min-w-[100px]">
                  <span>
                    {' '}
                    {row.cell.value ? (
                      <>
                        <span>&#8373;</span> {numberWithCommas(row.cell.value)}
                      </>
                    ) : (
                      'N/A'
                    )}
                  </span>
                </div>
              );
            }
          },
          {
            Header: 'Inception Date',
            accessor: 'startsOn',
            Cell: (row: any) => {
              return (
                <span>
                  {row.cell.value ? moment(row.cell.value).format('DD/MM/YY h:mm a') : 'N/A'}
                </span>
              );
            }
          },
          {
            Header: 'Insurer',
            accessor: 'insurer',
            Cell: (row: any) => {
              return <span>{row.cell.value ? row.cell.value?.name : 'N/A'}</span>;
            }
          },
          {
            Header: 'Owner',
            accessor: 'owner',
            Cell: (row: any) => {
              return <span>{row.cell.value ? row.cell.value?.name : 'N/A'}</span>;
            }
          },
          {
            Header: 'Manager',
            accessor: 'manager',
            Cell: (row: any) => {
              return (
                <span>
                  {row.cell.value
                    ? `${row.cell.value?.firstName} ${row.cell.value?.lastName}`
                    : 'N/A'}
                </span>
              );
            }
          },
          {
            Header: 'Created On',
            accessor: 'createdOn',
            Cell: (row: any) => {
              return (
                <span>
                  {row.cell.value ? moment(row.cell.value).format('DD/MM/YY h:mm a') : 'N/A'}
                </span>
              );
            }
          },
          {
            Header: 'Status',
            accessor: 'status',
            Cell: (row: any) => {
              return (
                <div className="w-full flex  text-white">
                  <span
                    className={clsx(
                      'w-[30px] h-[30px] rounded-full flex items-center justify-center text-[0px] ',
                      {
                        ['bg-status-active']:
                          new Policy(row.row.original).getStatus()?.toLowerCase() ===
                          POLICY_STATUS_OPTIONS.ACTIVE
                      },
                      {
                        ['bg-status-ended']:
                          new Policy(row.row.original).getStatus()?.toLowerCase() ===
                          POLICY_STATUS_OPTIONS.INACTIVE
                      }
                    )}>
                    {new Policy(row.row.original).getStatus()}
                  </span>
                </div>
              );
            }
          },
          {
            id: 'Action',
            Cell: (row: any) => {
              return (
                <div className=" flex justify-center !p-0 w-10">
                  <PopOnMe actions={getTableRowActions(row.row.original)}>
                    <EllipsisVerticalIcon className="h-6 w-6" />
                  </PopOnMe>
                </div>
              );
            }
          }
        ]
      }
    ],
    []
  );

  const taskColumns = useMemo(
    () => [
      {
        Header: 'M',
        columns: [
          {
            Header: 'Name',
            accessor: 'name',
            Cell: (row: any) => {
              return <span>{row.cell.value ? capitalizeSentence(row.cell.value) : 'N/A'}</span>;
            }
          },

          {
            Header: 'Description',
            accessor: 'description',
            Cell: (row: any) => {
              return <span>{row.cell.value ?? 'N/A'}</span>;
            }
          },
          {
            Header: 'Type',
            accessor: 'type',
            Cell: (row: any) => {
              return <span>{row.cell.value ?? 'N/A'}</span>;
            }
          },
          {
            Header: 'Assigned To',
            accessor: 'assignedTo.firstName',
            Cell: (row: any) => {
              return (
                <span>
                  {row.cell.value
                    ? capitalizeSentence(
                        row.row.original.assignedTo.firstName +
                          ' ' +
                          row.row.original.assignedTo.lastName
                      )
                    : 'N/A'}
                </span>
              );
            }
          },
          {
            Header: 'Status',
            accessor: 'completed',
            Cell: (row: any) => {
              return getStatus(row.cell.value);
            }
          },
          {
            Header: 'Due date',
            accessor: 'dueOn',
            Cell: (row: any) => {
              return (
                <span>
                  {row.cell.value ? moment(row.cell.value).format('MMM Do YY, h:mm a') : 'N/A'}
                </span>
              );
            }
          },
          {
            id: 'Action',
            Cell: (row: any) => { 
              return (
                <div className=" flex justify-center">
                  {getTaskTableRowActions(row.row.original).length > 0 && (
                    <PopOnMe actions={getTaskTableRowActions(row.row.original)}>
                      <EllipsisVerticalIcon className="h-4 w-4" />
                    </PopOnMe>
                  )}
                </div>
              );
            }
          }
        ]
      }
    ],
    []
  );

  const postSaleChecklist = [
    // 'Highlights of policy terms and conditions',
    // 'Welcome Call / Message / Video',
    // 'Sign up to Fuel rebates',
    // 'Send welcome gift',
    // 'How to log in to account and check payments',
    // 'Invite to follow socials',
    // 'Invite to review',
    // 'Send video on renewal (Month 9)',
    // 'Users should now be asked to provide their WhatsApp number',
    // 'Users should provide their birthday'

    'Welcome call',
    'In-person review',
    'Highlights of policy terms',
    'How to login to account and check payments',
    'Provide whatsapp number',
    'Invite to review ',
    'Message to follow channels',
    'Message to follow social media pages',
    'Call on add-on services',
    'Payment completion message with Gift',
    'Call and message on due -later',
    'Call and message on renewal',
    'Thank you call for renewal'
  ];

  const showEditCustomerModal = () => {
    customer &&
      updateModalStates({
        show: true,
        size: 'md',
        children: (
          <EditCustomer
            customer={customer}
            onProceed={() => {
              queryClient.invalidateQueries({ queryKey: ['fetchCustomer'] });
              closeModal();
            }}
            onClose={closeModal}
          />
        ),
        showCloseButton: false,
        classes: '!p-0 r-[5px]'
      });
  };

  // const showCustomerVerificationModal = () => {
  //   updateModalStates({
  //     show: true,
  //     size: 'md',
  //     children: <CustomerPhoneVerificationModal />,
  //     showTitleSection: false,
  //     classes: '!p-0 r-[5px]'
  //   });
  // };

  return (
    <div className="">
      {isLoading ? (
        <div className="h-screen flex items-center justify-center">
          <Loader message="Fetching customer. Please wait..." brollyLoader />
        </div>
      ) : (
        <div className="flex flex-col w-full bg-white text-sm p-5">
          {/* Top Section */}
          <div className=" flex flex-col md:flex-row justify-between md:items-center gap-5 md:gap-0">
            <div className="flex flex-col md:flex-row gap-5 md:items-center">
              <div className="flex items-center gap-8 ">
                <BackButton onClick={() => navigate(props.previousPath)} />

                <h2 className="font-semibold text-lg">
                  {customer?.firstName} {customer?.lastName}
                </h2>
              </div>
            </div>

            <div className="mb-2 min-[400px]:mb-0">
              {getActions().length > 0 && (
                <PopOnMe actions={getActions()}>
                  <div className="cursor-pointer py-1 px-2  border border-primary-main rounded">
                    Actions
                  </div>
                </PopOnMe>
              )}
            </div>
          </div>

          {/* Details section */}
          <div className="grid grid-cols-1 md:grid-cols-3 gap-5 py-10">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-5 md:col-span-2">
              {/* Personal Info section */}
              <div className="">
                <div className="">
                  <span className="font-semibold">Personal Information</span>
                </div>

                <div className=" flex flex-col gap-2 border border-gray-300 p-2 rounded-lg">
                  <CustomerDetailsItem name="First Name" value={customer?.firstName ?? 'N/A'} />
                  <CustomerDetailsItem name="Last Name" value={customer?.lastName ?? 'N/A'} />
                  <CustomerDetailsItem name="Gender" value={customer?.gender ?? 'N/A'} />
                  <CustomerDetailsItem
                    name="Date of Birth"
                    value={
                      customer?.dateOfBirth
                        ? moment(customer?.dateOfBirth).format(SERVER_DATE_FORMAT)
                        : 'N/A'
                    }
                  />
                </div>
              </div>

              {/* Contact Info section */}
              <div className="">
                <div className="flex items-center justify-between">
                  <span className="font-semibold">Contact Information</span>
                  <PencilSquareIcon
                    className="h-4 w-4 cursor-pointer"
                    onClick={showEditCustomerModal}
                  />
                </div>

                <div className=" flex flex-col gap-2 border border-gray-300 p-2 rounded-lg">
                  <CustomerDetailsItem
                    name="Primary Phone"
                    value={
                      customer?.phone ? getPhoneNumberFromShortCodeFormat(customer?.phone) : 'N/A'
                    }>
                    <TwoStateVerifiedCheck verified={customer && customer.phoneVerified} />
                  </CustomerDetailsItem>

                  <CustomerDetailsItem
                    name="WhatsApp Phone"
                    value={customer?.whatsAppPhone ?? 'N/A'}>
                    <TwoStateVerifiedCheck verified={customer && customer.emailVerified} />
                  </CustomerDetailsItem>

                  <CustomerDetailsItem
                    name="Secondary Phone"
                    value={
                      customer?.otherPhone
                        ? getPhoneNumberFromShortCodeFormat(customer.otherPhone)
                        : 'N/A'
                    }>
                    <TwoStateVerifiedCheck verified={customer?.otherPhoneVerified} />
                  </CustomerDetailsItem>
                  <CustomerDetailsItem name="Email Address" value={customer?.email ?? 'N/A'} />
                </div>
              </div>

              {/* Employee Info section */}
              <div className="">
                <div className="">
                  <span className="font-semibold">Employee Information</span>
                </div>

                <div className=" flex flex-col gap-2 border border-gray-300 p-2 rounded-lg">
                  <CustomerDetailsItem
                    name="Employer Name"
                    value={customer?.employerName ?? 'N/A'}
                  />
                  <CustomerDetailsItem
                    name="Employer Phone"
                    value={
                      customer?.employerPhone
                        ? getPhoneNumberFromShortCodeFormat(customer.employerPhone)
                        : 'N/A'
                    }
                  />
                  <CustomerDetailsItem
                    name="Employer Address"
                    value={customer?.employerAddress ?? 'N/A'}
                  />
                </div>
              </div>

              {/* Other Info section */}
              <div className="">
                <div className="flex items-center justify-between">
                  <span className="font-semibold">Other Information</span>
                </div>

                <div className=" flex flex-col gap-2 border border-gray-300 p-2 rounded-lg">
                  <CustomerDetailsItem name="ID type" value={customer?.idType ?? 'N/A'} />
                  <CustomerDetailsItem name="ID number" value={customer?.idNumber ?? 'N/A'}>
                    <TwoStateVerifiedCheck verified={customer?.idVerified} />
                  </CustomerDetailsItem>
                  <CustomerDetailsItem name="Home Address" value={customer?.address ?? 'N/A'} />
                </div>
              </div>
            </div>

            <div className="col-span-1">
              <ChecklistStrength
                options={postSaleChecklist}
                title={'Post-sale Checklist'}
                completedOptions={completedChecklist}
                onOptionChange={(option: string, remove?: boolean) => {
                  if (remove) {
                    setCompletedChecklist(completedChecklist.filter((item) => item !== option));
                  } else {
                    setCompletedChecklist([...completedChecklist, option]);
                  }
                }}
                onClick={(option) => {
                  let task: any;

                  showCreateTaskModal(task, false, option);
                }}
              />
            </div>
          </div>

          <div className="flex items-center gap-5">
            <TabNav
              tabs={getTabs()}
              activeTab={activeTab}
              onTabClicked={handleTabClicked}
              setActiveTab={(id: string) => {
                setActiveTab(id);
              }}
            />
          </div>

          <div className="">
            <TabContent id={'tasks'} activeTab={activeTab}>
              {/* customer Tasks */}
              {customer &&
              customer.tasks &&
              customer.tasks.length > 0 &&
              !user.isInsurer() &&
              !user.isRideSharing() ? (
                <div className=" my-5 ">
                  <GTable
                    columns={taskColumns}
                    data={customer?.tasks}
                    classes="h-fit"
                    showSearch={false}
                    tableType={'task'}
                    stickyHeader
                  />
                </div>
              ) : (
                <div className="h-20 flex items-center justify-center text-lg">No task found </div>
              )}
            </TabContent>

            <TabContent id={'documents'} activeTab={activeTab}>
              <>
                {!user.isRideSharing() &&
                  !user.isInsurer() &&
                  customer &&
                  customer?.customerDocuments &&
                  customer?.customerDocuments?.length > 0 && (
                    <div className="w-full grid grid-cols-1 md:grid-cols-3 xl:grid-cols-4 items-center gap-5">
                      {customer?.customerDocuments?.map(
                        (document: IPolicyDocument, index: number) => (
                          <div key={index}>
                            <div className="min-h-[90px]">
                              {getDocumentPlaceholder(document.docType)}
                            </div>
                            <div className="w-full bg-black h-[40px] flex items-center">
                              <div className="flex justify-between items-center w-full px-4">
                                <div className="flex items-center space-x-2 w-full flex-start">
                                  <PdfIcon className="w-[16px]" />
                                  <span className="text-white text-[16px] whitespace-nowrap">
                                    {document.title}
                                  </span>
                                </div>
                                <div className="flex items-center space-x-2 flex-end">
                                  <EyeIcon
                                    className="text-white w-[16px] cursor-pointer"
                                    onClick={() => previewDocument(document)}
                                  />
                                  <HiDownload
                                    className="text-white w-[16px] cursor-pointer"
                                    onClick={() => downloadDocument(document)}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  )}

                {/* No Documents Found */}
                {customer && !customer.customerDocuments?.length && (
                  <div className="h-20 flex items-center justify-center text-lg">
                    No document found
                  </div>
                )}
              </>
            </TabContent>
{/* 
            <TabContent id={'rnpl'} activeTab={activeTab}>
              {!user.isRideSharing() && !user.isInsurer() && customer?.policyList && (
                <>
                  <div className=" my-5 ">
                  <GTable
              columns={columnsRNPL}
              data={rnplCustomers ?? []}
              classes="h-fit"
              tableType={'Customer'} 
              showSearch={false}   
            />
                  </div>

                  {!customer?.policyList && (
                    <div className="h-40 flex items-center justify-center text-lg">
                      No policy found
                    </div>
                  )}
                </>
              )}
            </TabContent> */}

            <TabContent id={'policies'} activeTab={activeTab}>
              {!user.isRideSharing() && !user.isInsurer() && customer?.policyList && (
                <>
                  <div className=" my-5 ">
                    <GTable
                      columns={columns}
                      data={customer?.policyList}
                      showSearch={false}
                      tableType="policy"
                      onRowClick={(policy: any) => {
                        navigate(`${getDashboardBase()}/policies/${policy.policyNumber}`, {
                          replace: true
                        });
                      }}
                      columnClasses={clsx('w-fit')}
                      loading={isLoading}
                      tableContainerId="tableId"
                      stickyHeader
                    />
                  </div>

                  {!customer?.policyList && (
                    <div className="h-40 flex items-center justify-center text-lg">
                      No policy found
                    </div>
                  )}
                </>
              )}
            </TabContent>

            {/* <TabContent id={'claims'} activeTab={activeTab}>
              {!user.isRideSharing() && !user.isInsurer() && claims && (
                <>
                  <div className=" my-5 ">
                    <h2 className="font-semibold text-lg">Claims</h2>
                    <div className="">
                      <GTable
                        columns={claimsColumns}
                        data={claims}
                        classes="h-fit"
                        showSearch={false}
                        tableType={'claim'}
                        loading={loadClaims}
                        stickyHeader
                      />
                    </div>
                  </div>


                  {!claims && (
                    <div className="h-40 flex items-center justify-center text-lg">
                      No claim found
                    </div>
                  )}
                </>
              )}
            </TabContent> */}
          </div>

          {customer && showUpdates && (
            <CustomerUpdates
              show={showUpdates}
              onClose={() => {
                setShowUpdates(false);
              }}
              onProceed={() => {}}
              updates={customer?.customerUpdates ?? []}
              custumorId={customer.id}
              onCustomerUpdated={(customer: Customer) => setCustomer(customer)}
            />
          )}
        </div>
      )}
      <ModalComponent />
    </div>
  );
};

type CustomerDetailsItemProps = {
  name: string;
  value: string;
  children?: React.ReactNode;
};

const CustomerDetailsItem = ({ name, value, children }: CustomerDetailsItemProps) => {
  return (
    <div className="flex flex-row justify-between py-1 ">
      <div className="text-gray-600 ">{name}:</div>
      <div className="flex items-center gap-1">
        <span className="truncate font-medium whitespace-nowrap">{value}</span>
        <>{children} </>
      </div>
    </div>
  );
};

export default ViewCustomer;
