import { Formik } from 'formik';
import TextArea from '../../../components/form-control/TextArea';
import * as Yup from 'yup';
import { LEAD_STATUS, LEAD_TAGS } from '../../../resources/enums';
import React, { useEffect, useState, useRef} from 'react';
import Button from '../../../components/form-control/Button';
import { getSingleLead, updateLead, updateLeadTrail } from '../../../api/requests';
import { toast } from 'react-toastify';
import useRoute from '../../../hooks/routesHook';
import { Animate } from 'react-simple-animate';
import clsx from 'clsx';
import { XMarkIcon } from '@heroicons/react/24/solid';
import moment from 'moment';
import { Lead } from '../../../models/lead/Lead';
import { useParams } from 'react-router-dom';
import SelectMenu from '../../../components/form-control/SelectMenu';
import Input from '../../../components/form-control/Input';
import Loader from '../../../components/Loader';
export interface IUpdateLeadTrail {
  description?: string;
  leadStatus?: string;
  tag?: string;
  leadOwner?: string;
  id?: string;
  date?: Date;
}

interface ILeadUpdates {
  onProceed: () => void;
  onClose: () => void;
  show: boolean;
  updates: IUpdateLeadTrail[];
  leadID: string;
  onLeadUpdated: (lead: Lead) => void;
}

const AddUpdate = ({
  onProceed,
  onClose,
  leadID,
  show = false,
  updates,
  onLeadUpdated
}: ILeadUpdates) => {
  const [submit, setSubmit] = React.useState<boolean>(false);
  const [lead, setLead] = useState<any[]>(updates);
  const [loading, setLoading] = useState<boolean>(true);
  const [screenWidth, setScreenWidth] = React.useState<number>(
    document.documentElement.clientWidth
  );
  const [newUpdates, setNewUpdates] = useState<IUpdateLeadTrail[]>([]);
  const LeadTags: Array<string> = Object.values(LEAD_TAGS).map((value) => value);
  const scrollRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  }, [lead]);

  const { lockBackground, unlockBackground } = useRoute();

  React.useEffect(() => {
    const resizeCallback = () => {
      setScreenWidth(document.documentElement.clientWidth);
    };
    window.addEventListener('resize', resizeCallback);

    return () => {
      window.removeEventListener('resize', resizeCallback);
    };
  }, []);

  React.useEffect(() => {
    if (show) {
      lockBackground();
    } else {
      unlockBackground();
    }
  }, [show]);

  const endStyles = {
    position: 'absolute' as 'absolute',
    top: 65,
    right: screenWidth > 550 ? 0 : '20px',
    width: screenWidth > 550 ? '681px' : '100vw',
    minHeight: 'calc(100vh - 105px)'
  };

  const startStyles = {
    position: 'absolute' as 'absolute',
    top: 65,
    right: screenWidth > 550 ? '-681px' : '-100vw',
    width: screenWidth > 550 ? '681px' : '100vw',
    minHeight: 'calc(100vh - 105px)'
  };

  const { id } = useParams<string>();

  let status;
  let statusFromEnum = '';
  function selectedStatus(tag: string) {
    switch (tag) {
      case 'NEW':
        statusFromEnum = 'COLD';
        break;
      case 'CONTACTED':
        statusFromEnum = 'COLD';
        break;
      case 'QUALIFIED':
        statusFromEnum = 'COLD';
        break;
      case 'UNRESPONSIVE':
        statusFromEnum = 'COLD';
        break;
      case 'DISQUALIFIED':
        statusFromEnum = 'COLD';
        break;
      case 'IN_NEGOTIATION':
        statusFromEnum = 'WARM';
        break;
      case 'NURTURING':
        statusFromEnum = 'WARM';
        break;
      case 'CONVERTED':
        statusFromEnum = 'CLOSED';
        break;
      default:
        break;
    }
    status = statusFromEnum;
    return status;
  }
  useEffect(() => {
    setLead([]);
    id && fetchLead(id);
  }, []);

  const fetchLead = (id: string) => {
    getSingleLead(id)
      .then((response) => {
        setLead(response.data.leadUpdates);
      })
      .catch((error: Error) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Animate play={show} start={startStyles} end={endStyles}>
      <div className="bg-white border border-primary-main px-14 pl-18 sm:pl-14 py-4 shadow-full overflow-y-auto h-[calc(100vh-100px)]">
        <div className=" flex justify-end pl-[1rem] items-center">
          <XMarkIcon onClick={onClose} className="cursor-pointer size-5" />
        </div>

        {loading && lead.length <= 0 ? (
          <div className="h-fit flex items-center justify-center bg-white">
            <Loader message={'Fetching updates, please wait...'} brollyLoader />
          </div>
        ) : (
          lead.length <= 0 && (
            <h1 className="text-[34px] text-gray-500 font-semibold py-2 ">No Update Found!</h1>
          )
        )}

        {!loading && (
          <>
            {lead.length > 0 && (
              <h3 className="text-[24px] font-semibold py-2 ">
                {`${lead.length} updates on this lead`}
              </h3>
            )}

            <div
              ref={scrollRef}
              className={lead?.length > 0 ? `h-[400px] overflow-y-auto py-4` : ''}>
              {lead?.map((update: IUpdateLeadTrail, index: number) => (
                <>
                  <div
                    key={index}
                    className="min-h-[121px] border border-primary-main rounded p-6 space-y-3">
                    <div className="">
                      <h3 className="">{update.description}</h3>
                    </div>

                    <div className="flex items-center text-[10px] space-x-8 text-[#616161] justify-end">
                      <span>{update.leadOwner}</span>
                      <span>{`${moment(update.date)?.format('ll')} | ${moment(update.date)?.format(
                        'LT'
                      )}`}</span>
                    </div>
                  </div>
                  <div className="px-8">
                    <div
                      className={clsx('border-l-[1px] border-primary-main', {
                        ['min-h-[1.5rem]']: index + 1 !== lead.length
                      })}></div>
                  </div>
                </>
              ))}
            </div>
            <Formik
              initialValues={{
                description: '',
                leadStatus: '',
                tag: ''
              }}
              validateOnChange
              validationSchema={Yup.object().shape({
                tag: Yup.string().required('Lead tag is required.'),
                description: Yup.string().required('Description is required.')
              })}
              onSubmit={(values, { setSubmitting, setValues, resetForm }) => {
                leadID;
                const payload: IUpdateLeadTrail = {
                  ...values,
                  leadStatus: selectedStatus(values.tag)
                };
                setSubmitting(true);
                updateLeadTrail(leadID, payload)
                  .then((response: any) => {
                    setValues(response.data);
                    fetchLead(leadID);
                    onLeadUpdated(new Lead(response.data));
                    //setNewUpdates((prevUpdates) => [...newUpdates, response.data]);
                  })
                  .catch((error: any) => {
                    toast.error(error.message ?? 'Failed to add update', {
                      autoClose: 3000
                    });
                  })
                  .finally(() => {
                    setSubmit(false);
                    toast.success('Update added successfully', {
                      autoClose: 5000,
                      style: {
                        zIndex: 100
                      }
                    });
                    resetForm();
                  });
              }}>
              {({
                values,
                errors,
                isSubmitting,
                handleSubmit,
                handleChange,
                handleBlur,
                touched,
                setFieldValue
              }) => (
                <form className="mt-8" onSubmit={handleSubmit}>
                  <h3 className={`text-[24px] font-semibold py-2`}>{'Add an update'}</h3>
                  <div className="">
                    <div className="">
                      <SelectMenu
                        label="Lead Tag"
                        name="tag"
                        listSelectedValue={values.tag}
                        list={LeadTags}
                        onChange={(val: string | null) => setFieldValue('tag', val)}
                        onBlur={handleBlur}
                        placeholder="Select lead tag"
                        required
                        wrapperClasses="w-full"
                        error={touched.tag ? errors.tag : undefined}
                        helperText={touched?.tag ? errors.tag : undefined}
                      />
                    </div>
                    {/* <div className="mt-5">
                  <Input
                    type="text"
                    id="leadStatus"
                    name="leadStatus"
                    onChange={handleChange}
                    onBlur={handleChange}
                    value={selectedStatus(values.tag)}
                    label="Lead Status"
                    placeholder="Status"
                  />
                </div>  */}
                    <div className="mt-7">
                      <TextArea
                        name={'description'}
                        value={values.description}
                        onChange={handleChange}
                        placeholder="Write an update..."
                        rows={3}
                        required
                        error={touched.description ? errors.description : undefined}
                        helperText={touched?.description ? errors.description : undefined}
                      />
                    </div>
                  </div>
                  <div className=" mt-7">
                    <Button
                      label="Add update"
                      className="bg-primary-main w-fit px-8 py-2 h-[45px] rounded-md"
                      onClick={handleSubmit}
                      loading={isSubmitting}
                      disabled={isSubmitting}
                    />
                  </div>
                </form>
              )}
            </Formik>
          </>
        )}
      </div>
    </Animate>
  );
};

export default AddUpdate;
