import { UserCircleIcon, XMarkIcon } from '@heroicons/react/24/solid';
import { toast } from 'react-toastify';
import Button from '../../../components/form-control/Button';
import Input from '../../../components/form-control/Input';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { startCase } from 'lodash';
import moment from 'moment';
import {
  getSingleRNPLCustomer,
  reviewRNPLApplication,
  rnplPreapproval,
  updateLead,
  updateRNPL
} from '../../../api/requests';
import SelectMenu from '../../../components/form-control/SelectMenu';

import {
  capitalizeSentence,
  getFormattedPhoneNumber,
  getPhoneNumberFromShortCodeFormat,
  getSelectOptionsFromEnum
} from '../../../utils/miscFunctions';
import { Lead } from '../../../models/lead/Lead';
import { INPUT_DATE_FORMAT } from '../../../resources/constants';
import { useEffect, useState } from 'react';
import { REPAIR_TYPES, RNPL_PAYMENT_CHANNEL, RNPL_REVIEW_STATES } from '../../../resources/enums';
import SelectDropdown from '../../../components/form-control/SelectDropdown';
import TextArea from '../../../components/form-control/TextArea';
import { ICreateRNPLDTO } from '../../../resources/interfaces';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

interface EditLeadProps {
  onProceed: () => void;
  onClose: () => void;
  rnplApplicant: any;
}

const RNPLPreapproval = ({ onProceed, onClose, rnplApplicant }: EditLeadProps) => {
  const [loading, setLoading] = useState<boolean>(false);

  return (
    <div className="">
      <Formik
        initialValues={{
          phoneNumber: getPhoneNumberFromShortCodeFormat(rnplApplicant?.customer?.phone),
          channel: ''
        }}
        enableReinitialize={true}
        validateOnChange={false}
        validateOnBlur={false}
        validationSchema={Yup.object().shape({
          phoneNumber: Yup.string().trim().required('Phone number is required.'),
          channel: Yup.string().trim().required('Network Channel is required.')
        })}
        onSubmit={(values) => {
          const payload: any = {
            phoneNumber: values.phoneNumber,
            channel: values.channel
          };

          const id = rnplApplicant.id;
          if (id) setLoading(true);
          rnplPreapproval(values.channel, rnplApplicant.applicationNumber, values.phoneNumber)
            .then((response: any) => {
              onProceed();
              toast.success(response.message && 'RNPL updated successfully.');
            })
            .catch((error: any) => {
              toast.error(error.description && 'Something went wrong');
            })
            .finally(() => setLoading(false));
        }}>
        {({
          values,
          errors,
          isSubmitting,
          handleSubmit,
          handleChange,
          handleBlur,
          setFieldValue,
          touched
        }) => (
          <form className="" onSubmit={handleSubmit}>
            <div className="p-8">
              <div className=" ">
                <p className="text-[24px] mb-10">Provide your mobile money details.</p>
                <div className="grid w-full grid-cols-1 md:grid-cols-1 gap-5  ">
                  <Input
                    type={'text'}
                    name="phoneNumber"
                    value={values.phoneNumber}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label="Your mobile money wallet number"
                    placeholder="Phone number"
                    required
                    error={touched.phoneNumber ? errors.phoneNumber : undefined}
                    helperText={touched?.phoneNumber ? errors.phoneNumber : undefined}
                    className="rounded"
                  />

                  <SelectDropdown
                    name="channel"
                    listSelectedValue={values.channel}
                    onChange={(value: string) => {
                      setFieldValue('channel', value);
                      console.log(value);
                    }}
                    label="Mobile Money network"
                    placeholder="Select network"
                    list={getSelectOptionsFromEnum(RNPL_PAYMENT_CHANNEL)}
                    required
                    error={touched.channel ? errors.channel : undefined}
                    helperText={touched?.channel ? errors.channel : undefined}
                    selectInputClassName="rounded"
                  />
                </div>
              </div>
              <div className="flex-col-reverse gap-5 flex md:flex-row  mt-10 ">
                <div className="w-full md:w-1/2">
                  <Button
                    label="Cancel"
                    onClick={onClose}
                    curved
                    className=" w-full px-[12px] py-[12px] h-[45px] rounded border border-primary-border hover:bg-primary-main "
                  />
                </div>

                <div className="w-full md:w-1/2">
                  <Button
                    label="Save"
                    className="w-full px-[12px] py-[12px] h-[45px] rounded border border-primary-border hover:bg-primary-main "
                    onClick={handleSubmit}
                    disabled={loading}
                    loading={loading}
                    curved
                    bgColor="primary"
                  />
                </div>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default RNPLPreapproval;
