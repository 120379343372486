import { Formik } from 'formik';
import { Community } from '../../../models/community/Community';
import * as Yup from 'yup';
import Input from '../../../components/form-control/Input';
import SelectDropdown, { ISelectDropdownItem } from '../../../components/form-control/SelectDropdown';
import { COMMUNITY_TYPES, NATURE_OF_INTERREST_OPTIONS, REPAIR_TYPES, TRUE_FALSE, YES_NO } from '../../../resources/enums';
import {
  getFormattedPhoneNumber,
  getPhoneNumberFromShortCodeFormat,
  getSelectOptionsFromEnum
} from '../../../utils/miscFunctions';
import Button from '../../../components/form-control/Button';
import SelectMenu from '../../../components/form-control/SelectMenu'; 
import { 
  createNewRNPL,
  getAllCustomers,
  getAllRNPL,
  getCustomerVehicules, 
} from '../../../api/requests';
import { 
  ICreateRNPLDTO 
} from '../../../resources/interfaces'; 
import TextArea from '../../../components/form-control/TextArea';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify'; 
import { Customer } from '../../../models/customer/Customer'; 
import FileUpload from '../../../components/elements/FileUpload/FileUpload';

interface IRNPLProps { 
  onClose: () => void;
  onProceed: () => void;
}

interface IFormData {
  // timestamp: string;
  customerId: number;
  vehicleId:number;
  // applicantPhone: string;
  // carNumber: string;
  repairType: string;
  otherRepairType: string;
  repairDescription: string;
  repairCost: number;
  // estimatePicture: File[];
  // frontPicture: string;
  // rightPicture: string;
  // leftPicture: string;
  // backPicture: string;
  // pictureOfDamaged: string;
  // roadworthyCertificate: File[];
  // isVehicleOnCollateral: string;
  // isOwnerOfTheCar: string;
  // ownerFullName: string;
  // ownerPhone: string;
  // natureOfInterest: string;
  ownerConsent: boolean;
} 


const AddNewRnPl = (props: IRNPLProps): JSX.Element => {
  const [submitting, setSubmitting] = useState<boolean>(false); 
  const [applicants, setApplicants] = useState<any>([]);
  const [applicantVehicleList, setApplicantVehicleList] = useState<any>([]); 
  const [vehicleValue, setVehicleValue] = useState<string>("");
  const [vehicleCardNumber, setVehicleCardNumber] = useState<string>("");
  const [estimateImg, setEstimateImg] = useState<any>()
  const [roadWorthyImg, setRoadWorthyImg] = useState<any>()

  const getAllApplicants = async () => {
 
    try {
      const response = await getAllCustomers(0,100,'',false);
      const _customers = response?.data?.content?.map((customer: any) => {
         return new Customer(customer); 
      });
  
      setApplicants(response?.data?.content);
   
    } catch (error) {
    }  
  };
 
  const getAllCustomerVehicules = async(id:string)=>{
    const response = await getCustomerVehicules(id)
    .then((response)=>{
      
      setApplicantVehicleList(response?.data?.data)
    })
    .catch((error)=>{
      console.log(error)
    })

    return response;
    
  }
   
const selectApplicantInfos = (value: string): Customer => {
  const applicant = applicants?.find((applicant: any) => value == applicant.id); 
  return applicant ;  
}

const selectedVehicleInfos = (value: string): Customer => {
  const applicant = applicantVehicleList?.find((applicant: any) => value == applicant.id); 
  return applicant ;  
}

  useEffect(() => { 
    getAllApplicants();  
  }, [])
 
  
const yesOrno = [{name:'YES', value:true}, {name:'NO', value:false}]
  return (
    <div className="py-[43px] px-0 md:px-14 w-full">
      <Formik
        initialValues={{
          customerId: 0,
          vehicleId: 0,
          repairType: '',
          otherRepairType: '',
          repairDescription: '',
          repairCost: 0,
          ownerConsent: false
        }}
        enableReinitialize={true}
        validateOnChange={true}
        validateOnBlur={true}
        validationSchema={Yup.object().shape({
          //   // timestamp: Yup.string().trim().required('Timestamp is required.'),
          //   applicantName: Yup.string().trim().required('Applicant Name is required.'),
          //   applicantVehicleList: Yup.string().trim().required('Applicant vehicle is required.'),
          //   applicantPhone: Yup.string().trim().required('Applicant Phone is required.'),
          //   carNumber: Yup.string().trim().required('Car Number is required.'),
          //   repairType: Yup.string().trim().required('Repair Type is required.'),
          repairDescription: Yup.string().trim().required('Description is required.'),
          repairCost: Yup.number()
            .required('Repair Cost is required.')
            .positive('Repair Cost must be a positive number.')
            .max(5000, 'Repair Cost must be less than or equal to 5000.'),
          //   estimatePicture: Yup.string().trim().required('Estimate Picture is required.'),
          //   frontPicture: Yup.string().trim().required('Front Picture is required.'),
          //   rightPicture: Yup.string().trim().required('Right Picture is required.'),
          //   leftPicture: Yup.string().trim().required('Left Picture is required.'),
          //   backPicture: Yup.string().trim().required('Back Picture is required.'),
          //   // pictureOfDamaged: Yup.string().trim().required('Picture Of Damaged is required.'),
          //   roadworthyCertificate: Yup.string()
          //     .trim()
          //     .required('Roadworthy Certificate is required.'),
          //   isVehicleOnCollateral: Yup.string().trim().required('Vehicle On Collateral is required.'),
          //   isOwnerOfTheCar: Yup.string().trim().required('Are you the owner of the car.'),
          //   ownerFullName: Yup.string().trim().required("Owner's Full Name is required."),
          //   ownerPhone: Yup.string().trim().required("Owner's phone number is required."),
          //   natureOfInterest: Yup.string().trim().required('Nature Of Interest is required.'),
          ownerConsent:  Yup.boolean().oneOf([true], "Owner's consent is required.").required("Owner's consent is required.")
        })}
       
        onSubmit={async (values: IFormData, { resetForm }) => {
          const payload: ICreateRNPLDTO = {
            customerId: values.customerId,
            vehicleId: values.vehicleId,
            repairType: values.repairType == 'OTHER' ? values.otherRepairType : values.repairType,
            repairDescription: values.repairDescription,
            repairCost: values.repairCost,
            ownerConsent: values.ownerConsent 
          };

          console.log(payload)
         await createNewRNPL(payload)
            .then((response) => {
              console.log(response) 
            })
            .catch((error) => {
              console.log(error);
            })
            .finally(() => {
              resetForm();
              props.onProceed();
            });
        }}>
        {({
          values,
          errors,
          isSubmitting,
          handleSubmit,
          handleChange,
          handleBlur,
          setFieldValue,
          touched
        }) => (
          <form className="" onSubmit={handleSubmit}>
            <div className="w-full flex flex-col space-y-8">
              <h3 className="font-heading font-[600] text-[20px] text-[#455A64] leading-[30px] m-0 p-0">
                Repair Now, Pay Later Application Form
              </h3>
              <SelectDropdown
                name="customerId"
                listSelectedValue={values.customerId}
                onChange={(value: string) => {
                  setFieldValue('customerId', value);
                  getAllCustomerVehicules(value);
                  setApplicantVehicleList([]);
                }}
                label="Applicant"
                placeholder="Select applicant"
                list={applicants?.map((applicant: any) => ({
                  id: `${applicant.id}`,
                  name: `${applicant.firstName} ${applicant.lastName}`,
                  value: `${applicant.id}`
                }))}
                required
                error={touched.customerId ? errors.customerId : undefined}
                helperText={touched?.customerId ? errors.customerId : undefined}
                selectInputClassName="rounded"
              />
              {/* <Input
                type={'text'}
                name="ownerFullName"
                value={
                  values.applicantName
                    ? `${selectApplicantInfos(values.applicantName)?.firstName} ${
                        selectApplicantInfos(values.applicantName)?.lastName
                      }`
                    : ''
                }
                onChange={handleChange}
                onBlur={handleBlur}
                label="Owner's Full Name"
                placeholder="Enter owner's full name"
                required
                error={touched.ownerFullName ? errors.ownerFullName : undefined}
                helperText={touched?.ownerFullName ? errors.ownerFullName : undefined}
                className="rounded"
              /> */}
              {/* <Input
                type={'number'}
                name="ownerPhone"
                value={`${selectApplicantInfos(values.applicantName)?.phone}`}
                onChange={handleChange}
                onBlur={handleBlur}
                label="Owner's Phone Number"
                placeholder="Enter owner's phone number"
                required
                error={touched.ownerPhone ? errors.ownerPhone : undefined}
                helperText={touched?.ownerPhone ? errors.ownerPhone : undefined}
                className="rounded"
              /> */}
              <SelectDropdown
                name="vehicleId"
                listSelectedValue={values.vehicleId}
                onChange={(value: string) => {
                  setFieldValue('vehicleId', value);
                  const slicedValue = value.split(' - ');
                  setVehicleCardNumber(slicedValue[0]);
                }}
                label="Applicant Vehicle(s)"
                placeholder="Select applicant vehicle(s)"
                list={applicantVehicleList?.map((vehicle: any) => ({
                  name: `${vehicle.registrationNumber} - ${vehicle.model} ${vehicle.registrationYear}`,
                  value: `${vehicle.id}`
                }))}
                required
                error={touched.vehicleId ? errors.vehicleId : undefined}
                helperText={touched?.vehicleId ? errors.vehicleId : undefined}
                selectInputClassName="rounded"
              />

              {/* <Input
                type={'text'}
                name="carNumber"
                value={vehicleCardNumber}
                onChange={handleChange}
                onBlur={handleBlur}
                label="Car Number"
                placeholder="Enter car number"
                required
                error={touched.carNumber ? errors.carNumber : undefined}
                helperText={touched?.carNumber ? errors.carNumber : undefined}
                className="rounded"
              /> */}

              <div className={` ${values.repairType == 'OTHER' && 'rounded border flex flex-col space-y-8 p-3 border-gray-300'}`}>
                <SelectDropdown
                  name=""
                  listSelectedValue={values.repairType}
                  onChange={(value: string) => {
                    setFieldValue('repairType', value);
                  }}
                  label="Repair Type"
                  placeholder="Select repair type"
                  list={getSelectOptionsFromEnum(REPAIR_TYPES)}
                  required
                  error={touched.repairType ? errors.repairType : undefined}
                  helperText={touched?.repairType ? errors.repairType : undefined}
                  selectInputClassName="rounded"
                />

                <TextArea
                  name="otherRepairType"
                  value={values.otherRepairType}
                  onChange={handleChange}
                  onBlur={handleChange} 
                  placeholder="Provide any other type..."
                  required
                  error={touched.otherRepairType ? errors.otherRepairType : undefined}
                  helperText={touched?.otherRepairType ? errors.otherRepairType : undefined}
                  classes={`rounded ${values.repairType !== 'OTHER' && 'hidden'}`}
                
                />
              </div>
              <TextArea
                name="repairDescription"
                value={values.repairDescription}
                onChange={handleChange}
                onBlur={handleChange}
                label="Repair Description"
                placeholder="Provide description..."
                required
                error={touched.repairDescription ? errors.repairDescription : undefined}
                helperText={touched?.repairDescription ? errors.repairDescription : undefined}
                classes="rounded"
              />

              <Input
                type={'number'}
                name="repairCost"
                value={values.repairCost}
                onChange={handleChange}
                onBlur={handleBlur}
                label="Repair Cost"
                placeholder="Enter repair cost"
                required
                error={touched.repairCost ? errors.repairCost : undefined}
                helperText={touched?.repairCost ? errors.repairCost : undefined}
                className="rounded"
              />
              {/* <FileUpload
                onFileLoad={(images: any) => {
                  if (images && images[0]) { 
                    setEstimateImg(images[0])
                    console.log(images[0])
                  }
                }}
                showFileMax={false}
                onFileDeleted={() => setEstimateImg(null)}
                cropImage={false}
                title="Upload image"
                label="Estimate Picture"
                maxHeight={200}
                maxWidth={300}
                imageContainerHeight={'300px'}
                imageAlt={'Estimate Picture'}
              />
            <FileUpload
                onFileLoad={(images: any) => {
                  if (images && images[0]) { 
                    setEstimateImg(images[0])
                    console.log(images[0])
                  }
                }}
                showFileMax={false}
                onFileDeleted={() => setEstimateImg(null)}
                cropImage={false}
                title="Upload image"
                label="Picture of Damaged Area"
                maxHeight={200}
                maxWidth={300}
                imageContainerHeight={'300px'}
                imageAlt={'Picture of Damaged Area'}
              /> */}
              {/* <FileUpload
                onFileLoad={(images: any) => {
                  if (images && images[0]) {
                    setRoadWorthyImg(images[0]); 
                  }
                }}
                showFileMax={false}
                onFileDeleted={() => setRoadWorthyImg(null)}
                cropImage={false}
                title="Upload image"
                label="Roadworthy Certificate"
                maxHeight={200}
                maxWidth={300}
                imageContainerHeight={'700px'}
                imageAlt={'Roadworthy Certificate'}
              /> */}
              {/* <SelectDropdown
                name="isVehicleOnCollateral"
                listSelectedValue={values.isVehicleOnCollateral}
                onChange={(value: string) => {
                  setFieldValue('isVehicleOnCollateral', value);
                }}
                label="Is Vehicle On Collateral?"
                placeholder="Yes or No"
                list={getSelectOptionsFromEnum(YES_NO)}
                required
                error={touched.isVehicleOnCollateral ? errors.isVehicleOnCollateral : undefined}
                helperText={
                  touched?.isVehicleOnCollateral ? errors.isVehicleOnCollateral : undefined
                }
                selectInputClassName="rounded"
              /> */}
              {/* <SelectDropdown
                name="isOwnerOfTheCar"
                listSelectedValue={values.isOwnerOfTheCar}
                onChange={(value: string) => {
                  setFieldValue('isOwnerOfTheCar', value);
                }}
                label="Is Apllicant The Owner Of The Car?"
                placeholder="Yes or No"
                list={getSelectOptionsFromEnum(YES_NO)}
                required
                error={touched.isOwnerOfTheCar ? errors.isOwnerOfTheCar : undefined}
                helperText={touched?.isOwnerOfTheCar ? errors.isOwnerOfTheCar : undefined}
                selectInputClassName="rounded"
              /> */}

              {/* <SelectDropdown
                name="natureOfInterest"
                listSelectedValue={values.natureOfInterest}
                onChange={(value: string) => {
                  setFieldValue('natureOfInterest', value);
                }}
                label="Nature Of Interest"
                placeholder="Select option"
                list={getSelectOptionsFromEnum(NATURE_OF_INTERREST_OPTIONS)}
                required
                error={touched.natureOfInterest ? errors.natureOfInterest : undefined}
                helperText={touched?.natureOfInterest ? errors.natureOfInterest : undefined}
                selectInputClassName="rounded"
              /> */}
              <SelectDropdown
                name="ownerConsent"
                listSelectedValue={values.ownerConsent}
                onChange={(value: boolean) => {
                  setFieldValue('ownerConsent', value);
                  console.log(value)
                }}
                label="Does Owner Consent To RNPL?"
                placeholder="Yes or No"
                list= {yesOrno?.map((val: any) => ({
                  id: `${val.id}`,
                  name: `${val.name}`,
                  value: `${val.value}`
                }))} 
                // list= {[{name:'YES', value:true}, {name:'NO', value:false}]} 
             
                required
                error={touched.ownerConsent ? errors.ownerConsent : undefined}
                helperText={touched?.ownerConsent ? errors.ownerConsent : undefined}
                selectInputClassName="rounded"
              />
              <div className="flex-col-reverse gap-5 flex md:flex-row  mt-10 ">
                <div className="w-full md:w-1/2">
                  <Button
                    label="Cancel"
                    onClick={props.onClose}
                    curved
                    className=" w-full px-[12px] py-[12px] h-[45px] rounded border border-primary-border hover:bg-primary-main "
                  />
                </div>

                <div className="w-full md:w-1/2">
                  <Button
                    label="Save"
                    className="w-full px-[12px] py-[12px] h-[45px] rounded border border-primary-border hover:bg-primary-main "
                    onClick={handleSubmit}
                    curved
                    bgColor="primary"
                    loading={submitting}
                    disabled={submitting}
                  />
                </div>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default AddNewRnPl;
