import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import useRoute from '../../../../hooks/routesHook';
import useUserAccount from '../../../../hooks/userAccountHook';
import { User } from '../../../../models/User';
import FileUpload from '../../../elements/FileUpload/FileUpload';
import Button from '../../../form-control/Button';
import Input from '../../../form-control/Input';
import TextArea from '../../../form-control/TextArea';
import {
  CheckCircleIcon,
  ChevronDownIcon,
  PencilIcon,
  PlusIcon,
  XMarkIcon
} from '@heroicons/react/20/solid';
import clsx from 'clsx';
import {
  checkInsurerSlugExists,
  getInsurerProfile,
  toggleInsurerStatus,
  updateInsurerStore
} from '../../../../api/requests';
import { IUpdateInsurerStoreDTO } from '../../../../resources/interfaces';
import useImage from '../../../../hooks/imageHook';
import {
  DELETE_IMAGE_ENDPOINTS,
  INSURER_FILE_TYPES,
  UPLOAD_IMAGE_ENDPOINTS
} from '../../../../resources/enums';
import { toast } from 'react-toastify';
import ColorPicker from '../../../form-control/ColorPicker';
import CheckBoxFontSelector from '../../../form-control/CheckFontSelector';
import { copyToClipboard, getClientWebsiteUrl } from '../../../../utils/miscFunctions';
import Loader from '../../../Loader';
import { FaTimesCircle } from 'react-icons/fa';
import PopOnMe from '../../../elements/poponme';
import { Insurer } from '../../../../models/insurer/Insurer';
import ShareComponent from '../../../share/ShareComponent';
import { useQuery, useQueryClient } from '@tanstack/react-query';

function InsurerStoreSetup(): JSX.Element {
  const { getDashboardBase } = useRoute();
  const { getProfile, updateInsurerInState } = useUserAccount();
  const user = new User(getProfile());
  const [benefitFields, setBenefitFields] = useState<IBenefitField[]>([
    { benefit: null, id: null, position: 1 }
  ]);
  const [insurerLogoFile, setInsurerLogoFile] = useState<File | null>(null);
  const [insurerBannerFile, setInsurerBannerFile] = useState<File | null>(null);
  const [insurerBannerMobileFile, setInsurerBannerMobileFile] = useState<File | null>(null);
  const [insurer, setInsurer] = useState<Insurer>();
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [checkingSlug, setCheckingSlug] = useState<boolean>();
  const [slugAvailable, setSlugAvailable] = useState<boolean>(true);
  const [logoDeleted, setLogoDeleted] = useState<boolean>(false);
  const [bannerDeleted, setBannerDeleted] = useState<boolean>(false);
  const [mobileBannerDeleted, setMobileBannerDeleted] = useState<boolean>(false);
  const { uploadImage, removeImage } = useImage();
  const [defaultThemeColor, setDefaultThemeColor] = useState<string>('');
  const [defaultFontColor, setDefaultFontColor] = useState<string>('');
  const [themeColor, setThemeColor] = useState<string>('');
  const [fontColor, setFontColor] = useState<string>('');

  const storeUrl = `${getClientWebsiteUrl()}/${insurer?.slug}`;

  const getInsurer = async () => {
    try {
      const response: any = await getInsurerProfile();


      const insurerData = new Insurer(response);
      setInsurer(insurerData);
      updateInsurerInState(response);

      // Set default colors when insurer data is loaded
      setDefaultThemeColor(insurerData.themeColour);
      setDefaultFontColor(insurerData.fontColour);
      setThemeColor(insurerData.themeColour);
      setFontColor(insurerData.fontColour);
    } catch (error) {
      console.error('Failed to load insurer data:', error);
    }
  };


  const resetColors = () => {
    setThemeColor(defaultThemeColor);
    setFontColor(defaultFontColor);
  };

  const { isLoading } = useQuery({
    queryKey: ['getInsurer'],
    queryFn: () => getInsurer()
  });


  useEffect(() => {
    if (insurer?.benefits) {
      const formattedBenefits: IBenefitField[] = insurer?.benefits?.map(
        (benefit: string, index: number) => ({
          benefit: benefit,
          id: null,
          position: index + 1
        })
      );

      formattedBenefits.push({ benefit: null, id: null, position: formattedBenefits?.length + 1 });

      setBenefitFields(formattedBenefits);
    }
    resetColors();
  }, [insurer]);

  function handleChangeBenefit(field_value: any, index: number) {
    const _membersFields = [...benefitFields];

    _membersFields[index].benefit = field_value;

    setBenefitFields(_membersFields);
  }

  function handleRemoveBenefitField(i: any) {
    const values = [...benefitFields];
    values.splice(i, 1);

    values.forEach((value: any, index: any) => {
      values[index].position = index + 1;
    });
    setBenefitFields(values);
  }

  function handleAddBenefitField() {
    const values = [...benefitFields];
    values.push({ benefit: null, id: null, position: benefitFields.length + 1 });
    setBenefitFields(values);
  }

  const submitHandler = (values: any) => {
    if (insurer?.isActive() && !insurer.logo && !insurerLogoFile) {
      toast.error('Please upload a logo', {
        autoClose: 5000
      });
      return;
    }

    if (insurer?.isActive() && !insurer.banner && !insurerBannerFile) {
      toast.error('Please upload a hero image', {
        autoClose: 5000
      });
      return;
    }

    if (insurer?.isActive() && !insurer.bannerMobile && !insurerBannerMobileFile) {
      toast.error('Please upload a hero image for mobile screens', {
        autoClose: 5000
      });
      return;
    }

    if (!slugAvailable) {
      toast.error('The store url provided is not available', {
        autoClose: 5000
      });
      return;
    }

    setSubmitting(true);
    const benefits: string[] = [];

    benefitFields.forEach((benefit: IBenefitField) => {
      if (benefit.benefit !== null) {
        benefits.push(benefit.benefit);
      }
    });

    const requests: any[] = [];

    if (insurerLogoFile) {
      requests.push(
        uploadImage(
          insurerLogoFile,
          `${UPLOAD_IMAGE_ENDPOINTS.INSURER_FILE}?fileType=${INSURER_FILE_TYPES.LOGO}`,
          'put'
        )
      );
    }

    if (insurerBannerFile) {
      requests.push(
        uploadImage(
          insurerBannerFile,
          `${UPLOAD_IMAGE_ENDPOINTS.INSURER_FILE}?fileType=${INSURER_FILE_TYPES.BANNER}`,
          'put'
        )
      );
    }

    if (insurerBannerMobileFile) {
      requests.push(
        uploadImage(
          insurerBannerMobileFile,
          `${UPLOAD_IMAGE_ENDPOINTS.INSURER_FILE}?fileType=${INSURER_FILE_TYPES.BANNER_MOBILE}`,
          'put'
        )
      );
    }

    const updateData: IUpdateInsurerStoreDTO = {
      description: values.description,
      benefits: benefits,
      tagline: values.tagline,
      slug: values.slug,
      fontColour: fontColor,
      themeColour: themeColor
    };

    requests.push(updateInsurerStore(updateData));

    if (!insurerLogoFile && logoDeleted) {
      requests.push(removeImage(DELETE_IMAGE_ENDPOINTS.INSURER_LOGO));
    }

    if (!insurerBannerFile && bannerDeleted) {
      requests.push(removeImage(DELETE_IMAGE_ENDPOINTS.INSURER_BANNER));
    }

    // if (!insurerBannerMobileFile && mobileBannerDeleted) {
    //   requests.push(removeImage(DELETE_IMAGE_ENDPOINTS.INSURER_BANNER))
    // }

    Promise.all(requests)
      .then((response: any) => {
        getInsurer();
        toast.success('Store updated successfully', {
          autoClose: 3000
        });
      })
      .catch((error: any) => {
        setSubmitting(false);
      })
      .finally(() => setSubmitting(false));
  };

  const checkSlugExists = (slug: string) => {
    setCheckingSlug(true);
    checkInsurerSlugExists(slug)
      .then((response: any) => {
        if (slug !== insurer?.slug) {
          setSlugAvailable(false);
        }
      })
      .catch((error: any) => {
        setSlugAvailable(true);
      })
      .finally(() => setCheckingSlug(false));
  };

  const getPublishActions = () => {
    const actions = [];

    if (insurer?.isActive()) {
      actions.push({
        label: 'Unpublish',
        callback: () => unPublishInsurer()
        // data: data
      });
    }

    if (!insurer?.isActive()) {
      actions.push({
        label: 'Publish',
        callback: () => publishInsurer()
        // data: data
      });
    }

    return actions;
  };

  const publishInsurer = () => {
    if (!insurer?.logo) {
      toast.error('Kindly upload and save your logo before publishing your store', {
        autoClose: 3000
      });
      return;
    }

    if (!insurer?.banner) {
      toast.error('Kindly upload and save your banner before publishing your store', {
        autoClose: 3000
      });
      return;
    }

    if (!insurer?.bannerMobile) {
      toast.error(
        'Kindly upload and save your banner for mobile screens before publishing your store',
        {
          autoClose: 3000
        }
      );
      return;
    }

    if (!insurer?.tagline) {
      toast.error('Tagline is required before publishing store.', {
        autoClose: 3000
      });
      return;
    }

    if (!insurer?.description) {
      toast.error('Kindly provide your company description before publishing your store.', {
        autoClose: 3000
      });
      return;
    }

    toggleInsurerStatus(insurer?.id ?? '', true)
      .then((response: any) => {
        setInsurer(new Insurer(response.data));
        toast.success('Store published successfully.', {
          autoClose: 3000
        });
      })
      .catch((error: any) => {
        toast.error('Failed to publish your store. Please try again later', {
          autoClose: 3000
        });
      });
  };

  const unPublishInsurer = () => {
    toggleInsurerStatus(insurer?.id ?? '', false)
      .then((response: any) => {
        setInsurer(new Insurer(response.data));
        toast.success('Store unpublished successfully.', {
          autoClose: 3000
        });
      })
      .catch((error: any) => {
        toast.error('Failed to unpublish your store. Please try again later', {
          autoClose: 3000
        });
      });
  };

  const ShareStoreUrlElem = (): JSX.Element => {
    return (
      <div className="flex items-center gap-x-2">
        <Button
          className="border-[1px] bg-[#8CEB8C] border-[#8CEB8C] rounded-[8px] w-fit h-[30px] !text-[12px] text-dark p-0 py-0"
          label="Copy store url"
          onClick={() => {
            copyToClipboard(storeUrl);
            toast.success('URL copied to clipboard');
          }}
        />
        <ShareComponent title={''} text={''} url={storeUrl} withShareBtn={true} />
      </div>
    );
  };

  console.log("Insurer:", insurer)
  console.log("insurer data:",)

  return (
    <>
      {isLoading && !insurer &&
        <div className="min-h-full h-40 w-full flex items-center justify-center">
          <Loader message='Loading...' brollyLoader/>
        </div>}
      {!isLoading && insurer && (
        <Formik
          initialValues={{
            description: insurer.description ?? '',
            tagline: insurer?.tagline ?? '',
            slug: insurer?.slug ?? ''
          }}
          // validateOnBlur
          validationSchema={Yup.object().shape({
            description: Yup.string().required('Insurer store description is required.'),
            tagline: Yup.string().required('Company tagline is required'),
            slug: Yup.string().required('Slug is required')
          })}
          onSubmit={(values) => {
            submitHandler(values);
          }}>
          {({
            values,
            errors,
            isSubmitting,
            handleSubmit,
            handleChange,
            handleBlur,
            setFieldValue,
            touched
          }) => (
            <form className=" md:px-[45px] py-[30px] bg-white" onSubmit={handleSubmit}>
              <div className="flex items-center gap-2 mb-[36px] px-2 md:px-0 justify-between">
                <h3 className="font-semibold text-[30px] text-dark">Store</h3>
                <div className="flex gap-x-4 items-center">
                  <div>
                    <PopOnMe actions={getPublishActions()}>
                      <div className="flex items-center cursor-pointer">
                        <span
                          className={clsx('w-2 h-2 bg-primary-main rounded-[50%] mr-2', {
                            ['bg-success-main']: insurer?.isActive()
                          })}></span>
                        <span className="font-bold text-[14px] font-poppins">
                          {insurer.isActive() ? 'Published' : 'Unpublished'}
                        </span>
                        <ChevronDownIcon className="h-5 w-5" />
                      </div>
                    </PopOnMe>
                  </div>
                  <Link
                    to={`${getDashboardBase()}/store/preview`}
                    className="border border-primary-main hover:bg-primary-main rounded-[8px] px-6 h-[37px] text-dark items-center flex font-poppins text-[13px] font-normal"
                    target={'_blank'}>
                    Preview
                  </Link>
                </div>
              </div>

              <div className="lg:px-14">
                <div className="grid grid-cols-1 items-center gap-5 md:gap-7 mt-5">
                  <FieldWrapper label={'Company Logo'} description="Size should be 200px X 200px">
                    <div className="w-[17rem]">
                      <FileUpload
                        onFileLoad={(images: any) => {
                          if (images && images[0]) {
                            setInsurerLogoFile(images[0]);
                            setLogoDeleted(false);
                          }
                        }}
                        showFileMax={false}
                        onFileDeleted={() => {
                          setInsurerLogoFile(null);
                          setLogoDeleted(true);
                        }}
                        savedImage={insurer.logo}
                        maxHeight={200}
                        maxWidth={200}
                        imageContainerHeight={'200px'}
                        imageAlt={'Company Logo'}
                      />
                    </div>
                  </FieldWrapper>

                  <FieldWrapper label="Store Hero Image" description="Size should be 800px X 240px">
                    <div className="">
                      <FileUpload
                        onFileLoad={(images: any) => {
                          if (images && images[0]) {
                            setInsurerBannerFile(images[0]);
                            setBannerDeleted(false);
                          }
                        }}
                        maxHeight={240}
                        maxWidth={800}
                        imageContainerHeight={'240px'}
                        showFileMax={false}
                        savedImage={insurer.banner}
                        onFileDeleted={() => {
                          setInsurerBannerFile(null);
                          setBannerDeleted(true);
                        }}
                        imageAlt={'Store Hero Image'}
                      />
                    </div>
                  </FieldWrapper>

                  <FieldWrapper
                    label="Store Hero Image (Mobile)"
                    description="Size should be 500px X 900px">
                    <div className="w-full md:w-[20rem]">
                      <FileUpload
                        onFileLoad={(images: any) => {
                          if (images && images[0]) {
                            setInsurerBannerMobileFile(images[0]);
                            setMobileBannerDeleted(false);
                          }
                        }}
                        maxHeight={500}
                        maxWidth={300}
                        imageContainerHeight={'300px'}
                        showFileMax={false}
                        savedImage={insurer.bannerMobile}
                        onFileDeleted={() => {
                          setInsurerBannerMobileFile(null);
                          setMobileBannerDeleted(true);
                        }}
                        imageAlt={'Store Hero Image'}
                      />
                    </div>
                  </FieldWrapper>

                  <FieldWrapper label="Store Tagline" description="100 characters max">
                    <div className="">
                      <div className="flex justify-end">
                        <span className="text-[13px] text-dark">{values.tagline?.length}/100</span>
                      </div>
                      <TextArea
                        name="tagline"
                        value={values.tagline}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="Enter company tagline"
                        required
                        error={
                          touched.tagline && errors.tagline !== undefined
                            ? String(errors.tagline)
                            : undefined
                        }
                        helperText={
                          touched?.tagline && errors.tagline !== undefined
                            ? String(errors.tagline)
                            : undefined
                        }
                        rows={2}
                        max={100}
                      />
                    </div>
                  </FieldWrapper>

                  <FieldWrapper label="Store Description" description="1000 characters max">
                    <div className="">
                      <div className="flex justify-end">
                        <span
                          className="mr-4 cursor-pointer"
                          title="Edit description"
                          onClick={() => {
                            const elem = document.getElementById('descriptionFieldRef');
                            // @ts-ignore
                            const contentEnd = elem?.value?.length;

                            // @ts-ignore
                            elem.setSelectionRange(contentEnd, contentEnd);
                            elem?.focus();
                          }}>
                          <PencilIcon className="w-4" />
                        </span>
                        <span className="text-[13px] text-dark">
                          {values.description?.length}/1000
                        </span>
                      </div>
                      <TextArea
                        name="description"
                        value={values.description}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="Enter company description"
                        required
                        error={
                          touched.description && errors.description !== undefined
                            ? String(errors.description)
                            : undefined
                        }
                        helperText={
                          touched?.description && errors.description !== undefined
                            ? String(errors.description)
                            : undefined
                        }
                        rows={6}
                        id={'descriptionFieldRef'}
                        max={1000}
                      />
                    </div>
                  </FieldWrapper>

                  <FieldWrapper
                    label="Policy Benefits"
                    description="Benefits are perks you give out to users for buying your insurance policy.">
                    <div className="space-y-2">
                      {benefitFields
                        .sort((a: any, b: any) => (a.position > b.position ? 1 : -1))
                        .map((field: IBenefitField, index: any) => (
                          <div className="flex space-x-2" key={index}>
                            <Input
                              type="text"
                              placeholder="Enter benefit"
                              className="invite-input"
                              value={field.benefit || ''}
                              onChange={(e) => {
                                handleChangeBenefit(e.target.value, index);
                              }}
                            />

                            <button
                              type="button"
                              className={clsx('remove-button', {
                                ['hidden']: index === 0 && benefitFields.length === 1
                              })}
                              onClick={() => handleRemoveBenefitField(index)}>
                              <XMarkIcon className="w-4" />
                            </button>
                          </div>
                        ))}
                      {/* {isAddMoreBenefits && ( */}
                      <div className="add-field">
                        <button
                          type="button"
                          className={clsx('flex space-x-2 items-center mt-2 text-link-main')}
                          onClick={() => handleAddBenefitField()}>
                          <PlusIcon className="w-4" /> Add another benefit
                        </button>
                        <span />
                      </div>
                      {/* // )} */}
                    </div>
                  </FieldWrapper>

                  <FieldWrapper label="Store Url" headerActions={ShareStoreUrlElem()}>
                    <div className="flex space-x-2">
                      <Input
                        type="text"
                        name="slug"
                        placeholder="Enter slug"
                        value={values.slug}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          handleChange(e);
                          if (e.target.value) {
                            checkSlugExists(e.target.value);
                          } else {
                            setSlugAvailable(false);
                          }
                        }}
                        prepend={
                          <span className="border-r-[1px] border-r-grey-300 py-2 px-2 bg-gray-200">{`${getClientWebsiteUrl()}/`}</span>
                        }
                        className={'pl-[200px] !py-2'}
                        prependClasses={'!pl-0'}
                        append={
                          checkingSlug ? (
                            <Loader />
                          ) : slugAvailable ? (
                            <CheckCircleIcon className="w-7 text-[#00c07b] mr-2" />
                          ) : (
                            <FaTimesCircle
                              className="w-7 text-danger-main mr-2"
                              title="Slug already taken"
                            />
                          )
                        }
                      />
                    </div>
                  </FieldWrapper>

                  {/* Slug */}
                  <FieldWrapper label="Select your custom colors">
                    <>
                      <div className="flex gap-10">
                        <div className="">
                          <span className="text-sm">Brand Color</span>
                          <div className="">
                            <ColorPicker
                              initialColor={insurer.themeColour}
                              onColorChange={(value) => {
                                setThemeColor(value);
                              }}
                              className="h-12 w-24 rounded"
                            />
                          </div>
                        </div>

                        <div className="">
                          <span className="text-sm block pb-1">Font Color</span>
                          <div className="">
                            <CheckBoxFontSelector
                              initialColor={insurer.fontColour}
                              className="h-10 w-10 rounded"
                              setFontColour={(value) => setFontColor(value)}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="pt-2">
                        <span
                          className="text-sm text-blue-600 cursor-pointer "
                          onClick={resetColors}>
                          Reset Branding
                        </span>
                      </div>
                    </>
                  </FieldWrapper>
                </div>

                <div className="flex-row items-center md:flex md:justify-end md:mt-[56px] mt-8 ">
                  <div className="">
                    <Button
                      loading={submitting}
                      label="Save"
                      type="submit"
                      className="w-full md:w-[100px] bg-primary-main rounded h-[45px]"
                    />
                  </div>
                </div>
              </div>
            </form>
          )}
        </Formik>
      )}
    </>
  );
}

export default InsurerStoreSetup;

interface IFieldWrapperProps {
  label: string | JSX.Element;
  description?: string;
  children: JSX.Element;
  headerActions?: JSX.Element;
}

const FieldWrapper = (props: IFieldWrapperProps) => {
  return (
    <div className="border-[1px] border-[#5C5C5C]/[0.13] rounded-[8px] py-4">
      <div className="border-b-[1px] border-b-[#5C5C5C]/[0.13] px-4 pb-2 flex justify-between items-center pr-8">
        <div>
          <h3 className="font-semibold text-[21px]">{props.label}</h3>
          <p className="text-[13px]">{props.description}</p>
        </div>
        {props.headerActions && (
          <>{props.headerActions}</>
          // <Button
          //   fullWidth={false}
          //   label={'Generate from Ai'}
          //   className="bg-[#8CEB8C] border-[1px] border-[#8CEB8C] rounded-[8px] w-fit h-[30px] !text-[12px] text-dark p-0 py-0"
          // />
        )}
      </div>
      <div className="md:px-8 pt-2">{props.children}</div>
    </div>
  );
};

export interface IBenefitField {
  benefit: string | null;
  id: string | null;
  position: number;
}

interface IFormData { }
