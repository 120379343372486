import { useEffect, useState } from 'react';
import useUserAccount from '../../../hooks/userAccountHook';
import { XMarkIcon } from '@heroicons/react/24/solid';
type QuoteIframProps = {
  onProceed: () => void;
  onClose: () => void;
  isRenewPolicy?: boolean;
  policyNumber?: string;
  signup?: any;
};

const QuoteIframe = ({ onClose, isRenewPolicy = false, policyNumber, signup }: QuoteIframProps) => {
  const { getInsurer } = useUserAccount();
  const [loading, setLoading] = useState(true);
  const insurer = getInsurer();
  const signupParam = encodeURIComponent(JSON.stringify(signup));
  const [widgetUrl, setWidgetUrl] = useState<string>(getWidgetUrl);

  useEffect(() => {
    setWidgetUrl(getWidgetUrl());
  }, [insurer, isRenewPolicy, policyNumber, signupParam]);

  const handleLoad = () => {
    setLoading(false);
  };

  function getWidgetUrl(): string {
    return `${process.env.REACT_APP_CLIENT_WEBSITE}/widgets/admin?insurer=${insurer?.id}&renewPolicy=${isRenewPolicy}&policyNumber=${policyNumber}&signup=${signupParam}`;
  }

  return (
    <>
      {loading && (
        <div
          className="h-screen flex items-center"
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)'
          }}>
          Loading...
        </div>
      )}

      {/* {showWidget && ( */}
      <div className="">
        <div className="flex justify-end w-full">
          <XMarkIcon className="h-5 w-5 cursor-pointer" onClick={onClose} />
        </div>
        <iframe
          onLoad={handleLoad}
          src={widgetUrl}
          title="Brolly Insurance... "
          width="100%"
          height="700"></iframe>
      </div>

      {/* )} */}
    </>
  );
};
export default QuoteIframe;
