/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */

import axios, { AxiosError } from 'axios';
import _ from 'lodash';
import {
  ICompleteAccountDTO,
  ICreateAdminAccountDTO,
  ILoginDTO,
  IRequestErrorResponse,
  IReviewPolicyDTO,
  IUpdatePolicyDTO,
  ICreateTeamDTO,
  IAddSupportedVehicle,
  ICreateAgentDTO,
  IInitiateHubtelPaymentDTO,
  IVerifyMobilePaymentDTO,
  ICreateCommunityDTO,
  IAddCommunityContactDTO,
  IUpdateTeamDTO,
  IAddTeamPaymentDTO,
  IUpdateInsurerStoreDTO,
  IDownloadData,
  ISaveQuoteDTO,
  ITeamMetricsDTO,
  IRenewPolicyDTO,
  IClaimsPrelimQuestionsDTO,
  IClaimsAccidentCircumstanceDTO,
  IClaimsDriverParticularsDTO,
  IClaimsOtherVehicleInvolvedDTO,
  IClaimOtherAffectedPersonDTO,
  IClaimsPoliceInvolvementDTO,
  IClaimsOtherInformationDTO,
  IVehicleDamageFormData,
  IClaimsDeclarationDTO,
  IAddPartnerDTO,
  IUpdatePartnerQuotaDTO,
  IExportFuelSalesDataDTO,
  ICreateBlogPost,
  ICreateRNPLDTO
} from '../resources/interfaces';
import store, { RootState } from '../states';
import {
  ADMIN_PAYMENTS_STATUS_FILTER_OPTIONS,
  ADMIN_POLICY_STATUS_ACTIONS,
  CLAIM_FILTER_OPTIONS,
  COMMUNITY_CHANNEL_OPTIONS,
  PAYMENTS_FILTER_OPTIONS,
  POLICY_DOCUMENT_TYPES,
  POLICY_REVIEW_STATES,
  RNPL_PAYMENT_CHANNEL,
  RNPL_REVIEW_STATES
} from '../resources/enums';
import { IUpdateLead, IcreateLead } from '../models/lead/ILead';
import { ICreateInsurer } from '../models/insurer/IInsurer';
import { IgenerateDiscount } from '../models/discount/IDiscount';
import { SelectedDateRange } from '../components/dashboard/date-range-selector/datePicker';
import { IUpdateLeadTrail } from '../views/admin/leads/AddUpdate';
import { IAssignRelationshipManager } from '../views/admin/partners/AssignRelationshipMager';
import { IAssignPolicyManager } from '../views/admin/policies/AssignPolicyManger';
import { ISetCommission } from '../views/admin/partners/SetCommission';
import { IChangeAccessRight } from '../views/admin/partners/ChangeAccessRight';
import { IPolicyReferee } from '../models/policy/IPolicy';
import { IVerifyPolicyRefereeOTP } from '../views/admin/policies/PolicyEdit';
import { IChangePasswordDTO, IResetPasswordDTO } from '../views/admin/settings/Password';
import { isDevelopment } from '../utils/miscFunctions';
import { IPayoutTransactionDetailsDTO } from '../views/admin/partners/MarkAsPaidModal';
import { HIDE_TOP_LOADING, SESSION_EXPIRED, SHOW_TOP_LOADING } from '../states/slices/globalSlice';
import { ICreateTaskFormProps } from '../types';
import { SIGN_OUT } from '../states/slices/userAccountSlice';
import { IAssignMemberDTO } from '../views/admin/partners/assignMember';
import { IEditPaymentDTO } from '../views/admin/payments/PaymentEditModal';
import { IMarkPolicyAsDoneDTO } from '../views/admin/policies/MarkPolicyAsDone';

export const apiUrl = process.env.REACT_APP_API;

// export const apiUrl = process.env.REACT_APP_STAGING_API;

export const api = axios.create({
  baseURL: `${apiUrl}/api`,
  timeout: isDevelopment() ? 200000 : 60000,
  withCredentials: true
});

export const sseApi = axios.create({
  baseURL: `${apiUrl}/`,
  timeout: isDevelopment() ? 200000 : 60000,
  withCredentials: true
});

// Add interceptors to instance
api.interceptors.response.use(
  (response) => {
    store.dispatch(HIDE_TOP_LOADING());
    return response;
  },
  (error: AxiosError) => {
    store.dispatch(HIDE_TOP_LOADING());
    const errorStruct: IRequestErrorResponse = {
      message: 'Error',
      status: 500
    };

    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    if (error.response) {
      const errorResponse = error.response;
      errorStruct.status = errorResponse.status;
      const data: Record<any, any> = <any>errorResponse.data;

      switch (errorResponse.status) {
        case 400: // Timeout
          errorStruct.message = 'Error';
          errorStruct.description = data?.detail;
          // @ts-ignore
          errorStruct.detail = error.response?.data?.detail;
          break;
        case 401: // Unauthorized
          errorStruct.message = 'Unauthorized';
          errorStruct.description =
            data?.message ?? 'You are unauthorized to perform this operation.';
          // toast.error("You are either unauthorized to perform this operation or your token has expired.", {
          //   autoClose: 3000
          // })
          // @ts-ignore
          errorStruct.detail = error.response?.data?.detail;
          store.dispatch(SESSION_EXPIRED(true));
          store.dispatch(SIGN_OUT());
          break;
        case 403: // Forbidden
          errorStruct.message = 'Forbidden';
          errorStruct.description = data?.message ?? 'You cannot perfofrm this action.';
          // @ts-ignore
          errorStruct.detail = error.response?.data?.detail;
          break;
        case 408: // Bad Request
          errorStruct.message = 'Connection Timeout';
          errorStruct.description = 'Connection timeout. Try again';
          // @ts-ignore
          errorStruct.detail = error.response?.data?.detail;
          break;
        case 500: // Server Error
          errorStruct.message = 'Request Error';
          errorStruct.description = 'An error occurred serving request. Try again later.';
          // @ts-ignore
          errorStruct.detail = error.response?.data?.detail;
          break;
        default: // System Error
          errorStruct.message = 'System Error';
          break;
      }

      if (!errorStruct.description) errorStruct.description = data?.message;

      return Promise.reject(errorStruct);
    } else {
      // Something happened in setting up the request that triggered an Error
      return error;
    }
  }
);

api.interceptors.request.use((request) => {
  store.dispatch(SHOW_TOP_LOADING());
  // Set bearer token
  const storeState: RootState = store.getState();
  if (!_.isEmpty(storeState?.userAccount)) {
    const token = storeState?.userAccount?.ACCOUNT?.token;

    if (token && request && request.headers) {
      // @ts-ignore
      request.headers = {
        ...request.headers,
        Authorization: 'Bearer ' + token
      };
    }
  }

  return request;
});

// AUTH REQUESTS
export function loginWithPassword(payload: ILoginDTO): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    api
      .post(`/auth/password/signIn`, payload)
      .then((response: any) => {
        const token = response?.headers?.authorization?.split(' ')[1];
        response.data.data.token = token;
        resolve(response?.data);
      })
      .catch((error: AxiosError) => {
        reject(error);
      });
  });
}

export function getAccountProfile(): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    api
      .get(`/admin/staff/account/getProfile`)
      .then((response: any) => {
        resolve(response?.data);
      })
      .catch((error: AxiosError) => {
        reject(error?.response?.data);
      });
  });
}

export function completeAccount(payload: ICompleteAccountDTO): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    api
      .post(`/auth/completeAccount`, payload)
      .then((response: any) => {
        if (response.status === 200 || response.status === 201) {
          resolve(response);
        } else {
          reject(response);
        }
      })
      .catch((error: AxiosError) => {
        reject(error?.response?.data);
      });
  });
}

export function resetAccountPAssword(admin: boolean, email: string): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    api
      .post(`/auth/forgottenPassword?admin=${admin}&email=${email}`)
      .then((response: any) => {
        if (response.status === 200 || response.status === 201) {
          resolve(response);
        } else {
          reject(response);
        }
      })
      .catch((error: AxiosError) => {
        reject(error?.response?.data);
      });
  });
}

export function createAdminAccount(payload: ICreateAdminAccountDTO): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    api
      .post(`/admin/teams/staff`, payload)
      .then((response: any) => {
        if (response.status === 200 || response.status === 201) {
          resolve(response);
        } else {
          reject(response);
        }
      })
      .catch((error: AxiosError) => {
        reject(error?.response?.data);
      });
  });
}

export function signoutUser(accessRight?: string): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    api
      .post(`/auth/signOut?accessRights=${accessRight}`)
      .then((response: any) => {
        resolve(response);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}

export function sendRefereeOTP(phone: string, policyNumber: string | undefined): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    api
      .post(`/auth/${policyNumber}/refereeOTP?phone=${phone}`)
      .then((response: any) => {
        resolve(response);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}

// Send Customer Phone OTP
export function sendCustomerOTP(phone: string): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    api
      .post(`/auth/requestOTP?phone=${phone}`)
      .then((response: any) => {
        resolve(response?.data);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}

// Send otp to any phone number
export function sendOTPToPhone(phone: string): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    api
      .get(`/resources/request/otp?phone=${phone}`)
      .then((response: any) => {
        resolve(response?.data);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}
// Send Customer Email OTP
export function sendCustomerEmailOTP(email: string): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    api
      .post(`/auth/requestOTP?email=${email}`)
      .then((response: any) => {
        resolve(response);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}

// Send Customer Email
export function sendCustomerEmail(mail: any): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    api
      .get(`/admin/customers/send/email`, mail)
      .then((response: any) => {
        resolve(response);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}

// VerifyCustomerPhone
export function verifyPhoneOTP(payload: any): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    api
      .post(`/auth/verify/otp`, payload)
      .then((response: any) => {
        resolve(response.data);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}

export function verifyNonCustomerPhoneOTP(payload: any): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    api
      .post(`/resources/verify/otp`, payload)
      .then((response: any) => {
        resolve(response.data);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}

// Verify referee phone
export function adminPoliciesVerifyPhoneOTP(payload: IVerifyPolicyRefereeOTP): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    api
      .post(`/admin/policies/verify/phone/otp`, payload)
      .then((response: any) => {
        resolve(response);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
}

// Change user password
export function changeUserPassword(payload: IChangePasswordDTO): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    api
      .post(`/auth/changePassword`, payload)
      .then((response: any) => {
        resolve(response);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}

// Reset user password
export function resetUserPassword(payload: IResetPasswordDTO): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    api
      .post(`/auth/resetPassword`, payload)
      .then((response: any) => {
        resolve(response);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}
// Share referral code
export function shareReferral(): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    api
      .get(`admin/staff/account/refer`)
      .then((response: any) => {
        resolve(response?.data?.data);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}
//Verify Vehicle ID
export function verifyVehicle(id: number): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    api
      .get(`services/vehicle/verify/${id}`)
      .then((response: any) => {
        resolve(response?.data);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
}
//Verify Customer ID
export function verifyCustomerID(policyId: string): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    api
      .get(`services/id/verification?policyNumber=${policyId}`)
      .then((response: any) => {
        resolve(response);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}

// ENE AUTH REQUESTS

// *********************************************
// REQUEST TO CREATE LEADS

export function createLeads(formData: IcreateLead): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    api
      .post(`/admin/leads/`, formData)
      .then((response: any) => {
        resolve(response);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}

// REQUEST TO GET LEADS
export function getAllLeads(
  page: number,
  size: number,
  expireFrom?: string,
  expireTo?: string,
  createFrom?: string,
  createTo?: string,
  searchParam?: string,
  leadSource?: string,
  leadStatus?: string,
  staffId?: string,
  me?: boolean,
  tag?: string
): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    api
      .get(
        `/admin/leads/?me=${me}&page=${page}&size=${size}${
          createFrom ? `&createFrom=${createFrom}` : ''
        }${createTo ? `&createTo=${createTo}` : ''}${
          expireFrom ? `&expireFrom=${expireFrom}` : ''
        }${expireTo ? `&expireTo=${expireTo}` : ''}${
          searchParam ? `&searchParam=${searchParam}` : ''
        }${leadSource ? `&leadSource=${leadSource}` : ''}${
          leadStatus ? `&leadStatus=${leadStatus}` : ''
        }${staffId ? `&staffId=${staffId}` : ''}
        ${tag ? `&tag=${tag}` : ''}
      `
      )
      .then((response: any) => {
        resolve(response?.data?.data);
        console.log(response.data);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}

// REQUEST TO GET A SINGLE LEAD
export function getSingleLead(id: string): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    api
      .get(`/admin/leads/${id}`)
      .then((response: any) => {
        resolve(response?.data);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}
// UPDATE LEAD
export function updateLead(id: string, payload: IUpdateLead): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    api
      .put(`/admin/leads/${id}`, payload)
      .then((response: any) => {
        resolve(response);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}
// Assign LEAD
export function assignLead(id: string, adminEmail: string): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    api
      .post(`/admin/leads/${id}/assignTo/${adminEmail}`)
      .then((response: any) => {
        resolve(response);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
}
// Lead trail update
export function updateLeadTrail(id: string, payload: IUpdateLeadTrail): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    api
      .post(`/admin/leads/${id}/updates`, payload)
      .then((response: any) => {
        resolve(response?.data);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
}

// Get Leads Quotes
export function getLeadsQuotes(id: string): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    api
      .get(`/admin/leads/${id}/quotes`)
      .then((response: any) => {
        resolve(response?.data);
        console.log(response?.data);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
}
// Admin create
export function adminCreateTask(eventType: string, id: string, payload: any): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    api
      .post(`/admin/tasks/${eventType}/${id}`, payload)
      .then((response: any) => {
        resolve(response?.data);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
}

//Admin update tasks]
export function updateTask(taskId: string, payload: ICreateTaskFormProps): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    api
      .put(`/admin/tasks/${taskId}`, payload)
      .then((response: any) => {
        resolve(response?.data);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
}

// Get all tasks
export function getAllTasks(
  page: number,
  size?: number,
  staffId?: string,
  from?: string,
  to?: string
): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    api
      .get(
        `/admin/tasks/?page=${page}&size=${size}${staffId ? `&staff_id=${staffId}` : ''}${
          from ? `&from=${from}` : ''
        }${to ? `&to=${to}` : ''}`
      )
      .then((response: any) => {
        resolve(response?.data?.data);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}

//Get my overdue tasks
export function getMyOverdueTasks(): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    api
      .get(`/admin/tasks/assigned?status=OVERDUE`)
      .then((response: any) => {
        resolve(response?.data);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}

// Get single task
export function getTaskById(taskId: string): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    api
      .get(`/admin/tasks/${taskId}`)
      .then((response: any) => {
        resolve(response?.data?.data);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}

// Comment on a task
export function addCommentToTask(taskId: string, comment: any): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    api
      .patch(`/admin/tasks/${taskId}`, comment)
      .then((response: any) => {
        resolve(response?.data?.data);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}
// Get Admins
export function getAdmins(): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    api
      .get(`/admin/leads/admins`)
      .then((response: any) => {
        resolve(response);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
}

// Get adminAssignLead
export function adminAssignLead(id: string): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    api
      .patch(`/admin/leads/${id}/assignToSelf`)
      .then((response: any) => {
        resolve(response);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
}

// *****************************************
// ALL POLICIES
export function getAllPolicies(
  page: number,
  size: number,
  searchParam?: string,
  reviewStage?: string,
  expiredFrom?: string,
  expiredTo?: string,
  createFrom?: string,
  createTo?: string,
  startFrom?: string,
  startTo?: string,
  protectionType?: string,
  vehicleUsages?: string,
  leadSource?: string,
  managerId?: string,
  teamId?: string
): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    api
      .get(
        `/admin/policies/?page=${page}&size=${size}${
          searchParam ? `&searchParam=${searchParam}` : ''
        }${reviewStage ? `&reviewStage=${reviewStage}` : ''}${
          expiredFrom ? `&expiredFrom=${expiredFrom}` : ''
        }${expiredTo ? `&expiredTo=${expiredTo}` : ''}${
          createFrom ? `&createFrom=${createFrom}` : ''
        }${createTo ? `&createTo=${createTo}` : ''}${startTo ? `&startTo=${startTo}` : ''}${
          startFrom ? `&startFrom=${startFrom}` : ''
        }${protectionType ? `&protectionType=${protectionType}` : ''}${
          vehicleUsages ? `&vehicleUsages=${vehicleUsages}` : ''
        }${leadSource ? `&leadSource=${leadSource}` : ''}${
          managerId ? `&managerId=${managerId}` : ''
        }${teamId ? `&teamId=${teamId}` : ''}`
      )
      .then((response: any) => {
        resolve(response?.data?.data);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}

// ALL POLICIES
export function getAllPolicyRenewals(
  page: number,
  size: number,
  searchParam?: string,
  reviewStage?: string,
  expiredFrom?: string,
  expiredTo?: string,
  createFrom?: string,
  createTo?: string,
  startFrom?: string,
  startTo?: string,
  protectionType?: string,
  vehicleUsages?: string,
  leadSource?: string,
  managerId?: string,
  teamId?: string
): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    api
      .get(
        `/admin/policies/renewals?page=${page}&size=${size}${
          searchParam ? `&searchParam=${searchParam}` : ''
        }${reviewStage ? `&reviewStage=${reviewStage}` : ''}${
          expiredFrom ? `&expiredFrom=${expiredFrom}` : ''
        }${expiredTo ? `&expiredTo=${expiredTo}` : ''}${
          createFrom ? `&createFrom=${createFrom}` : ''
        }${createTo ? `&createTo=${createTo}` : ''}${startTo ? `&startTo=${startTo}` : ''}${
          startFrom ? `&startFrom=${startFrom}` : ''
        }${protectionType ? `&protectionType=${protectionType}` : ''}${
          vehicleUsages ? `&vehicleUsages=${vehicleUsages}` : ''
        }${leadSource ? `&leadSource=${leadSource}` : ''}${
          managerId ? `&managerId=${managerId}` : ''
        }${teamId ? `&teamId=${teamId}` : ''}`
      )
      .then((response: any) => {
        resolve(response?.data?.data);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}

export function getInsurerPoliciesById(
  id: string,
  page: number,
  size: number,
  searchParam?: string,
  reviewStage?: POLICY_REVIEW_STATES
): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    api
      .get(
        `/admin/insurers/insurer/${id}/policies/?page=${page}&size=${size}${
          searchParam ? `&searchParam=${searchParam}` : ''
        }${reviewStage ? `&reviewStage=${reviewStage}` : ''}`
      )
      .then((response: any) => {
        resolve(response?.data?.data);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}

export function updatePolicy(policyId: string, data: Partial<IUpdatePolicyDTO>): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    api
      .put(`/admin/policies/${policyId}`, data)
      .then((response: any) => {
        resolve(response);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}
// SINGLE POLICY BY ID
export function getPolicy(id: string): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    api
      .get(`/admin/policies/${id}`)
      .then((response: any) => {
        resolve(response?.data);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}

// Create a task on a policy
export function createTaskOnPolicy(policyNumber: string, payload: any): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    api
      .post(`/admin/policies/${policyNumber}/task`, payload)
      .then((response: any) => {
        resolve(response?.data);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}

export function cancelPolicyRequest(
  policyId: string,
  reason: string,
  terminate: boolean
): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    api
      .patch(`/admin/policies/${policyId}/cancel?reasons=${reason}&terminate=${terminate}`)
      .then((response: any) => {
        resolve(response?.data);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}

export function updatePolicyStatus(
  policyId: string,
  status: ADMIN_POLICY_STATUS_ACTIONS
): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    api
      .patch(`/admin/policies/${policyId}/approve`)
      .then((response: any) => {
        resolve(response?.data);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}

export function changePolicyReviewState(policyId: string, payload: IReviewPolicyDTO): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    api
      .post(`/admin/policies/${policyId}/review`, payload)
      .then((response: any) => {
        resolve(response?.data);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}

// ADD POLICY UPDATE
export function addPolicyUpdate(policyId: string, payload: { description: string }): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    api
      .post(`/admin/policies/${policyId}/updates`, payload)
      .then((response: any) => {
        resolve(response?.data);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}

// ADD POLICY REFEREE
export function addPolicyReferee(policyId: string, payload: IPolicyReferee): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    api
      .post(`/admin/policies/${policyId}/addReferee`, payload)
      .then((response: any) => {
        resolve(response?.data);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}

export function saveQuote(quoteData: ISaveQuoteDTO): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    api
      .post(`/quote/vehicle/persist`, quoteData)
      .then((response: any) => {
        resolve(response?.data);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}

export function createPolicyFromQuote(quoteId: string): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    api
      .get(`/admin/quotes/${quoteId}/generate/policy`)
      .then((response: any) => {
        resolve(response?.data);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}

export function renewPolicy(policyNumber: string, data: ISaveQuoteDTO, startDate?: string) {
  return new Promise<any>((resolve, reject) => {
    api
      .post(`/admin/quotes/${policyNumber}/renew?startsOn=${startDate}`, data)
      .then((response: any) => {
        if (response.status === 200) {
          resolve(response.data);
        }
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}

// LIST POLICIES BY CUSTOMER ID
export function getPoliciesByCustomer(id: string): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    api
      .get(`/admin/policies/customer/${id}`)
      .then((response: any) => {
        resolve(response?.data?.data);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}

// CHANGE POLICY STATUS
export function changePolicyStatus(policyState: string, id: string): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    api
      .get(`/admin/quotes/`)
      .then((response: any) => {
        resolve(response?.data);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}

// ASSIGN POLICY TO MANAGER
export function changePolicyManager({ policyId, managerId }: IAssignPolicyManager): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    api
      .post(`/admin/policies/${policyId}/assignTo/${managerId}`)
      .then((response: any) => {
        resolve(response?.data);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}

// Mark policy creation as complete
export function markPolicyCreationAsDone(
  policyNumber: string,
  payload: IMarkPolicyAsDoneDTO
): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    api
      .patch(`/admin/policies/${policyNumber}`, payload)
      .then((response: any) => {
        resolve(response?.data);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}

// Get all home insurancre policies
export function fetchAllHomePolicies(page: number, size: number): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    api
      .get(`/admin/home/policies/?page=${page}&size=${size}`)
      .then((response: any) => {
        resolve(response?.data?.data);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}

export function getAdminTeamPolicies(page: number, size: number): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    api
      .get(`/admin/teams/policies/?page=${page}&size=${size}`)
      .then((response: any) => {
        resolve(response?.data?.data);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}

export function sharePolicyAgreementDocumentLink(
  policyNumber: string,
  type: string,
  channel: string,
  identifier: string | number
): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    api
      .get(`/admin/policies/${policyNumber}/document/${type}/share/${channel}/${identifier}`)
      .then((response: any) => {
        resolve(response?.data);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}

export function adminRequestFeedbackFromCustomer(
  policyNumber: string,
  channel: string,
  identifier: string | number
): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    api
      .get(`/admin/policies/${policyNumber}/feedback/share/${channel}/${identifier}`)
      .then((response: any) => {
        resolve(response?.data);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}
// *************************************//

// GETTING ALL QUOTES
export function getAllQuotes(page: number, size: number, phone?: string): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    api
      .get(`/admin/quotes/?page=${page}&size=${size}${phone ? `&phone=${phone}` : ''}`)
      .then((response: any) => {
        resolve(response);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}

// GETTING SINGLE QUOTE
export function getSingleQuote(ref: string): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    api
      .get(`/admin/quotes/${ref}`)
      .then((response: any) => {
        resolve(response);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}
// GETTING ALL USER QUOTES
export function getAllUserQuotes(): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    api
      .get(`/admin/quotes/allUserQuotes`)
      .then((response: any) => {
        resolve(response);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}

// *********************************
// GET SINGLE CUSTOMER
export function getSingleCustomer(id: string): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    api
      .get(`/admin/customers/${id}`)
      .then((response) => {
        resolve(response?.data?.data);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}
// GET CUSTOMER LIST
export function getAllCustomers(
  page?: number,
  size?: number,
  name?: string,
  elite?: boolean
): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    api
      .get(
        `/admin/customers/?page=${page}&size=${size}${name ? `&name=${name}` : ''}&elite=${elite}`
      )
      .then((response: any) => {
        resolve(response); 
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}

// GET CUSTOMER VEHICLES
export function getCustomerVehicules(
  customerID: string, 
): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    api
      .get(
        `/admin/customers/${customerID}/vehicles`
      )
      .then((response: any) => {
        resolve(response); 
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}

// Edit Customer
export function updateCustomer(
  customerID: string,
  email: string,
  phone: string,
  otherPhone: string
): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    api
      .patch(
        `/admin/customers/${customerID}?email=${email}&phone=${phone}&otherPhone=${otherPhone}`
      )
      .then((response: any) => {
        resolve(response);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}

// ADD CUSTOMER UPDATE
export function addCustomerUpdate(
  custmoreId: string,
  payload: { description: string }
): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    api
      .post(`/admin/customers/${custmoreId}`, payload)
      .then((response: any) => {
        resolve(response?.data);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}

// ****************************************/
// GENERATE DISCOUNT CODE

export function generateDiscountCode(discountData: IgenerateDiscount): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    api
      .post(`/admin/discounts/`, discountData)
      .then((response: any) => {
        resolve(response?.data);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}

// GET ALL DISCOUNT CODE
export function getAllDiscounts(page: number, size: number): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    api
      .get(`/admin/discounts/?page=${page}&size=${size}`)
      .then((response: any) => {
        resolve(response?.data);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}

// DELETE DISCOUNT CODE
export function deleteDiscountCode(id: number): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    api
      .delete(`/admin/discounts/${id}`)
      .then((response: any) => {
        resolve(response);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}

// CHANGE DISCOUNT CODE STATUS
export function changeDiscountCodeStatus(id: number, status: string): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    api
      .put(`/admin/discounts/${id}/${status}`)
      .then((response: any) => {
        resolve(response);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}

export function applyDiscount(policyNumber: string, discountCode: string): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    api
      .get(`/discounts/${discountCode}/apply/${policyNumber}`)
      .then((response: any) => {
        resolve(response?.data?.data);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}
//***************************** */

// Update  Insurer status

export function updateInsurerStatus(id: string, status: string): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    api
      .patch(`/admin/insurers/${id}/${status}`, status)
      .then((response: any) => {
        resolve(response?.data);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}

// Update  Insurer DTO
export function updateInsurer(id: string, payload: any): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    api
      .put(`/admin/insurers/${id}`, payload)
      .then((response: any) => {
        resolve(response?.data);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}

// CREATE NEW INSURER
export function createNewInsurer(payload: ICreateInsurer): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    api
      .post(`/admin/insurers/insurer`, payload)
      .then((response: any) => {
        resolve(response?.data);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}

// RETRIEVE SINGLE INSURER
export function getSingleInsurer(id: string): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    api
      .get(`/admin/insurers/${id}`)
      .then((response: any) => {
        resolve(response?.data?.data);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}

// RETRIEVE ALL INSURERS
export function getAllInsurers(page: number, size: number): Promise<any> {
  return api.get(`/admin/insurers/?page=${page}&size=${size}`);
}

export function getInsurerProfile(): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    api
      .get(`/admin/insurers/profile`)
      .then((response: any) => {
        resolve(response?.data.data);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}

export function checkInsurerSlugExists(slug: string): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    api
      .get(`/admin/insurers/check/slug/${slug}`)
      .then((response: any) => {
        if (response.status === 200) {
          resolve(response?.data);
        } else {
          reject('Slug available');
        }
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}

export function toggleInsurerStatus(insurerId: string, status: boolean): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    api
      .post(`/admin/insurers/${insurerId}/activate?active=${status}`)
      .then((response: any) => {
        resolve(response?.data);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}

export function updateInsurerStore(data: IUpdateInsurerStoreDTO): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    api
      .put(`/admin/insurers/store`, data)
      .then((response: any) => {
        resolve(response?.data.data);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}

// GET STAFF ACCOUNT PROFILE
export function getAdminAccounts(): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    api
      .get(`/admin/teams/staffs`)
      .then((response: any) => {
        resolve(response?.data);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}

export function getASingleTeam(id: string): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    api
      .get(`/admin/teams/${id}`)
      .then((response: any) => {
        resolve(response?.data);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}

//SEND STAFF INVITE
export function sendStaffInviteLink(id: string): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    api
      .get(`/admin/teams/staff/${id}/send-invite-link`)
      .then((response: any) => {
        resolve(response?.data);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}

//Update admin account
export function updateAdminAccount(id: string, payload: any): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    api
      .put(`/admin/teams/staff/${id}`, payload)
      .then((response: any) => {
        resolve(response?.data);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}

// INVITE INSURER AGENT /api/admin/insurers/inviteInsurerAgent
export function inviteInsurerUser(payload: any): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    api
      .post(`/admin/insurers/invite/user`, payload)
      .then((response: any) => {
        resolve(response?.data);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}

//GET RELATIONSHIP MANAGER TEAMS
export function getRelationshipManagerTeams(userId: string): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    api
      .get(`/admin/teams/staff/${userId}/teamsManaging`)
      .then((response: any) => {
        resolve(response?.data);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}

//GET RELATIONSHIP MANAGER
export function getRelationshipManager(managerId: string): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    api
      .get(`/admin/teams/staff/${managerId}`)
      .then((response: any) => {
        resolve(response?.data?.data);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}

// GET ACCOUNT
export function getStaffAccountProfile(): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    api
      .get(`/admin/staff/account/getProfile`)
      .then((response: any) => {
        resolve(response?.data);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}

// GET staff account by id
export function getStaffAccountById(id: string): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    api
      .get(`/admin/teams/staff/${id}`)
      .then((response: any) => {
        resolve(response?.data);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}

// GET ALL TEAMS
export function getAllTeams(page?: number, size?: number): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    api
      .get(`/admin/teams/?page=${page}&size=${size}`)
      .then((response: any) => {
        resolve(response?.data?.data);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}
// CREATE TEAM
export function createTeam({ name, type }: ICreateTeamDTO): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    api
      .post(`/admin/teams/?name=${name}&type=${type}`)
      .then((response: any) => {
        resolve(response);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
}

export function updateTeam(teamId: string, payload: IUpdateTeamDTO): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    api
      .put(`/admin/teams/${teamId}`, payload)
      .then((response: any) => {
        resolve(response?.data?.data);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
}

// ADD STAFF TO TEAM
export function assignMember({ id, staffEmail }: IAssignMemberDTO): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    api
      .patch(`/admin/teams/${id}/${staffEmail}`)
      .then((response: any) => {
        resolve(response);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
}
// DELETE ADMIN ACCOUNT
export function deleteAdminAccount(id: string): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    api
      .delete(`/admin/teams/${id}`)
      .then((response: any) => {
        resolve(response);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
}
// DISABLE ADMIN ACCOUNT
export function disableAdminAccount(id: string, status: boolean): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    api
      .patch(`/admin/teams/staff/${id}/activate/${status}`)
      .then((response: any) => {
        resolve(response?.data);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
}

// UPDATE STAFF PROFILE
// export  function updateStaffProfile(id: string, payload: any) {
//   try {
//     const response = await api.patch(`/admin/teams/staffPersonal/${id}`, payload);
//     return response;
//   } catch (error) {
//     throw error;
//   }
// }

// UPDATE STAFF PROFILE
export function updateStaffProfile(id: string, payload: any): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    api
      .patch(`/admin/teams/staffPersonal/${id}`, payload)
      .then((response: any) => {
        resolve(response.data);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
}
// Get Team PROFILE
export function getTeamProfile(): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    api
      .get(`/admin/teams/profile`)
      .then((response: any) => {
        resolve(response?.data);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
}
// Get Team staffs
export function getTeam(id: string): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    api
      .get(`/admin/teams/${id}/staffs`)
      .then((response: any) => {
        resolve(response?.data?.data);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
}

// Get supervisor staffs
export function getSupervisorSurbodinates(): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    api
      .get(`/admin/teams/staff/subordinates`)
      .then((response: any) => {
        resolve(response?.data?.data);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
}

// CREATE AGENT ACCOUNT
export function addAgentsToMyTeam(payload: ICreateAgentDTO): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    api
      .patch(`/admin/teams/createAgentAccount`, payload)
      .then((response: any) => {
        resolve(response);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
}
// EDIT TEAM
export function editTeam(teamId: string, payload: any): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    api
      .put(`/admin/teams/${teamId}`, payload)
      .then((response: any) => {
        resolve(response?.data);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
}
// INVITE MEMBER TO TEAM
export function inviteMemberToTeam(teamID: string, payload: ICreateAgentDTO): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    api
      .post(`/admin/teams/${teamID}/inviteMember`, payload)
      .then((response: any) => {
        resolve(response);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
}
// INVITE AGENT TO TEAM
export function inviteAgent(payload: ICreateAgentDTO): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    api
      .post(`/admin/teams/inviteAgent`, payload)
      .then((response: any) => {
        resolve(response);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
}
// DELETE AGENT FROM TEAM
export function deleteAgent(agentId: string): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    api
      .delete(`/admin/teams/agent/${agentId}`)
      .then((response: any) => {
        resolve(response);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
}

//SET STAFF SUPERVISOR
export function getStaffActivities(): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    api
      .get(`/admin/staffs/activities`)
      .then((response: any) => {
        resolve(response?.data?.data);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
}

//GET STAFF ACTIVITIES
export function setStaffSupervisor(staffId: number, managerId: number): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    api
      .put(`/admin/teams/staff/${staffId}/supervisor/${managerId}`)
      .then((response: any) => {
        resolve(response?.data?.data);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
}

// SET AGENCY PAYMENT ACCOUNT
export function setAgencyPaymentAccount(payload: IAddTeamPaymentDTO): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    api
      .post(`/admin/teams/paymentAccount`, payload)
      .then((response: any) => {
        resolve(response?.data);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
}
// GET AGENCY PAYMENT ACCOUNT
export function getAgencyPaymentAccount(teamID: string): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    api
      .get(`/admin/teams/${teamID}/paymentAccount`)
      .then((response: any) => {
        resolve(response?.data?.data);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
}

// GENERATE AGENCY PAYOUT
export function generateAgencyPayout(teamID: string, reason: string): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    api
      .get(`/admin/teams/${teamID}/generatePayout?reason=${reason}`)
      .then((response: any) => {
        resolve(response);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
}

// Get policies in payout
export function generatePoliciesInPayout(payoutId: string, fileType: string): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    api
      .get(`/admin/insurers/payouts/${payoutId}/download?fileType=${fileType}`)
      .then((response: any) => {
        resolve(response?.data?.data);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
}

export function updateAgencyName(teamId: string, payload: { name: string }): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    api
      .put(`/admin/teams/${teamId}/updateName`, payload)
      .then((response: any) => {
        resolve(response);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
}

// ASSIGN AGENCY MANAGER
export function assignAgencyManager({
  teamId,
  managerId
}: IAssignRelationshipManager): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    api
      .post(`/admin/teams/${teamId}/assignAgencyAdmin/${managerId}`)
      .then((response: any) => {
        resolve(response);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
}

// ASSIGN RELATIONSHIP MANAGER
export function setRelationshipManager({
  teamId,
  managerId
}: IAssignRelationshipManager): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    api
      .patch(`/admin/teams/${teamId}/assignRelationshipManager/${managerId}`)
      .then((response: any) => {
        resolve(response);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
}

// CHANGE ADMIN ACCESS RIGHT
export function changeAccessRight({ staffID, accessRight }: IChangeAccessRight): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    api
      .put(`/admin/teams/staff/${staffID}/accessRight?accessRight=${accessRight}`)
      .then((response: any) => {
        resolve(response);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
}

// ASSIGN COMMISSION RATE
export function setCommissionRate({ teamId, rate, type }: ISetCommission): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    api
      .post(`/admin/teams/${teamId}/rate/${rate}?type=${type}`)
      .then((response: any) => {
        resolve(response);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
}

// GET COMMISSION DATA FOR SINGLE TEAM
export function getAgencyCommissions(teamId: string): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    api
      .get(`/admin/teams/agency/${teamId}/commissions`)
      .then((response: any) => {
        resolve(response?.data?.data);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
}
// GET PAYOUT DATA FOR SINGLE TEAM
export function superGetInsurerPayoutById(
  id: string,
  page: number,
  size: number,
  searchParam?: string
): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    api
      .get(
        `/admin/insurers/insurer/${id}/payouts/?page=${page}&size=${size}${
          searchParam ? `&searchParam=${searchParam}` : ''
        }`
      )
      .then((response: any) => {
        resolve(response?.data?.data);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
}
// GET PAYOUT DATA FOR SINGLE TEAM
export function getAgencyPayouts(teamId: string): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    api
      .get(`/admin/teams/agency/${teamId}/payouts`)
      .then((response: any) => {
        resolve(response?.data?.data);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
}

// GET PAYOUT DATA FOR SINGLE TEAM
export function getTeamsPayouts(teamId: string): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    api
      .get(`/admin/teams/${teamId}/payouts`)
      .then((response: any) => {
        resolve(response?.data?.data);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
}

// GET SINGLE PAYOUT BY ID
export function getPayoutById(teamId: string, payoutId: string): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    api
      .get(`/admin/teams/${teamId}/payouts/${payoutId}`)
      .then((response: any) => {
        resolve(response?.data?.data);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
}
// GET INSURER PAYOUT BY ID
export function getInsurerPayoutById(payoutId: string): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    api
      .get(`/admin/insurers/payouts/${payoutId}`)
      .then((response: any) => {
        resolve(response?.data?.data);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
}

// REQUEST FOR PAYOUT
export function requestPayout(teamId: string, payoutId: string): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    api
      .patch(`/admin/teams/agency/${teamId}/payouts/${payoutId}/review`)
      .then((response: any) => {
        resolve(response?.data?.data);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
}

// REQUUEST FOR PAYOUT
export function markAsPaid(teamId: string, payoutId: string): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    api
      .patch(`/admin/teams/agency/${teamId}/payouts/${payoutId}/paid`)
      .then((response: any) => {
        resolve(response?.data?.data);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
}

// GET AGENCY METRICS FOR SINGLE TEAM
export function getSingleAgencyTeamMetrics(teamId: string): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    api
      .get(`/admin/metrics/agency/${teamId}`)
      .then((response: any) => {
        resolve(response?.data?.data);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
}
// approvePayout
export function approvePayoutBySuper(teamId: string, payoutId: string): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    api
      .patch(`/admin/teams/agency/${teamId}/payouts/${payoutId}/approve`)
      .then((response: any) => {
        resolve(response?.data);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
}

// markPayoutAsPaid
export function markPayoutAsPaid(
  payoutId: string,
  teamId: string,
  payload: IPayoutTransactionDetailsDTO
): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    api
      .patch(`/admin/teams/agency/${teamId}/payouts/${payoutId}/paid`, payload)
      .then((response: any) => {
        resolve(response?.data);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
}

// GET TEAM PERFORMANCE
export function getSingleTeamPerformance(teamId: string): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    api
      .get(`/admin/teams/${teamId}/performance`)
      .then((response: any) => {
        resolve(response?.data?.data);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
}

export function getTeamPerformanceMetrics(teamId: string): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    api
      .get(`/admin/metrics/${teamId}/performance`)
      .then((response: any) => {
        resolve(response?.data?.data);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
}
//Get All staff
export function getAllAdminAccounts(): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    api
      .get(`/admin/teams/staffs`)
      .then((response: any) => {
        resolve(response?.data?.data);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
}

// GET
// ​/api​/admin​/teams​/staff​/{id}​/copy-invite-link
// copyStaffInviteLink
export function generateAgentLink(id: string): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    api
      .get(`/admin/teams/staff/${id}/copy-invite-link`)
      .then((response: any) => {
        resolve(response?.data?.data);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
}

// Get all vehicles
export function getAllVehicles(page: number, size: number): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    api
      .get(`/vehicle/supported/getAll?page=${page}&size=${size}`)
      .then((response: any) => {
        resolve(response);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
}
// Add supported vehicles
export function addVehicle(payload: IAddSupportedVehicle): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    api
      .post(`/vehicle/supported/add`, payload)
      .then((response: any) => {
        resolve(response);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}

// Dashboard
export function GetDashboardGenericsData({ from, to }: SelectedDateRange) {
  return new Promise<any>((resolve, reject) => {
    api
      .get(`/admin/metrics/agency${from ? `?from=${from}` : ''}${to ? `&to=${to}` : ''}`)
      .then((response: any) => {
        resolve(response);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}

export function GetDashboardAgencyMetrics(teamId: string, { from, to }: SelectedDateRange) {
  return new Promise<any>((resolve, reject) => {
    api
      .get(`/admin/metrics/agency/${teamId}${from ? `?from=${from}` : ''}${to ? `&to=${to}` : ''}`)
      .then((response: any) => {
        resolve(response?.data?.data);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}

export function GetRelationshipManagerMetrics(managerId: string, { from, to }: SelectedDateRange) {
  return new Promise<any>((resolve, reject) => {
    api
      .get(`/admin/metrics/admin/${managerId}/sales?from=${from}&to=${to}`)
      .then((response: any) => {
        resolve(response?.data?.data);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}

export function getDashboardGenericsDataSuper({ from, to }: SelectedDateRange) {
  return new Promise<any>((resolve, reject) => {
    api
      .get(`/admin/metrics/super?from=${from}&to=${to}`)
      .then((response: any) => {
        resolve(response);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}
export function getDashboardPayments() {
  return new Promise<any>((resolve, reject) => {
    api
      .get(`/admin/metrics/payments`)
      .then((response: any) => {
        resolve(response);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}

export function getDashboardGraphDataSuper({ quarter, year }: { quarter: string; year: string }) {
  return new Promise<any>((resolve, reject) => {
    api
      .get(`/admin/metrics/super/graph?quarter=${quarter}&year=${year}`)
      .then((response: any) => {
        resolve(response);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}

export function getDashboardMarketingMetrics({ from, to }: SelectedDateRange) {
  return new Promise<any>((resolve, reject) => {
    api
      .get(`/admin/metrics/marketing?from=${from}&to=${to}`)
      .then((response: any) => {
        resolve(response);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}

export function getDashboardGenericsDataAgent({ from, to }: SelectedDateRange) {
  return new Promise<any>((resolve, reject) => {
    api
      .get(`/admin/metrics/agent?from=${from}&to=${to}`)
      .then((response: any) => {
        resolve(response);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}

export function getDashboardGenericsGraphData({
  quarter,
  year
}: {
  quarter: string;
  year: string;
}) {
  return new Promise<any>((resolve, reject) => {
    api
      .get(`/admin/metrics/super/graph?quarter=${quarter}&year=${year}`)
      .then((response: any) => {
        resolve(response?.data?.data);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}

export function getAllInitResources() {
  return new Promise<any>((resolve, reject) => {
    api
      .get(`/resources/get/web/init`)
      .then((response: any) => {
        resolve(response.data);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}

// Upload documents and images
export function uploadDocument(url: string, data: any): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    api
      .post(`${url}`, data)
      .then((response: any) => {
        resolve(response);
      })
      .catch((error: Error) => {});
  });
}

export function uploadDocumentByPut(url: string, data: any): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    api
      .put(`${url}`, data)
      .then((response: any) => {
        resolve(response);
      })
      .catch((error: Error) => {});
  });
}

export function downloadPolicyDocument(documentId: string): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    api
      .get(`/admin/policies/document/${documentId}/download`, {
        responseType: 'blob'
      })
      .then((response: any) => {
        resolve(response);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}

export function downloadInsurerPolicies(data: any): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    api
      .get(
        `admin/policies/export?from=${data?.from}&insurerId=${data?.insurerId}&status=${data?.status}&to=${data?.to}`
      )
      .then((response: any) => {
        resolve(response?.data?.data);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}

//Super download all or selected policies
export function downloadPolicies(data: any): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    api
      .get(
        `admin/policies/download?active=${data?.active}&from=${data?.from}&reviewStage=${data?.reviewStage}&to=${data?.to}`
      )
      .then((response: any) => {
        resolve(response?.data?.data);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}

//Download policy details
export function downloadPolicyDetails(policyNumber?: string): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    api
      .get(`admin/policies/${policyNumber}/export`)
      .then((response: any) => {
        resolve(response?.data);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}

export function deleteUploadedImage(url: string): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    api
      .delete(`${url}`)
      .then((response: any) => {
        resolve(response);
      })
      .catch((error: Error) => {});
  });
}

// GET /api/admin/policies/{policyNumber}/vehicle-photos
export function requestPolicyVehiclePhotos(policyNumber: string): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    api
      .get(`admin/policies/${policyNumber}/vehicle-photos`)
      .then((response: any) => {
        resolve(response);
      })
      .catch((error: Error) => {});
  });
}

// Get All Payments
export function getAllPayments(
  page: number,
  size: number,
  paymentStatus?: ADMIN_PAYMENTS_STATUS_FILTER_OPTIONS | 'All'
) {
  return new Promise<any>((resolve, reject) => {
    api
      .get(
        `/admin/payments/?page=${page}&size=${size}${
          paymentStatus ? `&paymentStatus=${paymentStatus}` : ''
        }`
      )
      .then((response: any) => {
        resolve(response?.data?.data);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}

// Edit payment
export function editPolicyPayment(id: number, payload: IEditPaymentDTO, evidence: any) {
  return new Promise<any>((resolve, reject) => {
    api
      .put(
        `/admin/payments/${id}?bank=${payload.bank ? `${payload.bank}` : ''}&channel=${
          payload.channel
        }&dateOfPayment=${payload.dateOfPayment}&dueDate=${payload.dueDate}&narration=${
          payload.narration
        }&processor=${payload.processor}&reference=${payload.reference}&timeOfPayment=${
          payload.timeOfPayment
        }`,
        evidence
      )
      .then((response: any) => {
        resolve(response?.data);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}

// Get Payment link
export function getPaymentLink(id: string) {
  return new Promise<any>((resolve, reject) => {
    api
      .get(`/admin/payments/${id}/generate-link`)
      .then((response: any) => {
        resolve(response);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}

// Get Payment link for selected payments
export function makeSelectedPaymentsForAPolicy(paymentIds: string[]) {
  return new Promise<any>((resolve, reject) => {
    api
      .post(`/admin/payments/paySelected`, paymentIds)
      .then((response: any) => {
        resolve(response);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}

// Get Payment link for bulk payment
export function makeBulkPaymentForPolicy(policyNumber: string) {
  return new Promise<any>((resolve, reject) => {
    api
      .get(`/admin/payments/payBulk/${policyNumber}`)
      .then((response: any) => {
        resolve(response);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}

// Generate Payment Object
export function generatePolicyPaymentObjects(policyNumber: string, firstPaymentDate: string) {
  return new Promise<any>((resolve, reject) => {
    api
      .get(
        `admin/policies/${policyNumber}/generatePayments?${
          firstPaymentDate && `firstPaymentDate=${firstPaymentDate}`
        }`
      )
      .then((response: any) => {
        resolve(response);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}

// Regenerate Payment Object
export function regeneratePolicyPaymentObjects(policyNumber: string, startOn: string) {
  return new Promise<any>((resolve, reject) => {
    api
      .get(
        `admin/policies/${policyNumber}/regeneratePayments?${startOn ? `startOn=${startOn}` : ''}`
      )
      .then((response: any) => {
        resolve(response);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}
// Send payNow Notification
export function sendPayNowNotification(id: string) {
  return new Promise<any>((resolve, reject) => {
    api
      .get(`/admin/payments/payNowNotification/${id}`)
      .then((response: any) => {
        resolve(response);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}

export function getPayments(page: number, size: number) {
  return new Promise<any>((resolve, reject) => {
    api
      .get(`/admin/payment/?page=${page}&size=${size}`)
      .then((response: any) => {
        resolve(response);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}
//SUPER APPROVE PAYMENT EDITPATCH

export function superApprovePaymentEdit(id: number) {
  return new Promise<any>((resolve, reject) => {
    api
      .patch(`/admin/payments/${id}`)
      .then((response: any) => {
        resolve(response);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}

//Get Insurer admin dashboard metrics
export function getInsurerDashboardMetrics({ from, to }: SelectedDateRange) {
  return new Promise<any>((resolve, reject) => {
    api
      .get(`/admin/metrics/insurer?from=${from}&to=${to}`)
      .then((response: any) => {
        resolve(response.data.data);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}

//Get admin dashboard metrics
export function getTeamDashboardMetrics(params: ITeamMetricsDTO) {
  return new Promise<any>((resolve, reject) => {
    api
      .get(`/admin/metrics/team?from=${params.from}&to=${params.to}&year=${params.year}`)
      .then((response: any) => {
        resolve(response.data.data);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}

//fetchAllInsurerSales
export function getInsurerSales(page: number, size: number, searchParam?: string) {
  return new Promise<any>((resolve, reject) => {
    api
      .get(
        `/admin/insurers/sales?page=${page}${
          searchParam ? `&searchParam=${searchParam}` : ''
        }&size=${size}`
      )
      .then((response: any) => {
        resolve(response?.data?.data);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}

//approveInsurerPayout
export function approveInsurerPayout(payoutId: string) {
  return new Promise<any>((resolve, reject) => {
    api
      .post(`/admin/insurers/payouts/${payoutId}/approve`)
      .then((response: any) => {
        resolve(response);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}

//markInsurerPayoutAsPaid
export function markInsurerPayoutAsPaid(payoutId: string, payload: IPayoutTransactionDetailsDTO) {
  return new Promise<any>((resolve, reject) => {
    api
      .post(`/admin/insurers/payouts/${payoutId}/paid`, payload)
      .then((response: any) => {
        resolve(response);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}

//reviewInsurerPayout
export function reviewInsurerPayout(payoutId: string) {
  return new Promise<any>((resolve, reject) => {
    api
      .post(`/admin/insurers/payouts/${payoutId}/review`)
      .then((response: any) => {
        resolve(response);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}

// fetchAllInsurerPayouts
export function fetchInsurerPayouts(page: number, size: number, searchParam?: string) {
  return new Promise<any>((resolve, reject) => {
    api
      .get(
        `/admin/insurers/insurer/payouts/?page=${page}${
          searchParam ? `&searchParam=${searchParam}` : ''
        }&size=${size}`
      )
      .then((response: any) => {
        resolve(response?.data?.data);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}

// fetchAllInsurerReviews
export function fetchAllInsurerReviews(page?: number, size?: number, searchParam?: string) {
  return new Promise<any>((resolve, reject) => {
    api
      .get(`/admin/insurers/insurer/reviews`)
      .then((response: any) => {
        resolve(response?.data?.data);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}
// Super get insurer reviews by id
export function getInsurerReviewsbyId(
  id: string,
  page?: number,
  size?: number,
  searchParam?: string
) {
  return new Promise<any>((resolve, reject) => {
    api
      .get(`/admin/insurers/insurer/${id}/reviews`)
      .then((response: any) => {
        resolve(response?.data?.data);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}

//fetchSingleInsurerReview
export function fetchSingleInsurerReview(id: number) {
  return new Promise<any>((resolve, reject) => {
    api
      .get(`/admin/insurers/insurer/${id}/review`)
      .then((response: any) => {
        resolve(response?.data?.data);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}

//Comment on insurer review
export function commentOnInsurerReview(reviewID: number, payload: any) {
  return new Promise<any>((resolve, reject) => {
    api
      .post(`/admin/insurers/insurer/${reviewID}/comment`, payload)
      .then((response: any) => {
        resolve(response?.data?.data);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}

// getAllFileRequests
export function fetchInsurerFiles() {
  return new Promise<any>((resolve, reject) => {
    api
      .get(`/admin/insurers/files`)
      .then((response: any) => {
        resolve(response?.data?.data);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}

export function getPendingPayments(page: number, size: number) {
  return new Promise<any>((resolve, reject) => {
    api
      .get(`/admin/payments/pending?page=${page}&size=${size}`)
      .then((response: any) => {
        resolve(response);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}

export function getFilteredPayments(
  filterBy: PAYMENTS_FILTER_OPTIONS,
  name?: string,
  from?: string,
  to?: string
) {
  return new Promise<any>((resolve, reject) => {
    api
      .get(
        `/admin/payments/filter?filterBy=${filterBy}${name ? `&name=${name}` : ''}${
          from ? `&from=${from}` : ''
        }${to ? `&to=${to}` : ''}`
      )
      .then((response: any) => {
        resolve(response);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}

// Initiate Hubtel Payment
export function initializeHubtelPayment(payload: IInitiateHubtelPaymentDTO): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    api
      .post(`/admin/policies/recurringPayment`, payload)
      .then((response: any) => {
        resolve(response.data);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}
// Initiate IT Consortium Payment
export function initializeItConsortiumPayment(
  momoNumber: string,
  policyNumber: string
): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    api
      .get(
        `/services/itc/payment/installment?momoNumber=${momoNumber}&policyNumber=${policyNumber}`
      )
      .then((response: any) => {
        resolve(response.data);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}

export function InitializeHubtelDirectCharge(paymentId: string): Promise<any> {
  return api.get(`services/hubtel/direct-charge?paymentId=${paymentId}`);
}

export function InitializeHubtelPreapproval(
  channel?: string,
  momoNumber?: string,
  policyNumber?: string
): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    api
      .get(
        `services/hubtel/preapproval?channel=${channel}&msisdn=${momoNumber}&policyNumber=${policyNumber}`
      )
      .then((response: any) => {
        resolve(response?.data);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
}

export function hubtelPreapprovalVerify(otp?: string, policyNumber?: string): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    api
      .get(`services/hubtel/preapproval/verify?otp=${otp}&policyNumber=${policyNumber}`)
      .then((response: any) => {
        resolve(response?.data);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
}

export function verifyPaymentOtp(payload: IVerifyMobilePaymentDTO): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    api
      .post(`/admin/policies/verifyPaymentOtp`, payload)
      .then((response: any) => {
        resolve(response);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}

export function convertCurrency(have: string, want: string) {
  return new Promise<any>((resolve, reject) => {
    api
      .get(`/resources/currency/convert?have=${have}&want=${want}`)
      .then((response: any) => {
        resolve(response?.data);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}

{
  /*Financial Metrics on Payments page*/
}

export function getSuperPaymentMetrics(): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    api
      .get(`/admin/metrics/financials`)
      .then((response: any) => {
        resolve(response?.data?.data);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}
///api/admin/payments/debit/{category}
export function initiateDebiting(category: PAYMENTS_FILTER_OPTIONS): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    api
      .get(`/admin/payments/debit/${category}`)
      .then((response: any) => {
        resolve(response?.data?.data);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}

// COMMUNITIES
export function createCommunity(payload: ICreateCommunityDTO): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    api
      .post(`/admin/community/`, payload)
      .then((response: any) => {
        resolve(response?.data?.data);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}

export function updateCommunity(communityId: string, payload: ICreateCommunityDTO): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    api
      .put(`/admin/community/${communityId}`, payload)
      .then((response: any) => {
        resolve(response?.data?.data);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}

export function addCommunityContact(
  communityId: string,
  payload: IAddCommunityContactDTO
): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    api
      .post(`/admin/community/${communityId}/contact`, payload)
      .then((response: any) => {
        resolve(response?.data?.data);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}

export function updateCommunityContact(
  communityId: string,
  contactId: string,
  payload: IAddCommunityContactDTO
): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    api
      .put(`/admin/community/${communityId}/contact/${contactId}`, payload)
      .then((response: any) => {
        resolve(response?.data?.data);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}

export function getAllCommunities(page: number, size: number, name?: string): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    api
      .get(`/admin/community/all?page=${page}&size=${size}${name ? `&name=${name}` : ''}`)
      .then((response: any) => {
        resolve(response);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}

export function getCommunityById(communityId: string): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    api
      .get(`/admin/community/${communityId}`)
      .then((response: any) => {
        resolve(response?.data?.data);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}

export function getCommunityPolicies(communityId: string): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    api
      .get(`/admin/community/${communityId}/policies`)
      .then((response: any) => {
        resolve(response?.data);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}

export function deleteCommunity(communityId: string): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    api
      .delete(`/admin/community/${communityId}`)
      .then((response: any) => {
        resolve(response?.data);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}

export function notifyCommunity(
  communityId: string,
  channel: COMMUNITY_CHANNEL_OPTIONS,
  message: string
): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    api
      .post(`/admin/community/${communityId}/notify/${channel}?message=${message}`)
      .then((response: any) => {
        resolve(response?.data);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}

export function deleteCommunityContact(communityId: string, contactId: string): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    api
      .delete(`/admin/community/${communityId}/contact/${contactId}`)
      .then((response: any) => {
        resolve(response?.data);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}
export function addPoliciestoCommunity(communityId: string, policyNumbers: any): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    api
      .put(`/admin/community/${communityId}/policies`, policyNumbers)
      .then((response: any) => {
        resolve(response?.data);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}
// END COMMUNITIES

// Get all claims
export function getAllClaims(
  page: number,
  size: number,
  searchParam?: string,
  stage?: CLAIM_FILTER_OPTIONS
): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    api
      .get(
        `/admin/claims/?page=${page}&size=${size}${
          searchParam ? `&searchParam=${searchParam}` : ''
        }${stage ? `&stage=${stage}` : ''}`
      )
      .then((response: any) => {
        resolve(response?.data?.data);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}
// Get claim by id
export function getClaimById(claimNumber: string): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    api
      .get(`/admin/claims/${claimNumber}`)
      .then((response: any) => {
        resolve(response?.data);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}

// Get claims for a policy
export function getPolicyClaims(policyId: string): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    api
      .get(`/admin/policies/${policyId}/claims`)
      .then((response: any) => {
        resolve(response?.data?.data);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}

// Super get all insurer claims
export function getInsurerClaimsById(
  id: string,
  page: number,
  size: number,
  searchParam?: string,
  stage?: CLAIM_FILTER_OPTIONS
): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    api
      .get(
        `/admin/insurers/insurer/${id}/claims/?page=${page}&size=${size}${
          searchParam ? `&searchParam=${searchParam}` : ''
        }${stage ? `&stage=${stage}` : ''}`
      )
      .then((response: any) => {
        resolve(response?.data?.data);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}
// Update claim prelim questions
export function updateClaimPrelimQuestions(
  claimNumber: string,
  payload: IClaimsPrelimQuestionsDTO
): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    api
      .post(`/admin/claims/${claimNumber}/prelims/`, payload)
      .then((response: any) => {
        resolve(response?.data?.data);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}

//Update claim accident Circumstancee
export function updateClaimAccidentCircumstance(
  claimNumber: string,
  payload: IClaimsAccidentCircumstanceDTO
): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    api
      .put(`/admin/claims/${claimNumber}/accidentCircumstance`, payload)
      .then((response: any) => {
        resolve(response?.data?.data);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}

//Update claim claim vehicle damamge
export function updateClaimVehicleDamage(
  claimNumber: string,
  payload: IVehicleDamageFormData
): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    api
      .put(`/admin/claims/${claimNumber}/vehicleDamage`, payload)
      .then((response: any) => {
        resolve(response?.data?.data);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}

//Update claim claim driver details
export function updateClaimDriverParticulars(
  claimNumber: string,
  payload: IClaimsDriverParticularsDTO
): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    api
      .put(`/admin/claims/${claimNumber}/driverDetails`, payload)
      .then((response: any) => {
        resolve(response?.data?.data);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}

//Update claim other vehicle involved
export function updateClaimOtherVehicleInvolved(
  claimNumber: string,
  payload: IClaimsOtherVehicleInvolvedDTO
): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    api
      .put(`/admin/claims/${claimNumber}/otherVehicleInformation`, payload)
      .then((response: any) => {
        resolve(response?.data?.data);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}

//Update claim other affected persons
export function updateClaimOtherAffectedPersons(
  claimNumber: string,
  payload: IClaimOtherAffectedPersonDTO
): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    api
      .put(`/admin/claims/${claimNumber}/affectedPersons`, payload)
      .then((response: any) => {
        resolve(response?.data?.data);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}

//Update claim claim police involvement
export function updateClaimPoliceInvolvement(
  claimNumber: string,
  payload: IClaimsPoliceInvolvementDTO
): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    api
      .put(`/admin/claims/${claimNumber}/policeInvolvement`, payload)
      .then((response: any) => {
        resolve(response?.data?.data);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}

//Update claim  other information
export function updateClaimOtherInformation(
  claimNumber: string,
  payload: IClaimsOtherInformationDTO
): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    api
      .put(`/admin/claims/${claimNumber}/otherInformation`, payload)
      .then((response: any) => {
        resolve(response?.data?.data);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}

//Update claim  other information
export function updateClaimDeclaration(
  claimNumber: string,
  payload: IClaimsDeclarationDTO
): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    api
      .put(`/admin/claims/${claimNumber}/agreement`, payload)
      .then((response: any) => {
        resolve(response?.data?.data);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}

// Update entire claim
export function updateEntireClaim(claimNumber: string, payload: any): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    api
      .put(`/admin/claims/${claimNumber}/edit`, payload)
      .then((response: any) => {
        resolve(response?.data?.data);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}

// Export claim
export function exportClaimDetails(claimNumber: string): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    api
      .get(`/admin/claims/${claimNumber}/export`)
      .then((response: any) => {
        resolve(response?.data?.data);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}

// Create new claim
export function createClaimFromPolicy(policyNumber: string): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    api
      .get(`/admin/policies/${policyNumber}/claim`)
      .then((response: any) => {
        resolve(response?.data?.data);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}

export function archivePolicy(policyNumber: string, status: boolean): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    api
      .patch(`/admin/policies/${policyNumber}/archive/${status}`)
      .then((response: any) => {
        resolve(response);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}

// START PRODUCTS
export function getAllProducts(): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    api
      .get(`/admin/products/`)
      .then((response: any) => {
        resolve(response?.data.data);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}

export function addInsurerProducts(productIds: string[]): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    api
      .patch(`/admin/insurers/products?products=${productIds}`)
      .then((response: any) => {
        resolve(response?.data.data);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}

export function addInsurerProductBenefits(benefits: string[]): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    api
      .patch(`/admin/insurers/benefits?benefits=${benefits}`)
      .then((response: any) => {
        resolve(response?.data.data);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}

export function getDownloadableFile(data: IDownloadData): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    api
      .get(
        `/admin/insurers/file-request?fileType=${data.fileType}&from=${data.from}&to=${data.to}&resourceType=${data.resourceType}`
      )
      .then((response: any) => {
        resolve(response?.data?.data);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}

// END PRODUCTS

// ROLES ENDPOINTS

//GET ALL ROLES
export function getAllRoles(): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    api
      .get(`/admin/settings/roles`)
      .then((response: any) => {
        resolve(response?.data?.data);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}

//GET SINGLE ROLE BY ID
export function getARoleByID(id: string): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    api
      .get(`/admin/settings/role/${id}`)
      .then((response: any) => {
        resolve(response?.data?.data);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}
//GET ALL PERMISSIONS
export function getAllPermissions(): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    api
      .post(`/admin/settings/permissions`)
      .then((response: any) => {
        resolve(response?.data?.data);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}

//Create Role
export function createRole(payload: any): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    api
      .post(`/admin/settings/role`, payload)
      .then((response: any) => {
        resolve(response);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}

// LOGS
//GET all audit logs/ logs
export function fetchAllLogs(page: number, size: number): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    api
      .get(`/admin/logs/audit-trails/?page=${page}&size=${size}`)
      .then((response: any) => {
        resolve(response?.data?.data);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}
//Get audit log by id
export function fetchSingleLogByID(id: number): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    api
      .get(`/admin/logs/audit/${id}`)
      .then((response: any) => {
        resolve(response?.data?.data);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}

//Setting MID credentials
export function setMIDCredentials(payload: any): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    api
      .post(
        `/admin/settings/mid-credentials/?branchCode=${payload.branchCode}&midApiKey=${payload.midApiKey}&midPassword=${payload.midPassword}&midReady=${payload.midReady}&midUsername=${payload.midUsername}&nicRegistrationNumber=${payload.nicRegistrationNumber}`
      )
      .then((response: any) => {
        resolve(response?.data?.data);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}

//Generate Insurer API Keys
export function generateInsurerAPIKeys(environment: 'PRODUCTION' | 'SANDBOX'): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    api
      .put(`/admin/settings/generate/keys/?environment=${environment}`)
      .then((response: any) => {
        resolve(response?.data?.data);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}
//Insurer webhook settings PUT
export function insurerIntegrateWithWebhooks(payload: any): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    api
      .put(
        `/admin/settings/settings/webhook/?enable=${payload.enable}&enableForClaims=${payload.enableForClaims}&enableForPayouts=${payload.enableForPayouts}&enableForPolicies=${payload.enableForPolicies}&enableForSales=${payload.enableForSales}&webhookUri=${payload.webhookUri}`
      )
      .then((response: any) => {
        resolve(response?.data?.data);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}
//Insurer sales breakdown
export function insurerSalesBreakdown(): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    api
      .get(`/admin/metrics/insurer/sales`)
      .then((response: any) => {
        resolve(response?.data?.data);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}
//Add product to insurer
export function addProduct(insurerId: string, add: boolean, products?: string[]): Promise<void> {
  const queryParams = `add=${add}${products ? `&products=${products.join('&products=')}` : ''}`;
  const url = `/admin/insurers/${insurerId}/products?${queryParams}`;

  return new Promise<void>((resolve, reject) => {
    api
      .patch(url)
      .then((response: any) => {
        resolve(response?.data?.data);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}

//Upload product icon
export function uploadProductIcon(productId: string, file: any): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    api
      .patch(`admin/products/${productId}/icon`, file)
      .then((response: any) => {
        resolve(response?.data?.data);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}

//Create new product
export function createNewProduct(payload: any): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    api
      .post(`admin/products/`, payload)
      .then((response: any) => {
        resolve(response?.data?.data);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}

//Change product status
export function changeProductStatus(productId: string, status: boolean): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    api
      .patch(`admin/products/${productId}/activate/${status}`)
      .then((response: any) => {
        resolve(response?.data?.data);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}

export function showProtectedData(dataId: string, typeOfData: string): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    api
      .get(`/admin/staff/account/reveal-protected-data?dataId=${dataId}&typeOfData=${typeOfData}`)
      .then((response: any) => {
        resolve(response?.data?.data);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}

export function addSlackWorkspace(payload: any): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    api
      .post(`admin/settings/slack/workspace`, payload)
      .then((response: any) => {
        resolve(response?.data?.data);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}

export function addTeamToSlackWorkspace(payload: any): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    api
      .post(`admin/settings/slack/workspace`, payload)
      .then((response: any) => {
        resolve(response?.data?.data);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}

export function getSlackWorkspaces(): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    api
      .get(`admin/settings/slack/workspaces`)
      .then((response: any) => {
        resolve(response?.data);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}

export function getAllApprovalsForSuperAdmin(stage: string): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    api
      .get(`admin/approvals/?stage=${stage}`)
      .then((response: any) => {
        resolve(response?.data?.data);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}

// Clear "Me" notifications GET
export function clearMeNotifications(): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    sseApi
      .get(`sse/admin/notifications/clear`)
      .then((response: any) => {
        resolve(response?.data);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}

// CREATE Partner
export function createPartner(payload: IAddPartnerDTO): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    api
      .post(`/admin/partners`, payload)
      .then((response: any) => {
        resolve(response);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
}
// Get all Partners
export function getAllPartners(page?: number, size?: number): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    api
      .get(`/admin/partners/?page=${page}&size=${size}`)
      .then((response: any) => {
        resolve(response?.data?.data);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
}

// Get a partner
export function getAPartner(partnerId: string): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    api
      .get(`/admin/partners/${partnerId}`)
      .then((response: any) => {
        resolve(response?.data?.data);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
}

// Get team to partner
export function addTeamToPartner(teamId: string, partnerId: string): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    api
      .put(`/admin/teams/${teamId}/partner/${partnerId}`)
      .then((response: any) => {
        resolve(response?.data?.data);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
}
// Get partner teams
export function getPartnerTeams(partnerId: string): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    api
      .get(`/admin/partners/${partnerId}/teams`)
      .then((response: any) => {
        resolve(response?.data?.data);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
}

// Upload partner logo
export function uploadPartnerLogo(partnerId: string, logo: any): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    api
      .post(`/admin/partners/logo/upload?id=${partnerId}`, logo)
      .then((response: any) => {
        resolve(response?.data?.data);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
}

// Fetch Yango parks
export function getYangoParks(): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    api
      .get(`/admin/teams/yango/parks`)
      .then((response: any) => {
        resolve(response?.data?.data);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
}

//
export function addFuelPurchase(payload: any): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    api
      .post(`/admin/fuels/purchases`, payload)
      .then((response: any) => {
        resolve(response?.data?.data);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
}

//
export function getAllFuelPurchases(
  page: number,
  size: number,
  from?: string,
  to?: string,
  partnerId?: string,
  stationId?: string
): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    api
      .get(
        `/admin/fuels/purchases?page=${page}&size=${size}${from ? `&from=${from}` : ''}${
          to ? `&to=${to}` : ''
        }${stationId ? `&stationId=${stationId}` : ''}${partnerId ? `&partnerId=${partnerId}` : ''}`
      )
      .then((response: any) => {
        resolve(response?.data?.data);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
}

//
export function getPartnerFuelMetrics(
  from?: string,
  to?: string,
  partnerId?: string,
  stationId?: string
): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    api
      .get(
        `/admin/metrics/fuel/sales?${from ? `from=${from}` : ''}${to ? `&to=${to}` : ''}${
          partnerId ? `&partnerId=${partnerId}` : ''
        }${stationId ? `&stationId=${stationId}` : ''}`
      )
      .then((response: any) => {
        resolve(response?.data?.data);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
}
//
export function getAllFuelRebates(
  page: number,
  size: number,
  from: string,
  to: string
): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    api
      .get(
        `/admin/fuels/rebates?page=${page}&size=${size}${from ? `&from=${from}` : ''}${
          to ? `&to=${to}` : ''
        }`
      )
      .then((response: any) => {
        resolve(response?.data?.data);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
}

// Get partner fuel quota
export function getPartnerFuelQuota(partnerId: string): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    api
      .get(`/admin/fuels/quota?partnerId=${partnerId}`)
      .then((response: any) => {
        resolve(response?.data?.data);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
}

// Update partner fuel quota
export function updatePartnerFuelQuota(payload: IUpdatePartnerQuotaDTO): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    api
      .put(`/admin/fuels/quota`, payload)
      .then((response: any) => {
        resolve(response?.data?.data);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
}

// Get fuel vendor sales history
export function getFuelVendorSalesHistory(staffId: string): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    api
      .get(`/admin/fuels/sales/history?staff_id=${staffId}`)
      .then((response: any) => {
        resolve(response?.data?.data);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
}

// vendor close daily sales
export function closeFuelVendorDailySales(date?: string): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    api
      .get(`/admin/fuels/close-day-sale`)
      .then((response: any) => {
        resolve(response?.data?.data);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
}

// get unclosed vendor daily sales
export function getUnclosedFuelVendorDailySales(status: string): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    api
      .get(`/admin/fuels/sales${status ? `?status=${status}` : ''}`)
      .then((response: any) => {
        resolve(response?.data?.data);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
}

// Fuel admin approve vendor daily sales
export function fuelAdminCloseVendorSales(id: string): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    api
      .get(`/admin/fuels/close-staff-sale?id=${id}`)
      .then((response: any) => {
        resolve(response?.data?.data);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
}

// Export fuel partner sales
export function exportFuelPartnersales({
  from,
  stationId,
  to
}: IExportFuelSalesDataDTO): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    api
      .get(
        `/admin/fuels/transactions/export${from ? `?from=${from}` : ''}${
          stationId ? `&stationId=${stationId}` : ''
        }${to ? `&to=${to}` : ''}`
      )
      .then((response: any) => {
        resolve(response?.data?.data);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
}

// getAllFuelSaleTransactions
export function getAllFuelSaleTransactions(saleId: string): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    api
      .get(`/admin/fuels/sale/${saleId}/transactions`)
      .then((response: any) => {
        resolve(response?.data?.data);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
}

// grt fuel Settlements
export function getAllFuelSettlements(completed: boolean): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    api
      .get(`/admin/fuels/sales/settlements?completed=${completed}`)
      .then((response: any) => {
        resolve(response?.data?.data);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
}

//  GET settle Station Non Cash Sale

export function settleStationNonCashSale(id: string): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    api
      .get(`/admin/fuels/sales/${id}/approve-settlement`)
      .then((response: any) => {
        resolve(response?.data?.data);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
}

//  request NonCashSaleSettlement
export function requestNonCashSaleSettlement(): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    api
      .get(`/admin/fuels/sales/request-settlement`)
      .then((response: any) => {
        resolve(response?.data?.data);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
}

// Get unsettled fuel balance
export function getUnsettledFuelBalance(): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    api
      .get(`/admin/fuels/sales/unsettled-balance`)
      .then((response: any) => {
        resolve(response?.data?.data);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
}

{
  /* BLOGS */
}

//Create a blog post
export function createblogPost(payload: ICreateBlogPost): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    api
      .post(`/admin/blog/post`, payload)
      .then((response: any) => {
        resolve(response?.data?.data);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
}

//get a blog post
export function getblogPostById(blogId: string): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    api
      .get(`/admin/blog/post/${blogId}`)
      .then((response: any) => {
        resolve(response?.data?.data);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
}

//get blog posts
export function getAllBlogPosts(page: number, published: boolean, size: number): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    api
      .get(`/admin/blog/posts?page=${page}&published=${published}&size=${size}`)
      .then((response: any) => {
        resolve(response?.data?.data);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
}

//Update blog posts
export function updateBlogPostById(blogId: number, payload: ICreateBlogPost): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    api
      .put(`/admin/blog/post/${blogId}`, payload)
      .then((response: any) => {
        resolve(response?.data?.data);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
}

//Delete blog posts
export function deleteBlogPostById(blogId: number): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    api
      .delete(`/admin/blog/post/${blogId}`)
      .then((response: any) => {
        resolve(response?.data?.data);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
}

//Delete blog posts
export function publishBlogPostById(blogId: number, published: boolean): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    api
      .put(`/admin/blog/post/${blogId}/publish/${published}`)
      .then((response: any) => {
        resolve(response?.data);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
}

//Upload blog photo
export function uploadBlogMedia(blogId: string, file: any, isBanner: boolean): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    api
      .put(`/admin/blog/post/${blogId}/upload/media?isBanner=${isBanner}`, file)
      .then((response: any) => {
        resolve(response?.data);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
}

//Set a blog of the day
export function setPostOfTheDay(blogId: number): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    api
      .put(`/admin/blog/post/${blogId}/feature`)
      .then((response: any) => {
        resolve(response?.data);
      })
      .catch((error: any) => {
        reject(error);
        console.log(error);
      });
  });
}

export async function slackNotifyReport(data: string): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    fetch('https://hooks.slack.com/services/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', {
      method: 'POST',
      //cors: "no-cors",
      headers: {},
      body: data
    })
      .then((response: any) => {
        resolve(response);
        console.log(response);
      })
      .catch((error: AxiosError) => {
        reject(error);
      });
  });
}

//Repair now pay later request endpoints

export async function createNewRNPL(rnplDTO:ICreateRNPLDTO ): Promise<void> {
  return new Promise<any>((resolve, reject) => {
    api
      .post(`/admin/rnpl/`,rnplDTO)
      .then((response: any) => {
        resolve(response?.data);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
}

export async function getAllRNPL(page?:number, size?:number, from?:string, to?:string):Promise<void> {
  return new Promise<any>((resolve, reject) => {
    api.get(`admin/rnpl/?page=${page}&size=${size}`)
    .then((response:any)=>{
      resolve(response?.data);
    })
    .catch((error:any)=>{
      reject(error);
    })
  })
}

export async function getSingleRNPLCustomer(id:string):Promise<void> {
  return new Promise<any>((resolve, reject) => {
    api.get(`admin/rnpl/${id}`)
    .then((response:any)=>{
      resolve(response?.data);
    })
    .catch((error:any)=>{
      reject(error);
    })
  })
}

export async function updateRNPL(id:string, rnplDTO:ICreateRNPLDTO ):Promise<void> {
  return new Promise<any>((resolve, reject) => {
    api.put(`admin/rnpl/${id}`,rnplDTO)
    .then((response:any)=>{
      resolve(response?.data);
    })
    .catch((error:any)=>{
      reject(error);
    })
  })
}

export async function reviewRNPLApplication(id:string, reviewStage:string ):Promise<void> {
  return new Promise<any>((resolve, reject) => {
    api.patch(`admin/rnpl/${id}/review?reviewStage=${reviewStage}`,reviewStage)
    .then((response:any)=>{
      resolve(response?.data);
    })
    .catch((error:any)=>{
      reject(error);
    })
  })
}

export async function approveRNPLApplication(id:string):Promise<void> {
  return new Promise<any>((resolve, reject) => {
    api.get(`admin/rnpl/${id}/approve`)
    .then((response:any)=>{
      resolve(response?.data);
    })
    .catch((error:any)=>{
      reject(error);
    })
  })
}

export async function generateRNPLPayment(id:string, localDate:string):Promise<void> {
  return new Promise<any>((resolve, reject) => {
    api.patch(`admin/rnpl/${id}/payments/generate?localDate=${localDate}`,localDate)
    .then((response:any)=>{
      resolve(response?.data);
    })
    .catch((error:any)=>{
      reject(error);
    })
  })
}

export async function getRNPLPayment(id:string):Promise<void> {
  return new Promise<any>((resolve, reject) => {
    api.get(`admin/rnpl/${id}/payments`)
    .then((response:any)=>{
      resolve(response?.data);
    })
    .catch((error:any)=>{
      reject(error);
    })
  })
}

export async function rnplPreapproval(channel:string, identifier:string, msisdn:string ):Promise<void> {
  return new Promise<any>((resolve, reject) => {
    api.get(`payments/rnpl/preapproval?channel=${channel}&identifier=${identifier}&msisdn=${msisdn}`)
    .then((response:any)=>{
      resolve(response?.data);
    })
    .catch((error:any)=>{
      reject(error);
    })
  })
}